import React, { useEffect, useState } from 'react'
import { Box, Container, Text } from '../../../components/core'
import { Dialog, LinearProgress, Tab, Tabs } from '@mui/material'
import HotelMidia from './HotelMidia'
import HotelData from './HotelData'
import HotelPrices from './HotelPrices'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { uploadToS3 } from '../../../utils/uploadToS3'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../../../services/api'
import useApiRequest from '../../../services/useApiRequest'
import RemoveInternalPageButton from './RemoveInternalPageButton'

export default function EditInternalHotel() {
  const pathParam = useParams()
  const internalContent = useApiRequest(`/api/hotelPageContent/${pathParam.hotelId}`)
  const [tabValue, setTabValue] = useState(0)
  const [hotelCardData, setHotelCardData] = useState({})
  const tabOptions = ['Imagens/Vídeo', 'Dados do Hotel', 'Valores'];
  const [loading, setLoading] = useState({
    active: true,
    error: false,
    progress: 90,
    message: 'Carregando pagina interna...'
  })
  const navigate = useNavigate()
  const InternalContentSchema = Yup.object().shape({
    images: Yup.mixed()
      .required('Insira ao menos uma imagem')
      .test('notEmpty', 'O array de imagens não pode ser vazio', (value) => {
        return value && value.length > 0;
      }),
    generalDescription: Yup.string()
      .min(10, 'Sua descrição é muito curta!')
      .required('Campo Obrigatório'),
    availableRoom: Yup.array().required('Ao menos uma opção de quarto deve ser preenchida'),
  });
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleEditInternalHotel = async (values) => {
    setLoading((prevState) => ({ ...prevState, active: true, message: 'Adicionando as mudanças...', progress: 30 }))
    const { images } = values;
    let imgUrl =  internalContent.data.images
    // VERIFICA
    if (images[0] instanceof File) {
      // PASSO 1 - UPLOAD DAS IMAGENS NO S3
      imgUrl = await uploadToS3(images).then((uploadedImages) => {
        console.log('uploadedImages', uploadedImages)
        return uploadedImages
      })
        .catch((error) => {
          console.log('error', error)
          setLoading((prevState) => ({ ...prevState, error: true, message: error.message || 'Erro ao salvar imagem' }))
        }).finally(() => {
          setLoading((prevState) => ({ ...prevState, progress: 60, message: 'Gravando no banco de dados...' }))
        })

    }

      //PASSO 2 - SALVAR NO BANCO DE DADOS
      const bodyRequest = {
        ...values,
        card_id: internalContent.data.card_id,
        images: (typeof imgUrl[0] === 'string') ? internalContent.data.images : imgUrl.map((img) => img.url)
      };
      console.log('bodyRequest', bodyRequest);
      await api.patch(`/api/hotelPageContent/${bodyRequest.id || pathParam.hotelId}`, bodyRequest)
        .then(() => {
          setLoading((prevState) => ({ ...prevState, progress: 100, message: 'Salvo com sucesso!' }))
          setTimeout(() => {
            navigate('/hotels')
          }, 500);
        })
  }

  useEffect(() => {
    if (internalContent.data) {
      setLoading((prevState) => ({ ...prevState, active: false }))
      const fetchData = async () => {
        await api.get(`/api/hotel/${internalContent.data.card_id}`).then((response) => {
          setHotelCardData(response.data)
        })
      }
      fetchData();
    }
  }, [internalContent.data])
  console.log('internalContent', internalContent)
  if (internalContent.data)
    return (
      <Container maxWidth="xl" disableGutters>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        <RemoveInternalPageButton id={internalContent.data._id}/>
        </Box>
        <Text variant="h5">Editando {hotelCardData.name || 'Hotel'}</Text>
        <Box my={6} display={'flex'} flexDirection={'column'} gap={4} maxWidth={1280}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="tab options">
              {tabOptions.map((option, index) => (
                <Tab key={index} label={option} />
              ))}
            </Tabs>
          </Box>
          <Box>
            <Formik
              initialValues={internalContent.data}
              validationSchema={InternalContentSchema}
              onSubmit={handleEditInternalHotel}
            >
              <Form>
                {tabValue === 0 && <HotelMidia />}
                {tabValue === 1 && <HotelData />}
                {tabValue === 2 && <HotelPrices />}
              </Form>
            </Formik>
          </Box>
        </Box>
        <Dialog
          open={loading.active}
          onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
        >
          <Box p={4}>
            <Text variant="h5">{loading.message}</Text>
            <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
              height: 20,
            }}

            />
          </Box>
        </Dialog>
      </Container>
    )
  return <Dialog
    open={loading.active}
    onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
  >
    <Box p={4}>
      <Text variant="h5">{loading.message}</Text>
      <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
        height: 20,
      }}

      />
    </Box>
  </Dialog>
}

