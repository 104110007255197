import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { useDropzone } from 'react-dropzone'
import { Alert, Box, Button, IconButton, Snackbar } from '@mui/material'
import api from '../../../services/api'
import { Text } from '../../core'
import UploadIcon from '@mui/icons-material/Upload';
import { Preview } from '../PreviewImage'
// import { ErrorDialog } from '../error/ErrorDialog'

export const UploadBox = ({ children,
  handleUpload = () => { },
  sx = {},
  preview = true,
  data = {},
  uploadText = 'Arraste aqui a imagem',
  button,
  miniPreview = false }) => {

  const { setLoading } = useContext(AppContext)

  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false)

  const handleDeleteTempImages = () => {

    setFiles([])
  };

  const submitUpload = async (data) => {
    console.log('upload data is', data)
    try {
      setLoading(true);
      const response = await api.post(`/api/upload`, data)
      handleUpload(response.data.uploadedFiles[0])
    } catch (error) {
      console.log(error)
      setFiles([])
      setShowError(true)
    }
    finally {
      setLoading(false);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    try {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0], acceptedFiles[0].name)
      submitUpload(formData)
    } catch (err) {
      console.log(err);
    };
  }, [data, submitUpload])

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  })

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  // console.log(showErrorDialog)

  return (
    <Box sx={{ backgroundColor: isDragActive ? '#e1e1e1' : 'none', border: isDragActive ? '2px dashed #ccc' : 'none', borderRadius: 1, ...sx }} {...getRootProps()}>
      {!files.length > 0 && !button ?
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
          <IconButton>
            <UploadIcon />
          </IconButton>
          <Text sx={{ textAlign: 'center' }}>{uploadText}</Text>
        </Box>
        :
        !files.length > 0 && button ?
          <Button startIcon={<UploadIcon />} variant='contained'>
            {uploadText}
          </Button>
          :
          preview && files[0] &&
          <Preview src={files[0].preview} handleDeleteTempImages={handleDeleteTempImages} miniPreview={miniPreview} />
      }
      {children}
      {showError &&
        <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
          <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
            Ocorreu um erro durante o upload, verifique o formato do arquivo e tente novamente. Limite de Upload 10mb
          </Alert>
        </Snackbar>
      }
    </Box>
  )
}
