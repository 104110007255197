import makeRequest from "./makeRequest";

export const createTicket = async (data) => {
  const response = await makeRequest("/api/ticket", "POST", data);
  return response;
};

export const getTickets = async () => {
  const response = await makeRequest("/api/ticket", "GET");
  return response;
};

export const getTicket = async (id) => {
  const response = await makeRequest(`/api/ticket/${id}`, "GET");
  return response;
};

export const updateTicket = async ({ id, data }) => {
  const response = await makeRequest(`/api/ticket/${id}`, "PATCH", data);
  return response;
};

export const deleteTicket = async (id) => {
  const response = await makeRequest(`/api/ticket/${id}`, "DELETE");
  return response;
};
