import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
});

api.interceptors.request.use((config) => {
//   console.log('Making API request:', config.method, config.url);
  return config;
});

api.interceptors.response.use((response) => {
//   console.log('API response:', response);
  return response;
}, (error) => {
//   console.log('API error:', error);
  throw error;
});

export default api;
