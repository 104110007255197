import React, { useContext, useState } from "react";
import { PagesContext } from "../contexts/PagesContext";
import { AppContext } from "../contexts/AppContext";
import { FeedbackContext } from "../contexts/FeedbackContext";
import { Box, Container, IconButton, TextField } from "@mui/material";
import { Text } from "../components/core";
import { addPage, deletePage } from "../services/pageService";
import { ConfirmationDialog } from "../components/patterns";
import { PagesTable } from "../components/patterns/Pages/PagesTable";
import AddIcon from "@mui/icons-material/Add";

export const Main = () => {
  const { pages, loadPagesContent } = useContext(PagesContext);
  const { setResponse } = useContext(FeedbackContext);
  const { setLoading } = useContext(AppContext);

  const [page, setPage] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  const handleAddPage = async () => {
    setLoading(true);
    try {
      const data = { name: page };
      if (!data.name)
        return setResponse({
          open: true,
          message: "Adicione um nome para esta página",
          severity: "warning",
        });
      const response = await addPage(data);
      if (response.status !== 201) {
        setResponse({
          open: true,
          message: "Ocorreu um erro ao adicionar a página",
          severity: "error",
        });
        return;
      }
      setResponse({
        open: true,
        message: `Página ${response.data.page.name} criada com sucesso`,
        duration: 6000,
        severity: "success",
      });
      setPage("");
      loadPagesContent();
    } catch (error) {
      setResponse({
        open: true,
        message: "Ocorreu um erro ao adicionar a página",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePage = async (id) => {
    const response = await deletePage(id);
    if (response.status !== 200) {
      setResponse({
        open: true,
        message: "Ocorreu um erro ao remover a página",
        severity: "error",
      });
      return;
    }
    setResponse({
      open: true,
      message: response.data.msg,
      duration: 6000,
      severity: "success",
    });
    setConfirmationDialog({ ...confirmationDialog, open: false });
    loadPagesContent();
  };

  const handleShowConfirmationDialog = (page) => {
    setConfirmationDialog({
      open: true,
      title: "Exclusão de página",
      message: `Tem certeza que deseja remover a página ${page.name}? Caso você confirme, todos os demais componentes de stories, slider serão juntamente excluídos`,
      onConfirm: () => handleDeletePage(page._id),
    });
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Box>
        <Text variant="h5">Páginas</Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
        <TextField
          variant="outlined"
          label="Adicionar página"
          value={page}
          onChange={(e) => setPage(e.target.value)}
          sx={{ width: "100%", marginRight: 2 }}
        />
        <IconButton
          onClick={() => handleAddPage(page)}
          sx={{ backgrounColor: "#161034" }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <PagesTable
        pages={pages}
        handleShowConfirmationDialog={handleShowConfirmationDialog}
      />
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          dialogTitle={confirmationDialog.title}
          dialogMessage={confirmationDialog.message}
          onConfirm={confirmationDialog.onConfirm}
          handleClose={() =>
            setConfirmationDialog({ ...confirmationDialog, open: false })
          }
        />
      )}
    </Container>
  );
};
