import React, { useContext, useEffect, useState } from "react"
import { FeedbackContext } from "../../contexts/FeedbackContext"
import { AppContext } from "../../contexts/AppContext"
import {
  Box, Container, Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material"
import { Text } from "../../components/core"
import { createLink, deleteLink, updateLink } from "../../services/linkService"

import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import useApiRequest from "../../services/useApiRequest"
import { ConfirmationDialog } from "../../components/patterns"

export const Links = () => {
  const { setResponse } = useContext(FeedbackContext)

  const { data: content, error, refetchData } = useApiRequest('/api/link')

  const [openForm, setOpenForm] = useState({ open: false, values: {} })

  const handleCloseForm = () => setOpenForm({ open: false, values: {} })

  const handleSubmit = async (values) => {
    try {
      await createLink(values)
      await refetchData()
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenForm = () => setOpenForm({ open: true, values: {} })

  return (
    <Container maxWidth="xl" disableGutters>
      <Box sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}>
        <Text variant="h5">Links Úteis</Text>
        <Button
          onClick={handleOpenForm}
          variant="contained"
          color="primary"
        >
          Novo link
        </Button>
      </Box>
      {!!content ?
        <LinksTable
          content={content}
          loadLinks={refetchData}
          showSuccess={() => setResponse({ open: true, message: 'Link útil atualizado com sucesso.', severity: 'success' })}
          showError={() => setResponse({ open: true, message: 'Ocorreu um erro ao atualizar o link útil', severity: 'error' })}
        />
        : <Text variant="h6">Nenhum link útil cadastrado</Text>
      }
      {openForm.open && (
        <LinkForm
          open={openForm.open}
          values={openForm.values}
          onClose={handleCloseForm}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  )
}

const LinksTable = ({ content = [], loadLinks, showSuccess, showError }) => {
  const { setLoading } = useContext(AppContext)

  const contentWithEditMode = content.map(day => ({ ...day, editMode: false }))

  const [days, setDays] = useState([])

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState({ open: false, _id: '' })

  useEffect(() => {
    setDays(contentWithEditMode)
  }, [content])

  const handleChange = ({ target }, idx) => {
    let newDays = [...days]
    newDays[idx][target.name] = target.value
    setDays(newDays)
  }

  const handleEdit = (index) => {
    let newDays = [...days]
    newDays[index].editMode = true
    setDays(newDays)
  }

  const handleConfirm = (index) => {
    let newDays = [...days]
    newDays[index].editMode = false
    setDays(newDays)
  }
  const handleDataBeforeSubmit = () => {
    const removeEditModeFromDays = days.map(day => {
      const { editMode, ...rest } = day
      return rest
    })

    const changedDays = removeEditModeFromDays.filter((day, index) => {
      return day.description !== content[index].description || day.link !== content[index].link
    })

    return changedDays
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const data = handleDataBeforeSubmit()
      const promises = data.map(day => updateData(day._id, day))
      console.log(promises)
      await Promise.all(promises)
      loadLinks()
      showSuccess()
    } catch (error) {
      console.log(error)
      showError()
    } finally {
      setLoading(false)
    }
  }

  const updateData = async (id, data) => {
    setLoading(true)
    await updateLink(id, data)
    setLoading(false)
  }

  const handleDeleteLink = async () => {
    setLoading(true)
    try {
      await deleteLink(openConfirmationDialog._id)
      setOpenConfirmationDialog({ open: false, _id: '' })
      loadLinks()
      showSuccess()
    } catch (error) {
      showError()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <TableContainer component={Paper} sx={{ marginTop: 2 }} >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map((day, index) => (
              <TableRow
                key={day._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {day.editMode ?
                    <TextField
                      onChange={e => handleChange(e, index)}
                      name="description"
                      variant="standard"
                      placeholder="Título"
                      sx={{ marginRight: 2 }}
                      value={day.description}
                    />
                    :
                    day.description
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {day.editMode ?

                    <TextField
                      onChange={e => handleChange(e, index)}
                      name="link"
                      variant="standard"
                      placeholder="Link"
                      sx={{ marginRight: 2 }}
                      fullWidth
                      value={day.link}
                    />
                    :
                    day.link
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {day.editMode ?
                    <IconButton onClick={() => handleConfirm(index)} >
                      <CheckIcon sx={{ color: day.editMode ? '' : "Gray-Text" }} />
                    </IconButton>
                    :
                    <IconButton onClick={() => handleEdit(index)}>
                      <EditIcon sx={{ color: day.editMode ? 'Highlight' : "Gray-Text" }} />
                    </IconButton>
                  }
                  <IconButton onClick={() => setOpenConfirmationDialog({ open: true, _id: day._id })}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openConfirmationDialog.open}
        handleClose={() => setOpenConfirmationDialog({ open: false, _id: '' })}
        onConfirm={handleDeleteLink}
        dialogTitle="Excluir link"
        dialogMessage="Tem certeza que deseja excluir este link?"
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 4 }}>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Salvar
        </Button>
      </Box>
    </Box>
  )
}

const LinkForm = ({ open, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    description: "",
    link: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formValues)
    onSubmit(formValues);
    setFormValues({
      description: "",
      link: "",
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ minWidth: 330 }}>
        <DialogTitle>Novo Link</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              name="description"
              label="Descrição"
              onChange={handleChange}
              margin="dense"
              required
            />
            <TextField
              name="link"
              label="Link"
              onChange={handleChange}
              margin="dense"
              required
            />
            <DialogActions>
              <Button onClick={onClose} variant="outlined">Cancelar</Button>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

