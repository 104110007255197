import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import AppRoutes from './routes';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import { FeedbackProvider } from './contexts/FeedbackContext';

function App() {

  moment.locale('pt-br');

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <FeedbackProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </FeedbackProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
