import { Box, Button, Container, Dialog, DialogActions, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ConfirmationDialog } from '../../components/patterns';
import { Text } from '../../components/core';
import ProdutosTable from './ProdutosTable';
import useApiRequest from '../../services/useApiRequest';
import { deleteProdutos, updateProdutos } from '../../services/produtosService';
import NewProdutoForm from './NewProdutoForm';

export default function Produtos() {
    const { data } = useApiRequest('/api/produtos')
    const categorias = useApiRequest('/api/category')
    const [produtos, setProdutos] = useState([])
    const [showConfirmationDialog, setShowConfirmationDialog] = useState({ open: false, id: "" });
    const [switchActiveDialog, setSwitchActiveDialog] = useState({ open: false, id: "", isActive: false });
    const [editDialog, setEditDialog] = useState({ open: false, produto: {} });
    const [filter, setFilter] = useState({
        isActive: true,
        category: 'Todos'
    });

    useEffect(() => {
        if (data) {
            setProdutos(data);
        }
    }, [data]);

    useEffect(() => {
        if (filter.category === 'Todos' && data) {
            setProdutos(data);
        } else {
            setProdutos(data?.filter(produto => produto.category_id === filter.category));
        }
    }, [filter.category, data]);

    useEffect(() => {
        if (filter.isActive) {
            setProdutos(data?.filter(produto => produto.isActive === true));
        } else {
            setProdutos(data?.filter(produto => produto.isActive === false));
        }
    }, [filter.isActive, data]);


    const onSwitch = (hotel) => {
        setSwitchActiveDialog({ open: true, id: hotel._id, isActive: hotel.isActive });
    }

    const onDelete = (produto) => {
        setShowConfirmationDialog({ open: true, id: produto._id });
    };

    const handleSwitch = async () => {
        console.log('handleswitch produto id is:', switchActiveDialog.id);
        await updateProdutos(switchActiveDialog.id, {
            isActive: !switchActiveDialog.isActive,
        }).then(() => {
            produtos.find(produto => produto._id === switchActiveDialog.id).isActive = !switchActiveDialog.isActive;
            setProdutos([...produtos]);
        }
        ).finally(() => {
            setSwitchActiveDialog({ open: false, id: '', isActive: false });
        }
        );
    };

    const handleDeleteProduto = async () => {
        await deleteProdutos(showConfirmationDialog.id).then(() => {
            console.log('confirmationdialog id is:', showConfirmationDialog.id, 'produtos is:', produtos)
            setProdutos(() => produtos.filter((produto) => produto._id !== showConfirmationDialog.id));
            console.log('deleted produto->', showConfirmationDialog.id);
        })
        setShowConfirmationDialog(false);
    };
    const handleEditProduto = (produto) => {
        console.log('editando produto', produto);
        setEditDialog({ open: true, produto: produto });
    };


    return (
        <Container maxWidth="xl" disableGutters>
            <Box sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}>
                <Text variant="h5">Lista de Produtos</Text>
                <Box sx={{ flexGrow: 1, mx: 4, gap: 2, display: 'flex' }} >
                    <FormControl>
                        <InputLabel id="filtrar-por">Filtrar por</InputLabel>
                        <Select
                            id='filtrar-por'
                            label="Filtrar por"
                            variant="outlined"
                            defaultValue={true}
                            sx={{ width: 200 }}
                            onChange={(e) => setFilter({ ...filter, isActive: e.target.value })}
                        >
                            <MenuItem value={true}>Produtos Ativos</MenuItem>
                            <MenuItem value={false}>Produtos Inativos</MenuItem>
                        </Select>

                    </FormControl>
                    <Box>
                        <FormControl>
                            <InputLabel>Categorias</InputLabel>
                            <Select
                                label="Categorias"
                                variant="outlined"
                                defaultValue=""
                                sx={{ width: 200 }}
                                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                            >
                                {categorias.data && categorias.data.map((categoria) => (
                                    <MenuItem key={categoria._id} value={categoria._id}>{categoria.name}</MenuItem>
                                ))}
                                <MenuItem value="Todos">Todos</MenuItem>
                            </Select>
                        </FormControl>


                    </Box>
                </Box>
                <Button
                    href="/produtos/new"
                    variant="contained"
                    color="primary"
                >
                    Novo Produto
                </Button>
            </Box>
            <Box
                maxWidth={962}
                display={'flex'}
            >
                <ProdutosTable
                    produtos={produtos}
                    onEdit={handleEditProduto}
                    onDelete={onDelete}
                    onSwitch={onSwitch}
                />
            </Box>
            {showConfirmationDialog.open && (
                <ConfirmationDialog
                    open={showConfirmationDialog.open}
                    dialogTitle="Confirmação de Remoção"
                    dialogMessage="Deseja realmente remover o produto?"
                    handleClose={() => setShowConfirmationDialog({ open: false, id: '' })}
                    onConfirm={handleDeleteProduto}
                />
            )}
            {switchActiveDialog.open && (
                <ConfirmationDialog
                    open={switchActiveDialog.open}
                    dialogTitle='Confirmar ação de ativação/desativação'
                    dialogMessage={produtos.find(produto => produto._id === switchActiveDialog.id)?.isActive ? "O Produto será desativado e não aparecerá no site" : "O Produto ficará acessível no site"}
                    handleClose={() => setSwitchActiveDialog({ open: false, id: '' })}
                    onConfirm={handleSwitch}
                />
            )}
            {editDialog.open && editDialog.produto && (
                <Dialog
                    open={editDialog.open}
                >
                    <Box
                        maxWidth="md"
                        m={2}
                    >
                        <NewProdutoForm edit={editDialog.produto} setEdit={setEditDialog} />
                    </Box>
                    <DialogActions>
                        <Button autoFocus
                            color="error"
                            size="xl"
                            variant="contained"
                            onClick={() => {
                                setEditDialog({ open: false })
                                console.log('editDialog Produto is:', editDialog.produto)
                                setProdutos((prevData) => {
                                    const updatedData = prevData.map((produto) => {
                                        if (produto._id === editDialog.produto._id) {
                                            return editDialog.produto;
                                        }
                                        return produto;
                                    });
                                    return updatedData;
                                });
                            }

                            }
                        >
                            Sair
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container >
    )
}
