import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Button, Text } from "../../components/core";
import {
  SlideEdit,
  SlideCreateDialog,
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/patterns";
import { Paper } from "@mui/material";
import { deleteSlide } from "../../services/slideService";
import { useLocation, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { AppContext } from "../../contexts/AppContext";
import { FeedbackContext } from "../../contexts/FeedbackContext";
import { getPage } from "../../services/pageService";
import { updateSlider } from "../../services/sliderService";

export const SliderEdit = () => {
  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);

  const location = useLocation();
  const { state } = location;

  const [page, setPage] = useState({});

  const [editSlide, setEditSlide] = useState({ open: false, slide: null });
  const [createSlide, setCreateSlide] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    dialogTitle: "",
    dialogMessage: "",
    onConfirm: null,
  });
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    errorTitle: "",
    errorMessage: "",
    onConfirm: null,
  });

  let { id } = useParams();

  useEffect(() => {
    loadCurrentPage();
  }, [state]);

  const loadCurrentPage = async () => {
    setLoading(true);
    try {
      if (!state) {
        const { page } = await getPage(id);
        setPage(page);
      } else {
        const { page } = await getPage(state.currentPage._id);
        setPage(page);
      }
    } catch (error) {
      console.log(error);
      setResponse({
        open: true,
        message: "Erro ao carregar página.",
        severity: "error",
      });
    }
    setLoading(false);
  };

  const handleDeleteSlide = async (slideId) => {
    setConfirmationDialog({
      ...confirmationDialog,
      open: true,
      dialogTitle: "Excluir slide",
      dialogMessage: "Tem certeza que deseja excluir este slide?",
      onConfirm: async () => {
        await updateSlider({ id: page.slider._id, data: { slides: page.slider.slides.filter((slide) => slide._id !== slideId) } });
        await deleteSlide(slideId);
        setConfirmationDialog({ ...confirmationDialog, open: false });
        setResponse({
          open: true,
          message: "Slide excluído com sucesso.",
          severity: "success",
        });
        loadCurrentPage();
      },
    });
  };

  return (
    <>
      {page ? (
        <Container maxWidth="xl" disableGutters>
          <Text variant="h5">Edição de Slider da Página {page.name}</Text>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
            <Text variant="h6">Slides</Text>
            <div style={{ display: "flex", flex: 1 }} />
            <Button variant="contained" onClick={() => setCreateSlide(true)}>
              Adicionar novo slide
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {page.slider && page.slider.slides && page.slider.slides.length > 0 &&
              page.slider.slides.map((slide, index) => (
                <Box
                  component={Paper}
                  elevation={3}
                  key={`pageSlider:${index}`}
                  sx={{
                    maxWidth: "250px",
                    marginRight: 2,
                    width: "100%",
                    marginTop: 2,
                    borderRadius: "30px",
                    padding: 2,
                  }}
                >
                  <Text variant="h6">Slide {index + 1}</Text>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ maxWidth: "200px" }}>
                      <Text sx={{ fontSize: 14, fontWeight: "bold" }}>
                        Desktop
                      </Text>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={slide.desktopThumb}
                          alt="Imagem Desktop"
                          style={{
                            width: 200,
                            height: 90,
                            objectFit: "cover",
                            borderRadius: "13px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ maxWidth: "200px", marginTop: 2 }}>
                      <Text sx={{ fontSize: 14, fontWeight: "bold" }}>
                        Mobile
                      </Text>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={slide.mobileThumb}
                          alt="Imagem Mobile"
                          style={{
                            width: 200,
                            height: 120,
                            objectFit: "cover",
                            borderRadius: "13px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Text sx={{ marginTop: 2 }}>
                    <b>Alt:</b> {slide.alt}
                  </Text>
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Text sx={{ fontWeight: "bold", marginRight: 0.5 }}>
        Link:
      </Text>
      <Text style={{ wordBreak: "break-all" }}>
        <a
          href={slide.link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          {slide.link}
        </a>
      </Text>
      <LinkIcon />
    </Box>
                  <Text>
                    <b>Posição no slider:</b> {index + 1}
                  </Text>
                  <Box
                    sx={{
                      marginTop: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ backgroundColor: "#f3f8fc", color: "black" }}
                      value={slide.alt}
                      onClick={() => handleDeleteSlide(slide._id)}
                    >
                      <CloseIcon />
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      value={slide.link}
                      sx={{ marginLeft: 2 }}
                      onClick={() =>
                        setEditSlide({ open: true, currentSlide: slide })
                      }
                    >
                      <EditIcon />
                    </Button>
                  </Box>
                </Box>
              ))}
          </Box>
        </Container>
      ) : (
        <Text>Loading...</Text>
      )}
      {createSlide && (
        <SlideCreateDialog
          open={createSlide}
          handleClose={() => setCreateSlide(false)}
          page={page}
          reloadContent={() => loadCurrentPage(id)}
        />
      )}
      {editSlide.open && (
        <SlideEdit
          sliderId={page.slider._id}
          editSlide={editSlide}
          handleClose={() => setEditSlide({ ...editSlide, open: false })}
          slides={page.slider.slides}
        />
      )}
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          handleClose={() =>
            setConfirmationDialog({ ...confirmationDialog, open: false })
          }
          dialogTitle={confirmationDialog.dialogTitle}
          dialogMessage={confirmationDialog.dialogMessage}
          onConfirm={confirmationDialog.onConfirm}
        />
      )}
      {errorDialog.open && (
        <ErrorDialog
          open={errorDialog.open}
          handleClose={() => setErrorDialog({ ...errorDialog, open: false })}
          onConfirm={errorDialog.onConfirm}
          errorMessage={errorDialog.errorMessage}
          errorTitle={errorDialog.errorTitle}
        />
      )}
    </>
  );
};
