import { Box, Divider, Grid, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Text } from "../../core";

export const TicketForm = ({ tickets, handleTicketChange, addTicket, deleteTicket }) => {
  const [touched, setTouched] = useState({
    price: false,
  });

  const handleBlur = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mb: 1 }}>
        <Text variant="h6">Preços</Text>
      </Box>
      {tickets && tickets.length && tickets.map((ticket, ticketIndex) => (
        <Box key={`ticket:${ticketIndex}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                label="Título"
                name="title"
                variant="outlined"
                type="text"
                fullWidth
                value={ticket.title}
                onChange={(e) => handleTicketChange(e, ticketIndex)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                label="Descrição"
                name="description"
                variant="outlined"
                type="text"
                fullWidth
                value={ticket.description}
                onChange={(e) => handleTicketChange(e, ticketIndex)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                label="Preço"
                name="price"
                variant="outlined"
                type="text"
                value={ticket.price}
                onChange={(e) => handleTicketChange(e, ticketIndex)}
                onBlur={handleBlur}
                error={
                  touched.price &&
                  (ticket.price === "" ||
                    ticket.price === "R$ 0,00")
                }
                helperText={
                  touched.price &&
                  (ticket.price === "" ||
                    ticket.price === "R$ 0,00") &&
                  "Campo obrigatório"
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size="small"
                  label="Parcelamento"
                  name="installment"
                  variant="outlined"
                  type="text"
                  value={ticket.installment}
                  onChange={(e) => handleTicketChange(e, ticketIndex)}
                  fullWidth
                />
                <IconButton
                  variant="outlined"
                  onClick={() => deleteTicket(ticketIndex)}
                  sx={{ borderRadius: '50%', ml: 1 }}
                  disabled={tickets.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
                {ticketIndex === 0 && (
                  <IconButton
                    onClick={addTicket}
                    variant="contained"
                    sx={{}}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
    </Box>
  )
}
