import { Box, Typography } from "@mui/material"
import ReactQuill from "react-quill"
import { TextField } from '@mui/material'
import { useFormikContext } from "formik";
import AmenitiesTransferList from "./AmenitiesTransferList";

export default function HotelData() {
    const { values, handleChange, setFieldValue } = useFormikContext();
    console.log('values', values)
    return (
        <Box width={'100%'} px={2}>
            <Typography fontSize={20} color={'black'} fontWeight={500}>Descrição</Typography>
            <ReactQuill style={{ height: 150, marginTop: 20 }} value={values.generalDescription} name='generalDescription' onChange={(value) => setFieldValue('generalDescription', value)} />
            <Typography fontSize={20} color={'black'} fontWeight={500} mt={8}>Descrição dos Quartos</Typography>
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                <TextField type="text" multiline minRows={5} label="Quarto(Opcional)" fullWidth sx={{ width: 635, mt: 1 }} value={values.availableRoom[0].description} name='availableRoom[0].description' onChange={handleChange} />
                <TextField type="text" multiline minRows={5} label="Quarto Duplo(Opcional)" fullWidth sx={{ width: 635, mt: 1 }} value={values.availableRoom[1]?.description} name='availableRoom[1].description' onChange={handleChange} />
                <TextField type="text" multiline minRows={5} label="Quarto Triplo (Opcional)" fullWidth sx={{ width: 635, mt: 1 }} value={values.availableRoom[2]?.description} name='availableRoom[2].description' onChange={handleChange} />
                <TextField type="text" multiline minRows={5} label="Quarto Quadruplo(Opcional)" fullWidth sx={{ width: 635, mt: 1 }} value={values.availableRoom[3]?.description} name='availableRoom[3].description' onChange={handleChange} />
            </Box>
            <Typography fontSize={20} color={'black'} fontWeight={500} mt={6}>Comodidades</Typography>
            <Box width={'100%'} display={'flex'} flexWrap={'wrap'} mt={2}>
                <AmenitiesTransferList />
            </Box>
        </Box>
    )
}