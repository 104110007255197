import React, { useContext } from "react"
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"
import { Header } from "../components/layout/Header"
import { Signin } from "../views"
import { PagesProvider } from "../contexts/PagesContext"
import routes from "./routes"
import { Loading } from "../components/core/Loading"


const AppRoutes = () => {

  const { user, loadingCurrentUser } = useContext(AuthContext)

  return (
    <>
      {(!user && loadingCurrentUser) && <Loading open={loadingCurrentUser} />}
      {(!user && !loadingCurrentUser) && <Signin />}
      {user && <div style={styles.container}>
        <div style={styles.bodyContainer}>
          <Router>
            <Header />
            <div style={styles.contentContainer}>
              <PagesProvider>
                <Routes>
                  {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))}
                </Routes>
              </PagesProvider>
            </div>
          </Router>
        </div>
      </div >
      }
    </>
  )
}

export default AppRoutes;

const styles = {
  container: {
    height: "100%",
  },
  bodyContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
    marginTop: 64,
  },
  contentContainer: {
    padding: 20,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
