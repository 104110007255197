import React, { useContext, useEffect, useState } from "react"
import { FeedbackContext } from "../../contexts/FeedbackContext"
import { AppContext } from "../../contexts/AppContext"
import { Container } from "@mui/material"
import { Text } from "../../components/core"
import { ScheduleYoutubeTable } from "../../components/patterns/YoutubePlaylist/ScheduleYoutubeTable"
import { getScheduleYoutube } from "../../services/scheduleYoutubeService"

export const ScheduleYoutube = () => {

  const { setLoading } = useContext(AppContext)
  const { setResponse } = useContext(FeedbackContext)

  const [content, setContent] = useState([])

  useEffect(_ => {
    setLoading(true)
    loadDays()
    setLoading(false)
  }, [])

  const loadDays = async () => {
    const result = await getScheduleYoutube()
    setContent(result)
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Text variant="h5">Programação no Youtube</Text>
      <ScheduleYoutubeTable
        content={content}
        loadDays={loadDays}
        showSuccess={() => setResponse({ open: true, message: 'Programação do youtube atualizada com sucesso.', severity: 'success' })}
        showError={() => setResponse({ open: true, message: 'Ocorreu um erro ao atualizar a programação no youtube', severity: 'error' })}
      />
    </Container>
  )
}
