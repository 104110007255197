import api from "../services/api";

export async function uploadToS3(data,) {
    try {
        const formData = new FormData();
        data.forEach((file) => formData.append("file", file, file.name));
        const response = await api.post(`/api/upload`, formData);
        return response.data.uploadedFiles;
    } catch (error) {
        const message = error.response.data.error || 'Erro ao salvar imagem';
        throw new Error(message);


    }
};
