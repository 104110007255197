import api from "./api"

export const createWebstory = async data => {
    const response = await api.post('/api/webstory', data)
    return response.data
}

export const updateStory = async (id, data) => {
    const response = await api.patch(`/api/webstory/${id}`, data)
    return response.data
}

export const deleteStory = async (id, storyId) => {
    const response = await api.delete(`/api/page/${id}/stories/${storyId}`)
    return response.data
}

export const getStory = async (id) => {
    const response = await api.get(`/api/webstory/${id}`)
    return response.data
}
