import React, { useState } from 'react'
import { Box, Container, Text } from '../../../components/core'
import { Dialog, LinearProgress, Tab, Tabs } from '@mui/material'
import HotelMidia from './HotelMidia'
import HotelData from './HotelData'
import HotelPrices from './HotelPrices'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { uploadToS3 } from '../../../utils/uploadToS3'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../../../services/api'

export default function NewInternalHotel() {
  
  const [tabValue, setTabValue] = useState(0)
  const tabOptions = ['Imagens/Vídeo', 'Dados do Hotel', 'Valores'];
  const [loading, setLoading] = useState({
    active: false,
    error: false,
    progress: 10,
    message: 'Carregando...'
  })
  const navigate = useNavigate()
 const pathParam = useParams()
  const InternalContentSchema = Yup.object().shape({
    images: Yup.mixed()
      .required('Insira ao menos uma imagem')
      .test('notEmpty', 'O array de imagens não pode ser vazio', (value) => {
        return value && value.length > 0;
      }).test("fileSize", "O arquivo é muito grande (5MB max)", (value) => {
        if (!value.length) return true // attachment is optional
        return value[0].size <= 5000000 //(5MB max)
      }),
    generalDescription: Yup.string()
      .min(10, 'Sua descrição é muito curta!')
      .required('Campo Obrigatório'),
    availableRoom: Yup.array().required('Ao menos uma opção de quarto deve ser preenchida'),
  });
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleNewInternalHotel = async (values) => {
    setLoading((prevState) => ({ ...prevState, active: true , message: 'Salvando imagens...' , progress: 20}))
    const { images } = values;
    // PASSO 1 - UPLOAD DAS IMAGENS NO S3
    const imgUrl = await uploadToS3(images).then((uploadedImages) => {
      console.log('uploadedImages', uploadedImages)
      return uploadedImages
    })
    .catch ((error) => {
    console.log('error', error)
    setLoading((prevState) => ({ ...prevState, error: true, message: error.message || 'Erro ao salvar imagem' }))
  }).finally(() => {
    setLoading((prevState) => ({ ...prevState, progress: 60, message: 'Gravando no banco de dados...' }))
  })
  //PASSO 2 - SALVAR NO BANCO DE DADOS
  const bodyRequest = {
    ...values,
    card_id: pathParam.hotelId,
    images: imgUrl.map((img) => img.url)
  };

  console.log('bodyRequest', bodyRequest);
  await api.post('/api/hotelPageContent', bodyRequest)
  .then(() => {
    setLoading((prevState) => ({ ...prevState, progress: 100, message: 'Salvo com sucesso!' }))
    setTimeout(() => {
      navigate('/hotels')
    }, 500);
  })
}

return (
  <Container maxWidth="xl" disableGutters>
    <Text variant="h5">Pagina Interna de Hotel</Text>
    <Box my={6} display={'flex'} flexDirection={'column'} gap={4} maxWidth={1280}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="tab options">
          {tabOptions.map((option, index) => (
            <Tab key={index} label={option} />
          ))}
        </Tabs>
      </Box>
      <Box>
        <Formik
          initialValues={{
            card_id: pathParam.hotelId,
            images: [],
            youtubeVideo: '',
            generalDescription: 'Uma grande descrição',
            availableRoom: [{
              price: 0,
              installment: 12,
              description: 'um',
            },
            {
              price: 0,
              installment: 12,
              description: 'dois'
            },
            {
              price: 0,
              installment: 12,
              description: 'três'
            },
            {
              price: 0,
              installment: 12,
              description: 'quatro'
            },
            ],
            other: [ {
              description: '',
              price: '',
              installment: 12,
            }],
            amenities: [],
            isActive: true,
          }}
          validationSchema={InternalContentSchema}
          onSubmit={handleNewInternalHotel}
        >
          <Form>
            {tabValue === 0 && <HotelMidia />}
            {tabValue === 1 && <HotelData />}
            {tabValue === 2 && <HotelPrices />}
          </Form>
        </Formik>
      </Box>
    </Box>
    <Dialog
      open={loading.active}
      onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
    >
      <Box p={4}>
        <Text variant="h5">{loading.message}</Text>
        <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
          height: 20,
        }}

        />
      </Box>
    </Dialog>
  </Container>
)
}
