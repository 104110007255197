import React from "react";
import {
  Dialog,
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { Text } from "../../core";
import { UploadBox } from "../Upload/UploadBox";
import { Preview } from "../PreviewImage";

const frequencyOptions = [
  "Todos os dias",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
  "Domingo"
];

export const TransportForm = ({
  open,
  onClose,
  initialValues,
  handleChange,
  handleUpload,
  handleDeleteThumb,
  handleSubmit,
  handleEditSubmit
}) => {

  return (
    <Dialog open={open} onClose={onClose} sx={{}}>
      <Box sx={{ padding: 2, minWidth: 330, }}>
        <Text variant="h6">
          {initialValues._id ? "Editar transporte" : "Criar transporte"}
        </Text>
        <form onSubmit={initialValues._id ? handleEditSubmit : handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="Título"
              name="title"
              value={initialValues.title}
              onChange={handleChange}
              sx={{ mt: 2 }}
              required
            />
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel id="frequency-label">Frequência</InputLabel>
              <Select
                labelId="frequency-label"
                label="Frequência"
                id="frequency"
                name="frequency"
                multiple
                value={initialValues.frequency}
                onChange={handleChange}
                required
              >
                {frequencyOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!initialValues.thumbnail ? (
              <UploadBox
                uploadText="Thumbnail do transporte"
                data={initialValues}
                handleUpload={handleUpload}
                sx={{
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                  mt: 2
                }}
              />
            ) : (
              <Preview imgHeight={200} imgWidth={300} src={initialValues.thumbnail} deleteThumb={handleDeleteThumb} />
            )}
            <TextField
              label="Horário de saída"
              name="departureTime"
              type="time"
              value={initialValues.departureTime}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{ mt: 2 }}
            />
            <TextField
              label="Horário de retorno"
              name="returnTime"
              type="time"
              value={initialValues.returnTime}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 2 }}
              required
            />
            <TextField
              label="Preço promocional"
              name="pricesTable.minPrice"
              type="text"
              value={initialValues.pricesTable.minPrice}
              onChange={handleChange}
              sx={{ mt: 2 }}
              required
            />
            <TextField
              label="Preço normal"
              name="pricesTable.defaultPrice"
              type="text"
              value={initialValues.pricesTable.defaultPrice}
              onChange={handleChange}
              sx={{ mt: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};
