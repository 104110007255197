import api from "./api";

const makeRequest = async (endpoint, method, data = null) => {
  try {
    let response;
    switch (method) {
      case "GET":
        response = await api.get(endpoint);
        break;
      case "POST":
        response = await api.post(endpoint, data);
        break;
      case "PATCH":
        response = await api.patch(endpoint, data);
        break;
      case "DELETE":
        response = await api.delete(endpoint);
        break;
      default:
        throw new Error(`Invalid HTTP method: ${method}`);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export default makeRequest;
