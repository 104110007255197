import React from "react";
import { Box, Button, Text, TextField, FormControl } from "../../core";
import { InputLabel, MenuItem, Select } from "@mui/material";
import CustomMuiPicker from "../../core/DatePicker";
import { UploadBox } from "../Upload/UploadBox";
import { Preview } from "../PreviewImage";


export const FlightForm = ({ flight, handleFlightChange, handleUpload, handleDeleteThumb }) => {
  const formattedTime = (time) => {
    let date;
    if (time instanceof Date) {
      date = time;
    } else if (typeof time === 'string') {
      date = new Date(time);
    } else {
      return '';
    }

    const formatted = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    return formatted;
  };

  return (
    <Box>
      <TextField
        label="Horário de saída"
        name="departureTime"
        type="time"
        value={formattedTime(flight.departureTime)}
        onChange={handleFlightChange}
        InputLabelProps={{
          shrink: true,
        }}
        required
        sx={{ mt: 2, mr: 2, minWidth: 220 }}
      />
      <TextField
        label="Horário de chegada"
        name="arrivalTime"
        type="time"
        value={formattedTime(flight.arrivalTime)}
        onChange={handleFlightChange}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mt: 2, minWidth: 220 }}
        required
      />
      <Box sx={{ marginBlock: 2, display: "flex", gap: 2 }}>
        <CustomMuiPicker
          type="date"
          label="Dia da partida"
          onChange={handleFlightChange}
          value={flight.departureDate}
          sx={{ width: "100%" }}
        />
        <CustomMuiPicker
          type="date"
          label="Dia da chegada"
          onChange={handleFlightChange}
          value={flight.arrivalDate}
          sx={{ width: "100%" }}
        />
      </Box>
      <TextField
        label="Local de partida"
        name="departure"
        onChange={handleFlightChange}
        type="text"
        value={flight.departure}
        placeholder="Informe o local de partida"
        fullWidth
      />
      <TextField
        label="Local de chegada"
        name="arrival"
        onChange={handleFlightChange}
        type="text"
        value={flight.arrival}
        sx={{ mt: 2 }}
        placeholder="Informe o local de chegada"
        fullWidth
      />
      <TextField
        label="Conexão"
        name="layOver"
        value={flight.layOver}
        onChange={handleFlightChange}
        type="text"
        sx={{ mt: 2 }}
        placeholder="Informe o local de conexão"
        fullWidth
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Selecione o tipo de bagagem</InputLabel>
        <Select
          onChange={handleFlightChange}
          value={flight.lugged}
          label="Selecione o tipo de bagagem"
          name="lugged"
        >
          {["Mochila pequena", "Bagagem de mão", "Mala despachada"].map(
            (item, index) => (
              <MenuItem
                key={`item${item}:${index}`}
                value={item}
              >
                {item}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
        {!flight.airline.airlineThumb ? (
          <UploadBox
            uploadText="Logo da companhia aérea"
            data={flight}
            handleUpload={handleUpload}
            preview={true}
            miniPreview={true}
            button={false}
            sx={{ width: 120 }}
          />
        ) : (
          <Preview
            src={flight.airline.airlineThumb}
            deleteThumb={handleDeleteThumb}
            miniPreview
          />
        )}
        <TextField
          name="airline.name"
          label="Companhia Aérea"
          onChange={handleFlightChange}
          value={flight.airline.name}
          sx={{ mt: 2 }}
        />
      </Box>
    </Box>
  )
}
