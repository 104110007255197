export const formatValue = (value) => {
    const cleanedValue = String(value).replace(/[^\d]/g, '');
    const decimals = 2;
    const integerPart = cleanedValue.slice(0, -decimals).padStart(1, '0');
    const decimalPart = cleanedValue.slice(-decimals).padEnd(decimals, '0');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const trimmedIntegerPart = formattedIntegerPart.replace(/^0+(?=\d)/, '');

    const truncatedDecimalPart = decimalPart.slice(0, 2);
    const formattedValue = `R$ ${trimmedIntegerPart},${truncatedDecimalPart}`;

    return formattedValue;
};
