import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Switch, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import PostAddIcon from '@mui/icons-material/PostAdd';

import useApiRequest from '../../services/useApiRequest';
export default function HotelsTable({ hotelData, onDelete, onEdit, onSwitch }) {
    const internalHotelPages = useApiRequest('/api/hotelPageContent/');
    function changeActiveStatus(hotel) {
        onSwitch(hotel)
    }

    console.log('internalHotelPages', internalHotelPages)

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#f5f5f5',
                        }}>
                        <TableCell sx={{ fontWeight: 600 }}>Nome do Item</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Id</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Preço Min.</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Exibindo no site</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hotelData?.map((hotel, index) => {
                        const internalPage = internalHotelPages.data?.find(page => page.card_id === hotel._id);
                        return (
                            <TableRow
                                key={`${hotel.name}-${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="hotel">
                                    {hotel.name}
                                </TableCell>
                                <TableCell align="center">{hotel._id}</TableCell>
                                <TableCell align="center">R$ {parseFloat(hotel.minPrice).toFixed(2)}</TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={hotel.isActive}
                                        onClick={() => changeActiveStatus(hotel)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                <Tooltip title="Editar">
                                    <IconButton aria-label="edit" size="small"
                                        color='primary'
                                        onClick={() => onEdit(hotel)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={internalPage ? 'Editar Pagina Interna' : 'Criar Pagina Interna'}>
                                    <IconButton aria-label="more" size="small"
                                        color={'primary'}
                                        href={internalPage ? `hotels/edit/${internalPage._id}` : `hotels/new/${hotel._id}`}
                                    >
                                        {internalPage ? <LaunchIcon /> : <PostAddIcon />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remover Card de Hotel">
                                    <IconButton aria-label="remove" size="small"
                                        color='primary'
                                        disabled={internalPage}
                                        onClick={() => onDelete(hotel)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {!hotelData && (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                Nenhum hotel encontrado.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
