import React, { useEffect } from 'react';
import { createContext, useState, useContext, useRef } from "react";
import api from '../services/api'
import { AppContext } from "./AppContext";
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { destroyCookie, parseCookies, setCookie } from '../utils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SESSION_COOKIE = 'loumar.token';

const SESSION_COOKIE_MAX_AGE = 60 * 60;

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState()
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(false)
  const [error, setError] = useState({ open: false, message: 'Erro encontrado', severity: 'error', duration: 6000 })
  const { setLoading } = useContext(AppContext)

  const isMounted = useRef(true);

  useEffect(() => {
    const { [SESSION_COOKIE]: token } = parseCookies()
    if (isMounted.current && token) {
      requestUserInformation();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  const doLogin = (user) => {
    setLoading(true);

    api.post('/auth/signin', user).then((response) => {
      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setCookie(SESSION_COOKIE, token, { maxAge: SESSION_COOKIE_MAX_AGE });
      setUser(user)
      setLoading(false)
      setError({ open: true, message: 'Login realizado com sucesso', severity: 'success', duration: 6000 })

    }).catch(err => {
      setLoading(false)
      setError({ open: true, message: 'Erro ao fazer login', severity: 'error', duration: 6000 })
    })
  }

  const requestUserInformation = async () => {
    setLoadingCurrentUser(true)
    try {
      const token = await getSessionCookie()
      api.defaults.headers.Authorization = `Bearer ${token}`;

      const response = await api.get('/auth/signin', token);
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

      setCookie(SESSION_COOKIE, token, {
        maxAge: SESSION_COOKIE_MAX_AGE,
      });

      setUser(response.data.user)
    } catch (error) {
      console.log(error)
    }
    setLoadingCurrentUser(false);
  };

  const doLogout = () => {
    destroyCookie(SESSION_COOKIE);
    delete api.defaults.headers.delete['Authorization']
    setUser(null)
  }

  const getSessionCookie = () => {
    const { [SESSION_COOKIE]: token } = parseCookies()
    return token
  }

  const Error = () => {
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setError({ ...error, open: false });
    };
    return (
      <div style={{ position: 'absolute', bottom: 20, left: 20 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <Snackbar open={error.open} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity={error.severity} autoHideDuration={error.duration} onClose={handleClose}>{error.message}</Alert>
          </Snackbar>
        </Stack>
      </div>
    )
  }
  return (
    <AuthContext.Provider value={{ user, doLogin, doLogout, loadingCurrentUser }}>
      {children}
      {error && <Error />}
    </AuthContext.Provider>
  )
}

