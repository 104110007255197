import makeRequest from "./makeRequest";

export const getProducts = () => makeRequest("/api/product", "GET");

export const getProductById = (id) => makeRequest(`/api/product/${id}`, "GET");

export const createProduct = (data) => makeRequest("/api/product", "POST", data);

export const updateProduct = (id, data) => makeRequest(`/api/product/${id}`, "PATCH", data);

export const deleteProduct = (id) => makeRequest(`/api/product/${id}`, "DELETE");
