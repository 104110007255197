import api from "./api"

export const getAllTransports = async () => {
  try {
    const response = await api.get('/api/transport')
    return response.data
  } catch (error) {
    return error
  }
}

export const createTransport = async data => {
  try {
    const response = await api.post('/api/transport', data)
    return response.data
  } catch (error) {
    return error
  }
}

export const updateTransport = async (data) => {
  try {
    const { _id: id } = data
    const response = await api.patch(`/api/transport/${id}`, data)
    return response.data
  } catch (error) {
    return error
  }
}

export const removeTransport = async id => {
  try {
    const response = await api.delete(`/api/transport/${id}`)
    return response.data
  } catch (error) {
    return error
  }
}
