import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { useDropzone } from 'react-dropzone'
import { Box, IconButton } from '@mui/material'
import api from '../../../services/api'
import CloseIcon from '@mui/icons-material/Close';
import { Text } from '../../core'
import UploadIcon from '@mui/icons-material/Upload';
import { ErrorDialog } from '../Dialogs/ErrorDialog'


export const MultipleUploadBox = ({ children, handleUpload = () => { }, sx = {}, preview = true, data = {}, uploadText = 'Clique para selecionar ou Arraste as imagens aqui' }) => {

  const { setLoading } = useContext(AppContext)

  const [files, setFiles] = useState([]);
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const handleDeleteTempImages = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const submitUpload = async (data) => {
    try {
      setLoading(true);
      const response = await api.post(`/api/upload`, data)
      handleUpload(response.data.uploadedFiles)
    } catch (error) {
      setShowErrorDialog(true)
    }
    finally {
      setLoading(false);
    }
  }

  const callbackDependecy = () => {
    if(preview){
      return files
    }
    return []
  }

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))]);
    try {
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append("file", file, file.name));
      submitUpload(formData);
    } catch (err) {
      console.log(err);
    }
  }, [callbackDependecy]);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: Infinity,
  })

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Box>
      <Box sx={{
        backgroundColor: isDragActive ? '#e1e1e1' : '#FFF', border: '2px dashed #ccc',
        "&:  hover": {
          filter: "brightness(0.85)",
          transition: ".3s ease-in-out",
          cursor: "pointer",
        },
        ...sx
      }} {...getRootProps()}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton style={{ backgroundColor: 'transparent' }}>
              <UploadIcon />
            </IconButton>
            <Text sx={{ textAlign: 'center' }}>{uploadText}</Text>
          </Box>

        {children}
        {showErrorDialog &&
          <ErrorDialog
            title="Erro no upload"
            msg="Ocorreu um erro durante o upload, verifique o formato do arquivo e tente novamente. Limite de Upload 10mb*"
            handleClose={() => setShowErrorDialog(false)}
          />
        }

      </Box>
      <Box>
        {files.length > 0 && preview &&
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}>
            {files.map((file) => (
              <Box key={file.preview} sx={{  display: 'flex', alignItems: 'center', p: 2 }}>
                <Box style={{ display: 'flex', alignItems: 'flex-start', overflow: 'hidden', justifyContent: 'flex-end' }}>
                  <img src={file.preview} style={{ width: 200, height: 90, objectFit: "contains", borderRadius: '13px' }} alt={file.name} />
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteTempImages(file)}
                    style={{ backgroundColor: '#e1e1e2' }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        }
      </Box>
    </Box>
  )
}
