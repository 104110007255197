import api from "./api";

export const getPackages = async () => {
  try {
    const response = await api.get("/api/package");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPackage = async (id) => {
  try {
    const response = await api.get(`/api/package/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createPackage = async (data) => {
  try {
    const response = await api.post("/api/package", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatePackage = async (data) => {
  try {
    const { _id: id } = data;
    const response = await api.patch(`/api/package/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deletePackage = async (id) => {
  try {
    const response = await api.delete(`/api/package/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
