import React from 'react';
import { Button, Card, CardContent, CardMedia, Grid, IconButton } from "@mui/material"
import { Text, Box } from '../../core'
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';

export const CustomCard = ({ data, handleEdit, deleteCategory }) => {
  return (
    <Grid container spacing={1} justifyContent="center">
      {data.map((category, index) => (
        <Box key={`category:${index}`} sx={{ display: 'flex', flexDirection: 'column', margin: 2, position: 'relative' }}>
          <Card sx={{ maxWidth: 345 }}
            style={{
              textAlign: 'center',
              width: 258,
              borderRadius: 16,
              padding: 10,
              boxShadow: '2px 2px 30px -8px rgba(0, 0, 0, 0.15)'
            }}
          >
            <CardMedia
              component="img"
              height="140"
              image={category.thumb}
              alt="Thumbnail category"
              style={{ borderRadius: 16, height: 200 }}
            />
            <CardContent>
              <Text gutterBottom variant="h6" component="div">
                {category.name}
              </Text>
              <a
                href={category.link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Text sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'nowrap',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  >
                    {category.link}
                  </Text>
                  <div style={{ display: 'flex', flex: 1 }} />
                  <IconButton>
                    <LinkIcon />
                  </IconButton>
                </Box>
              </a>
              <Box sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleEdit({ open: true, data: category })}
                >
                  <EditIcon />
                </Button>
              </Box>
            </CardContent>
            <IconButton
              size='small'
              sx={{ position: 'absolute', right: 10, top: 8, backgroundColor: '#f3f7fc' }}
              onClick={() => deleteCategory(category._id)}>
              <CloseIcon />
            </IconButton>
          </Card>
        </Box>
      ))}
    </Grid >
  )
}
