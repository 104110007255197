import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { separateByComma } from "../../../utils/separateByComma";

export const TransportTable = ({ data, handleEdit, handleDelete }) => {
  const isTabletOrLarger = useMediaQuery("(min-width: 768px)");

  //   const formattedDays = formatDays(initialValues.frequency);
  return (
    <Box sx={{ width: "100%" }}>
      {isTabletOrLarger ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="transport table">
            <TableHead>
              <TableRow>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Título</TableCell>
                <TableCell>Frequência</TableCell>
                <TableCell>Horário de saída</TableCell>
                <TableCell>Horário de retorno</TableCell>
                <TableCell>Valor mínimo</TableCell>
                <TableCell>Valor em alta</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((transport) => (
                <TableRow key={transport._id}>
                  <TableCell>
                    <img
                      src={transport.thumbnail}
                      alt={transport.title}
                      width={80}
                      height={80}
                      style={{ objectFit: "contain", borderRadius: 5 }}
                    />
                  </TableCell>
                  <TableCell>{transport.title}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        transport.frequency && transport.frequency.length > 0
                          ? transport.frequency.join(", ")
                          : ""
                      }
                    >
                      <Box>
                        {transport.frequency && transport.frequency.length > 0
                          ? separateByComma(transport.frequency)
                          : ""}
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {new Date(transport.departureTime).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {new Date(transport.returnTime).toLocaleString()}
                  </TableCell>
                  <TableCell>{transport.pricesTable.minPrice}</TableCell>
                  <TableCell>{transport.pricesTable.defaultPrice}</TableCell>
                  <TableCell colSpan={2}>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        sx={{ paddingLeft: 0 }}
                        onClick={() => handleEdit(transport)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(transport)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ width: "100%" }}>
          {data.map((transport) => (
            <Accordion key={transport._id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{transport.title}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ flexDirection: "column" }}>
                <Typography gutterBottom>{transport.frequency}</Typography>
                <Typography gutterBottom>
                  Horario de saida:{" "}
                  {new Date(transport.departureTime).toLocaleString()}
                </Typography>
                <Typography gutterBottom>
                  Horario de retorno:{" "}
                  {new Date(transport.returnTime).toLocaleString()}
                </Typography>
                <Typography>
                  Valor minimo: {transport.pricesTable.minPrice}
                </Typography>
                {transport.pricesTable.defaultPrice && (
                  <Typography>
                    Valor em alta: {transport.pricesTable.defaultPrice}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
};
