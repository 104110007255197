import React, { useContext, useState, useEffect } from "react"
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@mui/system";
import api from "../../../services/api";
import { AppContext } from "../../../contexts/AppContext";

export const ScheduleYoutubeTable = ({ content, loadDays, showSuccess, showError }) => {
  const { setLoading } = useContext(AppContext)

  const contentWithEditMode = content.map(day => ({ ...day, editMode: false }))

  const [days, setDays] = useState([])

  useEffect(() => {
    setDays(contentWithEditMode)
  }, [content])

  const handleChange = ({ target }, idx) => {
    let newDays = [...days]
    newDays[idx][target.name] = target.value
    setDays(newDays)
  }

  const handleEdit = (index) => {
    let newDays = [...days]
    newDays[index].editMode = true
    setDays(newDays)
  }

  const handleConfirm = (index) => {
    let newDays = [...days]
    newDays[index].editMode = false
    setDays(newDays)
  }
  const handleDataBeforeSubmit = () => {
    const removeEditModeFromDays = days.map(day => {
      const { editMode, ...rest } = day
      return rest
    })

    const changedDays = removeEditModeFromDays.filter((day, index) => {
      return day.title !== content[index].title || day.link !== content[index].link || day.category !== content[index].category
    })

    console.log(changedDays)

    return changedDays
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const data = handleDataBeforeSubmit()
      const promises = data.map(day => updateData(day._id, day))
      console.log(promises)
      await Promise.all(promises)
      loadDays()
      showSuccess()
    } catch (error) {
      showError()
    } finally {
      setLoading(false)
    }
  }

  const updateData = async (id, data) => {
    setLoading(true)
    await api.put(`/api/scheduleYoutube/${id}`, data)
    setLoading(false)
  }

  return (
    <Box>
      <TableContainer component={Paper} sx={{ marginTop: 2 }} >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Dias da semana</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Categoria</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map((day, index) => (
              <TableRow
                key={day._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {day.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {day.editMode ?
                    <TextField
                      onChange={e => handleChange(e, index)}
                      name="title"
                      variant="standard"
                      placeholder="Título"
                      sx={{ marginRight: 2 }}
                      value={day.title}
                    />
                    :
                    day.title
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {day.editMode ?

                    <TextField
                      onChange={e => handleChange(e, index)}
                      name="category"
                      variant="standard"
                      placeholder="Categoria"
                      sx={{ marginRight: 2 }}
                      fullWidth
                      value={day.category}
                    />
                    :
                    day.category
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {day.editMode ?

                    <TextField
                      onChange={e => handleChange(e, index)}
                      name="link"
                      variant="standard"
                      placeholder="Link"
                      sx={{ marginRight: 2 }}
                      fullWidth
                      value={day.link}
                    />
                    :
                    day.link
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {day.editMode ?
                    <IconButton onClick={() => handleConfirm(index)} >
                      <CheckIcon sx={{ color: day.editMode ? '' : "Gray-Text" }} />
                    </IconButton>
                    :
                    <IconButton onClick={() => handleEdit(index)}>
                      <EditIcon sx={{ color: day.editMode ? 'Highlight' : "Gray-Text" }} />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 4 }}>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Salvar
        </Button>
      </Box>
    </Box>
  )
}
