import { Box, Button, Modal, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'

function ConfirmDeploy() {
    // https://api.vercel.com/v1/integrations/deploy/prj_oMLFsw1Qs3LZ100o6JgnjGf3LM7Z/F7isUNkefo
    axios.get('https://api.vercel.com/v1/integrations/deploy/prj_oMLFsw1Qs3LZ100o6JgnjGf3LM7Z/F7isUNkefo', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_VERCEL_API_TOKEN}`
        }
    })
    console.log('Deploying...')
}

export default function ConfirmationModal({openModal, setOpenModal, setButtonDisabled}) {

  return (
    <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        >
            <Box 
            position={'absolute'}
            width={400}
            height={200}
            top={'40%'}
            left={'40%'}
            transform={'translate(-50%, -50%)'}
            bgcolor={'white'}
            >
                <Box display={'flex'} gap={2} flexDirection={'column'} justifyContent={'center'} textAlign={'center'} p={2}>
                    <Typography variant='body1' fontWeight={600}>
                Esta ação não poderá ser desfeita, tenha certeza que todas as mudanças foram feitas corretamente.
                    </Typography>
                    <Button
                    variant='outlined'
                    onClick={() => {
                        ConfirmDeploy()
                        setButtonDisabled(() => true)
                        setOpenModal(false)
                    }}
                    >
                        Confirmar
                    </Button>
                    <Button
                    variant='contained'
                    onClick={() => setOpenModal(false)}
                    color='error'
                    >
                        Cancelar
                    </Button>
                </Box>
            </Box>

        </Modal>
  )
}
