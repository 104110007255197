import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

export const PackageSubcategoryTable = ({
  data,
  handleEditCategory,
  handleDeleteCategory,
}) => {

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Thumb</TableCell>
                <TableCell>Título da Categoria</TableCell>
                <TableCell>Slug</TableCell>
                <TableCell>Data de início</TableCell>
                <TableCell>Data de fim</TableCell>
                <TableCell align="left">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((category, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <img
                      src={
                        !category.thumbnail
                          ? "/no-image.png"
                          : category.thumbnail
                      }
                      alt="category thumbnail"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: 8,
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {category.title}
                  </TableCell>
                  <TableCell style={{ fontSize: 12, color: "#97999bf0" }}>
                    /{category.slug}
                  </TableCell>
                  <TableCell>
                    {new Date(category.periodFrom).toLocaleDateString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    {new Date(category.periodTo).toLocaleDateString("pt-BR")}
                  </TableCell>
                  <TableCell colSpan={2}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditCategory(category)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteCategory(category)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
