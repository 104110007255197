import makeRequest from "./makeRequest";

export const getFlights = () => makeRequest("/api/flight", "GET");

export const getFlightById = (id) => makeRequest(`/api/flight/${id}`, "GET");

export const createFlight = (data) => makeRequest("/api/flight", "POST", data);

export const updateFlight = (id, data) => makeRequest(`/api/flight/${id}`, "PATCH", data);

export const deleteFlight = (id) => makeRequest(`/api/flight/${id}`, "DELETE");
