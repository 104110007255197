import { Box, Typography, TextField, Button } from "@mui/material";
import { useFormikContext } from "formik";
import CurrencyInput from "react-currency-input-field";
import InputMask from 'react-input-mask';
export default function HotelPrices() {
    const { values, submitForm, handleChange, setFieldValue } = useFormikContext();
    const installmentMask = {
        mask: '99x',
        placeholder: '__x',
        maskChar: '0',
    }
    return (
        <Box width={'100%'}>
            <Typography fontSize={20} color={'black'} fontWeight={500}>Valores Padrão</Typography>
            <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} gap={2}>
                <label htmlFor={"input-currency-single"}>Quarto Single </label>
                <CurrencyInput
                    id="input-currency-single"
                    style={{ width: 430, height: 39, padding: 10, border: '1px solid #000', fontSize: 18 }}
                    name='availableRoom[0].price'
                    placeholder="R$999,99"
                    value={values.availableRoom[0]?.price}
                    prefix="R$"
                    decimalsLimit={2}
                    onValueChange={(value) => setFieldValue('availableRoom[0].price', value)}
                />
                <TextField type="text" label="Parcelamento*" sx={{ width: 230, mt: 1 }}
                    value={values.availableRoom[0]?.installment} name='availableRoom[0].installment' onChange={handleChange}
                    InputProps={{
                        inputComponent: InputMask,
                        inputProps: installmentMask,
                    }}
                />
            </Box>
            <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} gap={2}>
                <label htmlFor={"input-currency-duplo"}>Quarto Duplo </label>
                <CurrencyInput
                    id="input-currency-duplo"
                    style={{ width: 430, height: 39, padding: 10, border: '1px solid #000', fontSize: 18 }}
                    name='availableRoom[1].price'
                    placeholder="R$999,99"
                    value={values.availableRoom[1]?.price}
                    prefix="R$"
                    decimalsLimit={2}
                    onValueChange={(value) => setFieldValue('availableRoom[1].price', value)}
                />
                <TextField type="text" label="Parcelamento*" fullWidth sx={{ width: 230, mt: 1 }}
                    value={values.availableRoom[1]?.installment} name='availableRoom[1].installment' onChange={handleChange}
                    InputProps={{
                        inputComponent: InputMask,
                        inputProps: installmentMask,
                    }}
                />
            </Box>
            <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} gap={2}>
                <label htmlFor={"input-currency-triplo"}>Quarto Triplo </label>
                <CurrencyInput
                    id="input-currency-triplo"
                    style={{ width: 430, height: 39, padding: 10, border: '1px solid #000', fontSize: 18 }}
                    name='availableRoom[2].price'
                    placeholder="R$999,99"
                    value={values.availableRoom[2]?.price}
                    prefix="R$"
                    decimalsLimit={2}
                    onValueChange={(value) => setFieldValue('availableRoom[2].price', value)}
                />
                <TextField type="text" label="Parcelamento*" fullWidth sx={{ width: 230, mt: 1 }}
                    value={values.availableRoom[2]?.installment} name='availableRoom[2].installment' onChange={handleChange}
                    InputProps={{
                        inputComponent: InputMask,
                        inputProps: installmentMask,
                    }}
                />
            </Box>
            <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} gap={2}>
                <label htmlFor={"input-currency-quad"}>Quarto Quadr. </label>
                <CurrencyInput
                    id="input-currency-quad"
                    style={{ width: 430, height: 39, padding: 10, border: '1px solid #000', fontSize: 18 }}
                    name='availableRoom[3].price'
                    placeholder="R$999,99"
                    value={values.availableRoom[3]?.price}
                    prefix="R$"
                    decimalsLimit={2}
                    onValueChange={(value) => setFieldValue('availableRoom[3].price', value)}
                />
                <TextField type="text" label="Parcelamento*" fullWidth sx={{ width: 230, mt: 1 }}
                    value={values.availableRoom[3]?.installment} name='availableRoom[3].installment' onChange={handleChange}
                    InputProps={{
                        inputComponent: InputMask,
                        inputProps: installmentMask,
                    }}
                />
            </Box>

            <Typography fontSize={20} color={'black'} fontWeight={500} mt={4}>Outras Cobranças</Typography>
            <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <TextField type="text" label="Titulo" fullWidth sx={{ width: 400, mt: 1 }}
                    value={values.other[0]?.description} name='other[0].description' onChange={handleChange}
                />
                <TextField type="text" label="Preço*" fullWidth sx={{ width: 400, mt: 1 }}
                    onChange={handleChange}
                    // name='other[0].price'
                    // value={values.other[0]?.price}
                    InputProps={{
                        inputComponent: CurrencyInput,
                        inputProps: {
                            decimalsLimit: 2,
                            value: values.other[0].price,
                            name: 'other[0].price',
                            prefix: 'R$',
                        }
                    }}
                />
                <TextField type="text" label="Parcelamento*" fullWidth sx={{ width: 400, mt: 1 }}
                    value={values.other[0]?.installment} name='other[0].installment' onChange={handleChange}
                    InputProps={{
                        inputComponent: InputMask,
                        inputProps: installmentMask,
                    }}
                />
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mt={4}>
                <Button variant="contained" color="primary" sx={{ px: 4, py: 2 }} onSubmit={submitForm} type="submit">Finalizar</Button>
            </Box>
        </Box>
    )
}