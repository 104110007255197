import makeRequest from "./makeRequest";

export const getPackageCategories = async () => {
  try {
    const response = await makeRequest("/api/packageCategory", "GET");
    return response;
  } catch (error) {
    return error;
  }
};

export const getActiveCategories = async () => {
  try {
    const response = await makeRequest("/api/packageCategory/activeCategories", "GET");
    return response;
  } catch (error) {
    return error;
  }
};

export const create = async (data) => {
  try {
    const response = await makeRequest("/api/packageCategory", "POST", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePackageCategory = async (data) => {
  try {
    const { _id: id } = data;
    const response = await makeRequest(`/api/packageCategory/${id}`, "PATCH", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateStatus = async (data) => {
  try {
    const { _id: id } = data;
    const response = await makeRequest(`/api/packageCategory/${id}/status`, "PATCH", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    const response = await makeRequest(`/api/packageCategory/${id}`, "DELETE");
    return response;
  } catch (error) {
    return error;
  }
};
