import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Container,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "../../components/core";
import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import {
  create,
  remove,
  getPackageSubcategories,
  updateSubcategory,
} from "../../services/packageSubcategoryService";
import { AppContext } from "../../contexts/AppContext";
import { PackageSubcategoryTable } from "../../components/patterns/Category/PackageSubcategoryTable";
import { PackageSubcategoryForm } from "../../components/patterns/Category/PackageSubcategoryForm";
import { getPackageCategories, updatePackageCategory } from "../../services/packageCategoryService";

const initialValues = {
  _id: "",
  packageCategoryId: "",
  thumbnail: "",
  title: "",
  description: "",
  periodFrom: new Date(),
  periodTo: new Date(),
  packageCategory: {},
}

export const PackageSubcategory = () => {
  const { setLoading } = useContext(AppContext);
  const [packageSubcategory, setPackageSubcategory] = useState(initialValues);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [packageCategories, setPackageCategories] = useState([]);
  const [packageSubcategories, setPackageSubcategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});
  const [error, setError] = useState(false);

  const [response, setResponse] = useState({
    open: false,
    severity: "warning",
    message: "",
  });
  const [showDialogConfirmation, setShowDialogConfirmation] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => { },
    item: {},
  });

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async (index = 0) => {
    try {
      const data = await getPackageCategories();
      setPackageCategories(data);
      setCategorySelected(data[index]);
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao listar as categorias do pacote.`,
      });
    }
  };

  const handleChange = ({ target }) => {
    setPackageSubcategory({
      ...packageSubcategory,
      [target.name]: target.value,
    });
    !packageSubcategory.title ? setError(true) : setError(false);
  };

  const handleTitleToSlug = (title) => {
    return title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9 ]/g, "")
      .replace(/ /g, "-");
  };

  const handleSubmit = () => {
    const slug = packageSubcategory.packageCategory.slug + "/" + handleTitleToSlug(packageSubcategory.title);
    const data = {
      packageCategoryId: packageSubcategory.packageCategory._id,
      title: packageSubcategory.title,
      description: packageSubcategory.description,
      thumbnail: packageSubcategory.thumbnail,
      periodFrom: packageSubcategory.periodFrom,
      periodTo: packageSubcategory.periodTo,
      slug: slug,
    };
    setLoading(true);

    const subcategoryCreatedPromise = create(data).then(subcategoryCreated => {
      const updatedCategory = {
        ...packageSubcategory.packageCategory,
        packageSubcategories: [
          ...packageSubcategory.packageCategory.packageSubcategories,
          subcategoryCreated._id
        ]
      }
      return updatePackageCategory(updatedCategory); // isto retorna uma promessa
    });

    subcategoryCreatedPromise.then(() => {
      setResponse({
        open: true,
        severity: "success",
        message: `Categoria de evento salva com sucesso.`,
      });
      setPackageSubcategory(initialValues);
      const currentSelectedCategory = packageCategories.findIndex((category) => category._id === categorySelected._id)
      fetchData(currentSelectedCategory);
    }).catch((error) => {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao salvar a categoria de evento.`,
      });
      setPackageSubcategory({ thumb: "", title: "", active: false });
    }).finally(() => {
      setLoading(false);
      setOpenFormDialog(false);
    });
  };

  const handleDeleteCategory = (category) => {
    setShowDialogConfirmation({
      open: true,
      message: "Deseja deletar a categoria?",
      title: "Deletar Categoria",
      onConfirm: () => deleteSubCategory(category),
    });
  };

  const deleteSubCategory = async (subcategory) => {
    try {
      setLoading(true);
      if (subcategory.packageCategory) {
        const updatedCategory = {
          ...subcategory.packageCategory,
          packageSubcategories: subcategory.packageCategory.packageSubcategories.filter(
            (id) => id !== subcategory._id
          ),
        };

        await Promise.all([remove(subcategory._id), updatePackageCategory(updatedCategory)]);
      } else {
        await remove(subcategory._id);
      }

      fetchData();
      setShowDialogConfirmation({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { },
        item: {},
      });
      setResponse({
        open: true,
        severity: "success",
        message: `Subcategoria de evento deletada com sucesso.`,
      });
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao deletar a subcategoria de evento.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (data) => {
    const { url } = data;
    setPackageSubcategory({ ...packageSubcategory, thumbnail: url });
  };

  const handleEditCategory = (category) => {
    if (!packageCategories.length) {
      setResponse({
        open: true,
        severity: "warning",
        message: `Não há categorias de pacote cadastradas, acesse a aba de categorias de pacote para cadastrar uma nova categoria.`,
      });
    }
    setOpenFormDialog(true)
    setPackageSubcategory(category)
  }

  const handleDeleteTempThumb = () =>
    setPackageSubcategory({ ...packageSubcategory, thumbnail: "" });

  const handleNewCategory = () => {
    if (!packageCategories.length) {
      setResponse({
        open: true,
        severity: "warning",
        message: `Não há categorias de pacote cadastradas, acesse a aba de categorias de pacote para cadastrar uma nova categoria. `,
      });
    }
    setOpenFormDialog(true)
    setPackageSubcategory(initialValues)
  };

  const handleEditSubmit = () => {
    try {
      setLoading(true);
      const slug = categorySelected.slug + "/" + handleTitleToSlug(packageSubcategory.title);
      const data = {
        _id: packageSubcategory._id,
        packageCategoryId: categorySelected ? categorySelected._id : packageSubcategory.packageCategoryId,
        title: packageSubcategory.title,
        description: packageSubcategory.description,
        thumbnail: packageSubcategory.thumbnail,
        periodFrom: packageSubcategory.periodFrom,
        periodTo: packageSubcategory.periodTo,
        slug: slug,
      };
      const updateSubcategoryPromise = updateSubcategory(data);

      Promise.all([updateSubcategoryPromise]).then(() => {
        fetchData();

        setResponse({
          open: true,
          severity: "success",
          message: `Categoria de evento atualizada com sucesso.`,
        });
      }).catch((error) => {
        setResponse({
          open: true,
          severity: "error",
          message: `Ocorreu um erro ao atualizar a categoria de evento.`,
        });
      }).finally(() => {
        setLoading(false);
        setOpenFormDialog(false);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPackageSubcategories(categorySelected.packageSubcategories)
  }, [categorySelected])

  return (
    <Container maxWidth="xl" disableGutters>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Text variant="h5">Subcategorias de Pacote</Text>
          <Button
            onClick={handleNewCategory}
            variant="contained"
            color="primary"
          >
            Adicionar
          </Button>
        </Box>
        <FormControl sx={{ width: 300, my: 2, mr: 2 }}>
          <InputLabel>Categoria do pacote</InputLabel>
          <Select
            label="Categoria do pacote"
            value={categorySelected}
            onChange={e => setCategorySelected(e.target.value)}
            required
          >
            {packageCategories.map((category) => (
              <MenuItem key={category._id} value={category}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {packageSubcategories && packageSubcategories.length > 0 &&
          <PackageSubcategoryTable
            data={packageSubcategories}
            handleDeleteCategory={handleDeleteCategory}
            handleEditCategory={handleEditCategory}
          />
        }
        {packageCategories.length > 0 &&
          <PackageSubcategoryForm
            open={openFormDialog}
            onClose={() => setOpenFormDialog(false)}
            initialValues={packageSubcategory}
            categories={packageCategories}
            handleUpload={handleUpload}
            handleTitleToSlug={handleTitleToSlug}
            handleChange={handleChange}
            handleDeleteThumb={handleDeleteTempThumb}
            handleSubmit={handleSubmit}
            handleEditSubmit={handleEditSubmit}
            error={error}
          />
        }
      </Box>
      {response.open && (
        <SnackbarResponse
          open={response.open}
          severity={response.severity}
          message={response.message}
          onClose={() => setResponse({ open: false, message: "" })}
        />
      )}
      {showDialogConfirmation && (
        <DialogConfirmation
          open={showDialogConfirmation.open}
          title={showDialogConfirmation.title}
          message={showDialogConfirmation.message}
          onClose={() => setShowDialogConfirmation(false)}
          onConfirm={showDialogConfirmation.onConfirm}
          item={packageSubcategory}
        />
      )}
    </Container>
  );
};

const SnackbarResponse = ({
  open = false,
  message = "Ocorreu um erro desconhecido",
  severity = "warning",
  onClose = () => { },
}) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert severity={severity} sx={{ margin: 2 }} onClose={onClose}>
      {message}
    </Alert>
  </Snackbar>
);

const DialogConfirmation = ({
  open = false,
  title,
  message,
  onClose = () => { },
  item = {},
  onConfirm = () => { },
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <Button onClick={(item) => onConfirm(item)} autoFocus variant="contained">
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);
