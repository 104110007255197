import { Box, Button, Dialog, DialogActions } from '@mui/material'
import React from 'react'
import { Text } from '../../../components/core'
import { deleteInternalPage } from '../../../services/produtosService'
import { useNavigate } from 'react-router-dom'

export default function RemoveInternalPageButton({ id }) {
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const navigate = useNavigate()

    const handleRemove = async () => {
        await deleteInternalPage(id).then(() => {
            console.log('pagina interna deletada')
            navigate('/produtos')
        }
        )
    }

    return (
        <>
            <Button
                color='error'
                variant='outlined'
                onClick={() => setDialogOpen((prevState) => (!prevState))}
            >
                Remover Página Interna
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen((prevState) => (!prevState))}
            >
                <Box p={4}>
                    <Text variant="h5">Tem certeza que deseja remover essa página interna?</Text>
                </Box>
                <DialogActions>
                    <Button
                        color="error"
                        size="xl"
                        variant="contained"
                        onClick={handleRemove}
                    >
                        Remover Pagina
                    </Button>
                    <Button autoFocus
                        color="primary"
                        size="xl"
                        variant="outlined"
                        onClick={() => {
                            setDialogOpen((prevState) => (!prevState))
                        }

                        }
                    >
                        Sair
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}
