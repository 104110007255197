export default [
  {
    path: '/',
    name: 'Pages'
  },
  {
    path: '/webstories',
    name: 'Stories'
  },
  {
    path: '/sliders',
    name: 'Sliders'
  },
  {
    path: '/categories',
    name: 'Categorias'
  },
  {
    path: '/schedule',
    name: 'Programação no Youtube'
  },
  {
    path: '/packageCategories',
    name: 'Categorias de Pacote'
  },
  {
    path: '/packageSubcategories',
    name: 'Subcategoria de Pacote'
  },
  {
    path: '/packages',
    name: 'Pacotes'
  },
  {
    path: '/products',
    name: 'Produtos',
  },
  {
    path: '/transports',
    name: 'Transportes'
  },
  {
    path: '/links',
    name: 'Links úteis'
  },
]
