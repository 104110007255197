function updateNestedProperty(name, state, value) {
  const keys = name.split('.');
  const key = keys[0];

  if (keys.length > 1) {
    const nextObj = state.hasOwnProperty(key) ? state[key] : {};

    const updatedSubObject = updateNestedProperty(keys.slice(1).join('.'), nextObj, value);

    return { ...state, [key]: updatedSubObject };
  }

  return { ...state, [key]: value };
}

export default updateNestedProperty;
