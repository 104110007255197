import { Box } from "@mui/system";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ name, value, onEditorChange }) => {
  const handleEditorChange = (content) => {
    onEditorChange(content, name);
  };

  return (
    <Box sx={{}}>
      <ReactQuill
        name={name}
        value={value}
        onChange={handleEditorChange}
        style={{ height: 200, width: "100%", maxWidth: 450, paddingBottom: 30 }}
      />
    </Box>
  );
};

export default TextEditor;
