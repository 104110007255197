import { useState, useEffect, useContext, useRef, useCallback } from "react";
import makeRequest from "./makeRequest";
import { AppContext } from "../contexts/AppContext";
import { FeedbackContext } from "../contexts/FeedbackContext";

const useApiRequest = (endpoint, method = "GET", initialData = null) => {
  const isMountedRef = useRef(true);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);

  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      let responseData;
      responseData = await makeRequest(endpoint, method);
      setData(responseData);
    } catch (error) {
      setError(error);
      setResponse({
        open: true,
        message: "Ocorreu um erro desconhecido",
        severity: "warning",
        duration: 6000,
      });
    } finally {
      setLoading(false);
    }
  }, [endpoint, method, isMountedRef, setData, setError, setLoading, setResponse]);

  useEffect(() => {
    if (isMountedRef.current) {
      fetchData();
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [fetchData]);


  const refetchData = () => fetchData();

  return { data, error, refetchData };
};

export default useApiRequest;
