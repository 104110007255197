import React, { useContext, useState } from "react";
import { Box, TextField, Button } from "../../core";
import { UploadBox } from "../Upload/UploadBox";
import { Dialog, DialogContent, IconButton, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createCategory } from "../../../services/categoryService";
import { FeedbackContext } from "../../../contexts/FeedbackContext";
import { AppContext } from "../../../contexts/AppContext";

export const CategoryCreateDialog = ({
  open,
  handleClose,
  reloadCategories,
}) => {
  const { setResponse } = useContext(FeedbackContext);
  const { setLoading } = useContext(AppContext);
  const [ hasLink, setHaskLink ] = useState(false);

  const [category, setCategory] = useState({
    name: "",
    description: "",
    thumb: "",
    link: "",
  });

  const handleChange = ({ target }) => {
    setCategory({
      ...category,
      [target.name]: target.value,
    });
  };

  const handleUploadCategory = (data) => {
    const { url } = data;
    setCategory({ ...category, thumb: url });
  };

  const validateFields = (data) => {
    const { name, thumb, link } = data;
   if(hasLink) {
     if (!name || !thumb || !link) {
       return false;
     }
   }
    else if (!name) return false;
    return true;
  };

  const handleCreateCategory = async (_) => {
    try {
      if (!validateFields(category)) {
        setResponse({
          open: true,
          message: "Preencha todos os campos",
          severity: "warning",
        });
        return;
      }
      setLoading(true);
      await createCategory(category);
      reloadCategories();
      handleClose();
    } catch (error) {
      setResponse({
        open: true,
        message: "Ocorreu um erro ao criar a categoria",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} sx={{ maxWidth: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "row", padding: 2 }}>
        <Typography variant="h6">Novo category</Typography>
        <div style={{ display: "flex", flex: 1 }} />
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "390px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {!category.thumb ? (
                <UploadBox
                  uploadText="Thumbnail da categoria"
                  data={category}
                  handleUpload={handleUploadCategory}
                  sx={{
                    width: "auto",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      padding: 2,
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setCategory({ ...category, thumb: "" })}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        backgroundColor: "#e1e1e1",
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <img
                      src={category.thumb}
                      alt="category thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "200px",
                        maxWidth: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column" }}>
          <TextField
            name="name"
            onChange={handleChange}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Nome"
            fullWidth
          />
          <TextField
            name="description"
            onChange={handleChange}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Descrição"
            fullWidth
            multiline
            rows={3}
          />
          <Typography
          m={4}
          >
            Link para redirecionamento
          <Switch
            checked={hasLink}
            onChange={() => setHaskLink(!hasLink)}
          />
          </Typography>
          {hasLink &&
            <TextField
              name="link"
              onChange={handleChange}
              margin="dense"
              size="small"
              variant="outlined"
              placeholder="Link para redirecionamento"
              fullWidth
            />
          }

        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCategory}
          >
            Salvar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
