function setCookie(name, value, options = {}) {
  let expires = options.expires;
  let string = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (expires) {
    if (typeof expires === 'number') {
      const date = new Date();
      date.setTime(date.getTime() + expires * 1000);
      expires = date;
    }

    if (expires instanceof Date) {
      string += `; expires=${expires.toUTCString()}`;
    }
  }

  document.cookie = string;
}

function parseCookies() {
  const cookies = document.cookie.split('; ');
  const result = {};

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    result[name] = decodeURIComponent(value);
  }

  return result;
}

function destroyCookie(name) {
  document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

export { setCookie, parseCookies, destroyCookie}
