import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Container } from "../../components/core";
import { PackageForm } from "../../components/patterns/Package/PackageForm";
import { FeedbackContext } from "../../contexts/FeedbackContext";
// import { getPackageCategories } from "../../services/packageCategoryService";
// import { getPackageSubcategories, updateSubcategory } from "../../services/packageSubcategoryService";
import { formatValue } from "../../utils/formatCurrency";
import { handleTitleToSlug } from "../../utils/stringToSlug";
import { createPackage } from "../../services/packageService";
import { createTicket } from "../../services/ticketService";
import { useLocation, useNavigate } from "react-router-dom";
import { updateSubcategory } from "../../services/packageSubcategoryService";
import { getProducts } from "../../services/productService";

const initialTicket = {
  title: "",
  description: "",
  price: "",
  installment: ""
};

const initialState = {
  newPackage: {
    title: "",
    slug: "",
    description: "",
    evaluation: 3,
    images: [],
    document: "",
    products: [],
    extraInfo: "",
    active: true,
  },
  tickets: [initialTicket],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_NEW_PACKAGE':
      return { ...state, newPackage: action.payload };
    case 'SET_TICKETS':
      return { ...state, tickets: action.payload };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    default:
      return state;
  }
}

export const NewPackage = () => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const isSubscribed = useRef(null);

  const { setResponse } = useContext(FeedbackContext)

  useEffect(() => {
    isSubscribed.current = true;
    const fetchProducts = async () => {
      try {
        const response = await getProducts();
        dispatch({
          type: 'SET_PRODUCTS',
          payload: response
        })
      } catch (error) {
        setResponse({
          open: true,
          message: "Erro ao buscar produtos",
          severity: "error",
        })
      }
    }
    fetchProducts();
    return () => (isSubscribed.current = false);
  }, [])

  const navigate = useNavigate();

  const editorRef = useRef(null);

  const location = useLocation();

  const handleEditorChange = (content, name) => {
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, [name]: content },
    });
  };

  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, [name]: value },
    });
  };

  const handleDeleteTempImage = (index) => {
    let newImages = state.newPackage.images.filter((image, i) => i !== index);
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, images: newImages },
    });
  };

  const handleActive = ({ target }) => {
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, active: target.checked },
    });
  };

  const handleUpload = (data) => {
    const images = [...state.newPackage.images, ...data.map((image) => image.url)];
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, images: images },
    });
    setResponse({
      open: true,
      message: "Upload feito com sucesso",
      severity: "success",
    });
  };

  const handleDocumentUpload = data => {
    const { url } = data;
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, document: url },
    });
  }

  const addTicket = () => {
    dispatch({
      type: 'SET_TICKETS',
      payload: [...state.tickets, initialTicket],
    });
  };

  const deleteTicket = (index) => {
    const updatedTickets = [...state.tickets];
    updatedTickets.splice(index, 1);
    dispatch({
      type: 'SET_TICKETS',
      payload: updatedTickets,
    });
  };

  const handleTicketChange = (event, ticketIndex) => {
    let updatedTickets = [...state.tickets];

    const { name, value } = event.target;

    if (name === 'price') {
      const price = formatValue(value)
      updatedTickets[ticketIndex] = {
        ...updatedTickets[ticketIndex],
        [name]: price
      };
      dispatch({
        type: 'SET_TICKETS',
        payload: updatedTickets,
      });
      return;
    }

    updatedTickets[ticketIndex] = {
      ...updatedTickets[ticketIndex],
      [name]: value
    };

    dispatch({
      type: 'SET_TICKETS',
      payload: updatedTickets,
    });
  };

  const validateData = () => {
    const errors = [];
  
    if (!state.newPackage.title || state.newPackage.title.trim() === "") {
      errors.push("Título");
    }
  
    if (!Array.isArray(state.newPackage.images) || state.newPackage.images.length === 0) {
      errors.push("Imagens");
    }

    if (!state.newPackage.description || state.newPackage.description.trim() === "") {
      errors.push("Descrição");
    }

    if (state.tickets && !state.tickets.some(ticket => 
      ticket.title.trim() !== "" &&
      ticket.price.trim() !== "" &&
      ticket.installment.trim() !== ""
    )) {
      errors.push("Preços");
    }
    
    if (errors.length) {
      const fields = errors.join(", ");
      return `Os seguintes campos precisam ser preenchidos: ${fields}`;
    }

    return null;
  };

  const handleSubmit = async () => {
    const validationError = validateData();

    if (validationError) {
      setResponse({
        open: true,
        message: validationError,
        severity: "error",
      });
      return;
    }

    let data = {
      title: state.newPackage.title,
      slug: handleTitleToSlug(state.newPackage.title),
      description: state.newPackage.description,
      evaluation: state.newPackage.evaluation,
      images: state.newPackage.images,
      document: state.newPackage.document,
      products: state.newPackage.products,
      extraInfo: state.newPackage.extraInfo,
      active: state.newPackage.active,
    }
    const ticketIds = await handleTicketsSubmit()
    data = { ...data, tickets: ticketIds }
    const response = await createPackage(data)
    const { _id: id, packages } = location.state.subcategorySelected
    const subcategoryUpdateData = {
      _id: id,
      packages: packages.length ? [...packages, response._id] : [response._id]
    }
    await updateSubcategory(subcategoryUpdateData)
    setResponse({
      open: true,
      message: "Pacote criado com sucesso",
      severity: "success",
    });
    navigate('/packages')
  }

  const handleTicketsSubmit = async () => {
    const ticketPromises = state.tickets.map(ticket => createTicket(ticket));
    const ticketResponses = await Promise.all(ticketPromises);
    const ticketIds = ticketResponses.map(response => response._id);
    return ticketIds;
  }

  const handleRemoveDocument = () => {
    dispatch({
      type: 'SET_NEW_PACKAGE',
      payload: { ...state.newPackage, document: "" }
    });
  }

  return (
    <Container maxWidth="xl" disableGutters>
      {state.newPackage &&
        <PackageForm
          initialValues={state.newPackage}
          products={state.products}
          handleEditorChange={handleEditorChange}
          editorRef={editorRef}
          handleChange={handlePackageChange}
          handleDeleteTempImage={handleDeleteTempImage}
          handleActive={handleActive}
          handleUpload={handleUpload}
          handleDocumentUpload={handleDocumentUpload}
          handleRemoveDocument={handleRemoveDocument}
          subcategorySelected={location.state.subcategorySelected}
          tickets={state.tickets}
          handleTicketChange={handleTicketChange}
          addTicket={addTicket}
          deleteTicket={deleteTicket}
          handleSubmit={handleSubmit}
        />
      }
    </Container>
  );
};
