import React, { useEffect, useState } from 'react'
import { Box, Container, Text } from '../../../components/core'
import { Dialog, LinearProgress, Tab, Tabs } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { uploadToS3 } from '../../../utils/uploadToS3'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../../../services/api'
import useApiRequest from '../../../services/useApiRequest'
import RemoveInternalPageButton from './RemoveInternalPageButton'
import ProdutosMidia from './ProdutosMidia';
import ProdutosData from './ProdutosData';
import ProdutosPrices from './ProdutosPrices';

export default function EditProdutoForm() {
  const pathParam = useParams()
  const internalContent = useApiRequest(`/api/produtosPageContent/${pathParam.produtoId}`)
  const [tabValue, setTabValue] = useState(0)
  const [produtosCardData, setProdutosCardData] = useState({})
  const tabOptions = ['Imagens/Vídeo', 'Dados do Produto', 'Valores'];
  const [loading, setLoading] = useState({
    active: true,
    error: false,
    progress: 90,
    message: 'Carregando pagina interna...'
  })
  const navigate = useNavigate()
  const InternalContentSchema = Yup.object().shape({
    card_id: Yup.string().required('Campo Obrigatório'),
    images: Yup.array().required('Insira ao menos uma imagem').min(1, 'O array de imagens não pode ser vazio'),
    youtubeVideo: Yup.string(),
    generalDescription: Yup.string().required('Campo Obrigatório').min(10, 'Sua descrição é muito curta!'),
    observations: Yup.string(),
    isActive: Yup.boolean().required('Campo Obrigatório'),
    availableTime: Yup.array().of(
      Yup.object().shape({
        day: Yup.string(),
        startTime: Yup.string(),
        endTime: Yup.string(),
      }).notRequired()
    ),
    priceTable: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required('Campo Obrigatório'),
        price: Yup.number().required('Campo Obrigatório'),
        installment: Yup.number().required('Campo Obrigatório'),
      })
    ),
    metaTitle: Yup.string(),
  });
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleEditInternalProduto = async (values) => {
    setLoading((prevState) => ({ ...prevState, active: true, message: 'Adicionando as mudanças...', progress: 30 }))
    const { images } = values;
    let imgUrl =  internalContent.data.images
    // VERIFICA
    if (images[0] instanceof File) {
      // PASSO 1 - UPLOAD DAS IMAGENS NO S3
      imgUrl = await uploadToS3(images).then((uploadedImages) => {
        console.log('uploadedImages', uploadedImages)
        return uploadedImages
      })
        .catch((error) => {
          console.log('error', error)
          setLoading((prevState) => ({ ...prevState, error: true, message: error.message || 'Erro ao salvar imagem' }))
        }).finally(() => {
          setLoading((prevState) => ({ ...prevState, progress: 60, message: 'Gravando no banco de dados...' }))
        })

    }

      //PASSO 2 - SALVAR NO BANCO DE DADOS
      const bodyRequest = {
        ...values,
        card_id: internalContent.data.card_id,
        images: (typeof imgUrl[0] === 'string') ? internalContent.data.images : imgUrl.map((img) => img.url)
      };
      console.log('bodyRequest', bodyRequest);
      await api.patch(`/api/produtosPageContent/${bodyRequest.id || pathParam.produtoId}`, bodyRequest)
        .then(() => {
          setLoading((prevState) => ({ ...prevState, progress: 100, message: 'Salvo com sucesso!' }))
          setTimeout(() => {
            navigate('/produtos')
          }, 500);
        })
  }

  useEffect(() => {
    if (internalContent.data) {
      setLoading((prevState) => ({ ...prevState, active: false }))
      const fetchData = async () => {
        await api.get(`/api/produtos/${internalContent.data.card_id}`).then((response) => {
          setProdutosCardData(response.data)
        })
      }
      fetchData();
    }
  }, [internalContent.data])
  console.log('internalContent', internalContent)
  if (internalContent.data)
    return (
      <Container maxWidth="xl" disableGutters>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        <RemoveInternalPageButton id={internalContent.data._id}/>
        </Box>
        <Text variant="h5">Editando {produtosCardData.name || 'Produto'}</Text>
        <Box my={6} display={'flex'} flexDirection={'column'} gap={4} maxWidth={1280}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="tab options">
              {tabOptions.map((option, index) => (
                <Tab key={index} label={option} />
              ))}
            </Tabs>
          </Box>
          <Box>
            <Formik
              initialValues={internalContent.data}
              validationSchema={InternalContentSchema}
              onSubmit={handleEditInternalProduto}
            >
              <Form>
                {tabValue === 0 && <ProdutosMidia />}
                {tabValue === 1 && <ProdutosData />}
                {tabValue === 2 && <ProdutosPrices />}
              </Form>
            </Formik>
          </Box>
        </Box>
        <Dialog
          open={loading.active}
          onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
        >
          <Box p={4}>
            <Text variant="h5">{loading.message}</Text>
            <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
              height: 20,
            }}

            />
          </Box>
        </Dialog>
      </Container>
    )
  return <Dialog
    open={loading.active}
    onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
  >
    <Box p={4}>
      <Text variant="h5">{loading.message}</Text>
      <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
        height: 20,
      }}

      />
    </Box>
  </Dialog>
}

