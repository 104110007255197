import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import { SvgIcon } from '@mui/material';
import { useFormikContext } from 'formik';

const amenitiesList = [
    {
        icon: 'restaurant',
        text: 'Restaurante Buffet'
    },
    {
        icon: 'smoke_free',
        text: 'Quarto para não fumantes'
    },
    {
        icon: 'fitness_center',
        text: 'Academia'
    },
    {
        icon: 'local_laundry_service',
        text: 'Lavanderia'
    },
    {
        icon: 'tour',
        text: 'Especialista LOUMAR no hotel'
    },
    {
        icon: 'ac_unit',
        text: 'Ar Condicionado'
    },
    {
        icon: 'accessible',
        text: 'Quartos adaptados para portadores de necessidades especiais'
    },
    {
        icon: 'local_bar',
        text: 'Bar'
    },
    {
        icon: 'elevator',
        text: 'Elevador'
    },
    {
        icon:'pool',
        text: 'Piscina'
    },
    {
        icon: 'meeting_room',
        text: 'Suíte Especial'
    },
    {
        icon:'confirmation_number',
        text: 'Serviço de Vendas de Ingressos'
    },
    {
        icon: 'notifications_active',
        text: 'Serviço de Despertador'
    },
    {
        icon: 'add',
        text: 'e muito mais... Consulte um especialista para saber'
    },
    {
        icon:'room_service',
        text: 'Recepção 24 Horas'
    },
    {
        icon: 'store',
        text: 'Lojas no Hotel'
    },
    {
        icon: 'cleaning_services',
        text: 'Serviço de Quarto'
    },
    {
        icon: 'toys',
        text: 'Loja de Souvernirs'
    },
    {
        icon: 'wifi',
        text: 'Acesso Wi-fi Gratuito'
    },
    {
        icon: 'coffee',
        text: 'Café da Manhã'
    }
]
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function AmenitiesTransferList() {
    const { values, setFieldValue } = useFormikContext();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(amenitiesList.filter(item => !values.amenities.includes(item)));
    const [right, setRight] = React.useState(values.amenities || []);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    React.useEffect(() => {
        setFieldValue('amenities', right)
        console.log('right is: ', right)
    }, [right]);

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        icon={items.icon}
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'Todos itens selecionados',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
            />
            <Divider />

            <List
                sx={{
                    width: 360,
                    height: 430,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;
                    return (
                        <ListItemButton
                            key={value.text}
                            role="listitem"
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    icon={<Icon>{value.icon}</Icon>}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.text}`} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );

    return (

        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <SvgIcon component={`SmokeFreeIcon`} />
            <Grid item>{customList('Opções de comodidade', left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Comodidades do Hotel', right)}</Grid>
        </Grid>
    );
}
