import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { deleteStory } from "../../services/storyService";
import {
  ConfirmationDialog,
  WebstoryCreateDialog,
  WebstoryEditDialog,
} from "../../components/patterns";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  Button,
  IconButton,
  Text,
} from "../../components/core";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Card, CardContent, CardMedia, Grid, Paper, Typography } from "@mui/material";
import { AppContext } from "../../contexts/AppContext";
import { FeedbackContext } from "../../contexts/FeedbackContext";
import { PagesContext } from "../../contexts/PagesContext";
import { DropdownMenu } from "../../components/patterns/Select/DropdownMenu";
import { ChatBubbleOutlineOutlined, FavoriteBorderOutlined } from "@mui/icons-material";

export const Webstories = () => {
  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);
  const { pages, loadPagesContent } = useContext(PagesContext);

  const location = useLocation();
  const { state } = location;

  const [selectedPage, setSelectedPage] = useState("");
  const [showNewStory, setShowNewStory] = useState(false);
  const [showEditStory, setShowEditStory] = useState({
    open: false,
    story: {},
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState({
    open: false,
    story: null,
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (!state) {
        if (pages) setSelectedPage(pages[0]._id);
      } else {
        setSelectedPage(state.page._id);
      }
    }, 100);
    setLoading(false);
  }, [pages, state]);

  const handleChangePage = ({ target }) => {
    setSelectedPage(target.value);
  };

  const handleDeleteStory = async (storyId) => {
    setLoading(true);
    try {
      await deleteStory(selectedPage, storyId);
      loadPagesContent();
      setResponse({
        open: true,
        message: "Story excluído com sucesso.",
        severity: "success",
      });
    } catch (error) {
      setResponse({
        open: true,
        message: "Erro ao tentar excluir o story.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleShowEditDialog = (data) =>
    setShowEditStory({ open: true, story: data });

  const handleShowDeleteDialog = (data) =>
    setShowDeleteDialog({ open: true, story: data._id });

  return (
    <Container maxWidth="xl" disableGutters>
      <Text variant="h5">Stories</Text>
      <Box sx={{}}>
        <Box sx={{ padding: 2, paddingLeft: 0, display: "flex" }}>
          <FormControl fullWidth sx={{ maxWidth: "220px" }} size="small">
            <InputLabel>Página</InputLabel>
            <Select
              label="Página"
              color="primary"
              onChange={handleChangePage}
              value={selectedPage}
            >
              {!!pages &&
                pages.map((page, index) => (
                  <MenuItem key={`page:${index}`} value={page._id}>
                    {page.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", flex: 1 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowNewStory(true)}
            disabled={!selectedPage}
          >
            Novo
          </Button>
        </Box>
        <Box>
          {!!selectedPage &&
            pages.filter((item) => item._id === selectedPage)[0].webStories
              .length > 0 && (
              <Box sx={{ height: "100%", marginTop: 4 }}>
                <Grid container justifyContent="center">
                  {pages
                    .filter((item) => item._id === selectedPage)[0]
                    .webStories.map((story) => (
                      <InstagramCard
                        key={story._id}
                        data={story}
                        onEdit={handleShowEditDialog}
                        onDelete={handleShowDeleteDialog}
                      />
                    ))}
                </Grid>
              </Box>
            )}
        </Box>
      </Box>
      {showNewStory && (
        <WebstoryCreateDialog
          open={showNewStory}
          page={selectedPage}
          handleClose={() => setShowNewStory(false)}
          reloadContent={loadPagesContent}
        />
      )}
      {showEditStory.open && (
        <WebstoryEditDialog
          open={showEditStory.open}
          page={selectedPage}
          currentStory={showEditStory.story}
          handleClose={() => setShowEditStory(false)}
          reloadContent={loadPagesContent}
        />
      )}
      {showDeleteDialog.open && (
        <ConfirmationDialog
          open={showDeleteDialog.open}
          dialogTitle="Excluir Story"
          dialogMessage="Tem certeza que deseja excluir este story?"
          handleClose={() => setShowDeleteDialog({ open: false, story: null })}
          onConfirm={() => {
            handleDeleteStory(showDeleteDialog.story);
            setShowDeleteDialog({ open: false, story: null });
          }}
        />
      )}
    </Container>
  );
};

// const Story = ({ data, onEdit, onDelete }) => {
//   return (
//     <Box
//       component={Paper}
//       elevation={3}
//       key={data._id}
//       sx={{
//         position: "relative",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "220px",
//         width: "180px",
//         borderRadius: "10%",
//         padding: 2,
//         margin: 1,
//       }}
//     >
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//         <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
//           <Text>{data.title}</Text>
//           <Text>{data.subtitle}</Text>
//         </Box>
//         <Box sx={{}}>
//           <DropdownMenu
//             options={[
//               {
//                 label: "Editar",
//                 icon: <EditIcon />,
//                 action: () => onEdit(data),
//               },
//               {
//                 label: "Excluir",
//                 icon: <CloseIcon />,
//                 action: () => onDelete(data),
//               },
//             ]}
//           />
//         </Box>
//       </Box>
//       <img
//         src={data.thumbUrl}
//         alt={data.title}
//         style={{ width: "100%", height: "100%", objectFit: "contain" }}
//       />
//     </Box>
//   );
// };

export default function InstagramCard({ data, onEdit, onDelete }) {
  return (
    <Card sx={{ maxWidth: 345, width: '100%', margin: 2 }}>
      <CardMedia
        component="img"
        height="300"
        image={data.thumbUrl}
        alt="Imagem da Publicação"
      />
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography gutterBottom variant="h6" component="div">
            {data.title}
          </Typography>
          <DropdownMenu
            options={[
              {
                label: "Editar",
                icon: <EditIcon />,
                action: () => onEdit(data),
              },
              {
                label: "Excluir",
                icon: <CloseIcon />,
                action: () => onDelete(data),
              },
            ]}
          />
        </div>
        <Typography variant="body2" color="text.secondary">
          {data.subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}
