import api from './api'

export const createSlide = async data => {
    const response = await api.post('/api/slide', data)
    return response.data
}

export const deleteSlide = async id => {
    const response = await api.delete(`/api/slide/${id}`)
    return response.data
}

export const updateSlide = async ({id, data}) => {
    // console.log(id, data)
    const response = await api.patch(`/api/slide/${id}`, data)
    return response.data
}