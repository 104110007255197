import api from "./api";

export const addPage = async (data) => {
  const response = await api.post('/api/page', data)
  return response
}

export const getPages = async () => {
  const response = await api.get('/api/page')
  return response.data
}

export const getPage = async id => {
  const response = await api.get(`/api/page/${id}`)
  return response.data
}

export const updatePage = async ({ id, data }) => {
  const response = await api.patch(`/api/page/${id}`, data)
  return response.data
}

export const updateStoriesPage = async ({ id, data }) => {
  const response = await api.patch(`/api/page/${id}/stories`, data)
  return response.data
}

export const updateSliderPage = async ({ id, data }) => {
  const response = await api.patch(`/api/page/${id}/slider`, data)
  return response.data
}

export const deletePage = async (id) => {
  const response = await api.delete(`/api/page/${id}`)
  return response
}
