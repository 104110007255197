import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDate } from "../../../utils/formatDate";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

export const PagesTable = ({ pages = [], handleShowConfirmationDialog }) => {
  const navigate = useNavigate();
  return (
    <>
    {!!pages && pages.length === 0 ? <p>Loading</p> :
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 330 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome da página</TableCell>
              <TableCell>Stories</TableCell>
              <TableCell>Sliders</TableCell>
              <TableCell>Data de criação</TableCell>
              <TableCell align="right">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.map((page) => (
              <TableRow
                key={page._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {page.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    arial-label="edit"
                    onClick={() => navigate(`/webstories`, { state: { page } })}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    arial-label="edit"
                    onClick={() => navigate(`/sliders`, { state: { page } })}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell style={{ fontSize: 12, color: "#97999bf0" }}>
                  {formatDate(page.createdAt)}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleShowConfirmationDialog(page)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
  );
};
