import makeRequest from "./makeRequest";

export const getPackageSubcategories = async () => {
  try {
    const response = await makeRequest("/api/packageSubcategory", "GET");
    return response;
  } catch (error) {
    return error;
  }
};

export const getPackageSubcategory = async (id) => makeRequest(`/api/packageSubcategory/${id}`, "GET");

export const create = async (data) => {
  try {
    const response = await makeRequest("/api/packageSubcategory", "POST", data);
    return response;
  } catch (error) {
    console.log('Error is : ', error)
    return error;
  }
};

export const updateSubcategory = async (data) => {
  try {
    const { _id: id } = data;
    const response = await makeRequest(`/api/packageSubcategory/${id}`, "PATCH", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const remove = async (id) => {
  try {
    const response = await makeRequest(`/api/packageSubcategory/${id}`, "DELETE");
    return response;
  } catch (error) {
    return error;
  }
};
