import { Box, IconButton } from "@mui/material"
import { UploadBox } from "./UploadBox"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Text } from "../../core";

export const DocumentUpload = ({ document, data, handleDocumentUpload, handleRemoveDocument }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
      {!document ?
        <UploadBox
          button={true}
          uploadText="Documento (Somente PDF)"
          data={data}
          handleUpload={handleDocumentUpload}
          sx={{ backgroundColor: 'none', border: 'none' }}
        />
        :
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <PictureAsPdfIcon />
          <Text>{document}</Text>
          <IconButton onClick={handleRemoveDocument}>
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Box>
      }
    </Box>
  )
}
