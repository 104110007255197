import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Container, Text } from "../../components/core";
import { PackageForm } from "../../components/patterns/Package/PackageForm";
import { getPackageCategories } from "../../services/packageCategoryService";
import { getPackageSubcategories, updateSubcategory } from "../../services/packageSubcategoryService";
import { FeedbackContext } from "../../contexts/FeedbackContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatValue } from "../../utils/formatCurrency";
import { handleTitleToSlug } from "../../utils/stringToSlug";
import { createTicket, getTicket, updateTicket } from "../../services/ticketService";
import { getPackage, updatePackage } from "../../services/packageService";
import { getProducts } from "../../services/productService";
import { ConfirmationDialog } from "../../components/patterns/Dialogs/ConfirmationDialog";

const initialPackageState = {
  packageData: null,
  categories: [],
  subcategories: [],
  tickets: [],
  confirm: false,
}

function packageReducer(state, action) {
  switch (action.type) {
    case 'SET_PACKAGE':
      return { ...state, packageData: action.payload };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'SET_SUBCATEGORIES':
      return { ...state, subcategories: action.payload };
    case 'SET_TICKETS':
      return { ...state, tickets: action.payload };
    case 'SET_CONFIRM':
      return { ...state, confirm: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const PackageEdit = () => {

  const navigate = useNavigate();

  const location = useLocation();

  const [state, dispatch] = useReducer(packageReducer, initialPackageState);

  const { setResponse } = useContext(FeedbackContext)

  const { id } = useParams();

  const mountedPackageRef = useRef(true)

  const mountedProductRef = useRef(true)

  useEffect(() => {
    const loadPackage = async () => {
      try {
        if (!mountedPackageRef.current) return null;
        const response = await getPackage(id);
        dispatch({ type: 'SET_PACKAGE', payload: { ...response, products: response.products.map(product => product._id) } })
        dispatch({ type: 'SET_TICKETS', payload: response.tickets });
      } catch (error) {
        setResponse({
          open: true,
          message: "Erro ao carregar pacote",
          severity: "error",
        })
      }
    }
    loadPackage();
    return () => mountedPackageRef.current = false;
  }, [])

  useEffect(() => {
    const loadPackage = async () => {
      try {
        if (!mountedProductRef.current) return null;
        const response = await getProducts();
        dispatch({ type: 'SET_PRODUCTS', payload: response });
      } catch (error) {
        setResponse({
          open: true,
          message: "Erro ao carregar os produtos",
          severity: "error",
        })
      }
    }
    loadPackage();
    return () => mountedProductRef.current = false;
  }, [])

  const editorRef = useRef(null);

  const handleEditorChange = (content, name) =>
    dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, [name]: content } });


  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, [name]: value } });
  };

  const handleDeleteTempImage = (index) => {
    let newImages = state.packageData.images.filter((image, i) => i !== index);
    dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, images: newImages } });
  };

  const handleActive = ({ target }) => dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, active: target.checked } })

  const handleUpload = (data) => {
    const images = [...state.packageData.images, ...data.map((image) => image.url)];
    dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, images } });
    setResponse({
      open: true,
      message: "Upload feito com sucesso",
      severity: "success",
    });
  };

  const handleDocumentUpload = data => {
    const { url } = data;
    dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, document: url } });
  }

  const handleRemoveDocument = () => {
    dispatch({ type: 'SET_PACKAGE', payload: { ...state.packageData, document: '' } });
  }

  const addTicket = () => {
    dispatch({ type: 'SET_TICKETS', payload: [...state.tickets, { title: '', description: '', price: '', installment: '' }] });
  };

  const deleteTicket = (index) => {
    const updatedTickets = [...state.tickets];
    updatedTickets.splice(index, 1);
    dispatch({ type: 'SET_TICKETS', payload: updatedTickets });
  };

  const handleTicketChange = (event, ticketIndex) => {
    let updatedTickets = [...state.tickets];

    const { name, value } = event.target;

    if (name === 'price') {
      const price = formatValue(value)
      updatedTickets[ticketIndex] = {
        ...updatedTickets[ticketIndex],
        [name]: price
      };
      dispatch({ type: 'SET_TICKETS', payload: updatedTickets });
      return
    }

    updatedTickets[ticketIndex] = {
      ...updatedTickets[ticketIndex],
      [name]: value
    };

    dispatch({ type: 'SET_TICKETS', payload: updatedTickets });
  };

  const handleSubmit = async () => {
    const { packageData, tickets } = state;
    let data = {
      _id: packageData._id,
      title: packageData.title,
      slug: handleTitleToSlug(packageData.title),
      description: packageData.description,
      evaluation: packageData.evaluation,
      images: packageData.images,
      document: packageData.document,
      products: packageData.products,
      extraInfo: packageData.extraInfo,
      active: packageData.active,
    }

    const ticketIds = await handleTicketSubmit();

    data = { ...data, tickets: ticketIds };

    await updatePackage(data);

    setResponse({
      open: true,
      message: "Pacote criado com sucesso",
      severity: "success",
    });
    navigate('/packages')
  }

  const handleTicketSubmit = async () => {
    const ticketPromises = state.tickets.filter(item => item.price).map((ticket) => {
      const ticketData = {
        title: ticket.title,
        description: ticket.description,
        price: ticket.price,
        installment: ticket.installment
      }
      if (ticket._id) {
        updateTicket({ id: ticket._id, data: ticketData });
        return Promise.resolve({ _id: ticket._id });
      } else {
        return createTicket(ticketData);
      }
    });

    const ticketResponses = await Promise.all(ticketPromises);
    const ticketIds = ticketResponses.map((response) => response._id);
    return ticketIds;
  };

  const handleCancelConfirmation = () => {
    dispatch({ type: 'SET_CONFIRM', payload: !state.confirm });
  };

  const handleCancel = () => {
    navigate('/packages')
  }

  return (
    <Container maxWidth="xl" disableGutters>
      {state.packageData?._id ? (
        <PackageForm
          initialValues={state.packageData}
          products={state.products}
          handleEditorChange={handleEditorChange}
          editorRef={editorRef}
          handleChange={handlePackageChange}
          handleDeleteTempImage={handleDeleteTempImage}
          handleActive={handleActive}
          handleUpload={handleUpload}
          handleDocumentUpload={handleDocumentUpload}
          handleRemoveDocument={handleRemoveDocument}
          subcategorySelected={location.state.subcategorySelected}
          tickets={state.tickets}
          handleTicketChange={handleTicketChange}
          addTicket={addTicket}
          deleteTicket={deleteTicket}
          handleSubmit={handleSubmit}
          handleCancel={handleCancelConfirmation}
        />
      ) : (
        <Text>Carregando pacote...</Text>
      )}
       {state.confirm &&
        <ConfirmationDialog
          open={state.confirm}
          dialogTitle="Cancelar"
          dialogMessage="Tem certeza que deseja cancelar a edição deste pacote? Uma vez que você confirme, não será possível reverter esta ação."
          handleClose={() => dispatch({ type: 'SET_CONFIRM', payload: false })}
          cancelActionName="Não"
          confirmActionName="Sim"
          onConfirm={() => handleCancel()}
        />
      }
    </Container>
  );
};
