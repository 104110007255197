import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '../../core'

export const ErrorDialog = ({ open, errorTitle = '', errorMessage = 'Ocorreu um erro desconhecido, entre em contato com o suporte.', handleClose = () => { }, handleConfirm = () => { } }) => {

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        {errorTitle !== '' && <DialogTitle>{errorTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
