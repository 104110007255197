import React from "react";
import {
  Categories,
  PackageCategory,
  Main,
  ScheduleYoutube,
  SliderEdit,
  Sliders,
  Webstories,
  Transport,
  PackageSubcategory,
  Packages,
  NewPackage,
  PackageEdit,
  Products,
  NewProduct,
  ProductEdit,
} from "../views";
import Error from "../config/404";
import { Hotels } from "../views/Hotel/Hotels";
import { Links } from "../views/Link/Links";
import Orchestrator from "../views/Orchestrator/Orchestrator";
import NewInternalHotel from "../views/Hotel/InternalPage/NewHotelForm";
import NewHotel from "../views/Hotel/NewHotel";
import EditInternalHotel from "../views/Hotel/InternalPage/EditHotelForm";
import Produtos from "../views/Produtos";
import NewProdutoForm from "../views/Produtos/NewProdutoForm";
import ProdutosInternalPage from "../views/Produtos/InternalPage";
import EditProdutoForm from "../views/Produtos/InternalPage/EditProdutoForm";

const routes = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "webstories",
    element: <Webstories />,
  },
  {
    path: "sliders",
    element: <Sliders />,
  },
  {
    path: "sliders/:id",
    element: <SliderEdit />,
  },
  {
    path: "categories",
    element: <Categories />,
  },
  {
    path: "schedule",
    element: <ScheduleYoutube />,
  },
  {
    path: "packages",
    element: <Packages />,
  },
  {
    path: "packages/new",
    element: <NewPackage />,
  },
  {
    path: "packageCategories",
    element: <PackageCategory />,
  },
  {
    path: "packageSubcategories",
    element: <PackageSubcategory />,
  },
  {
    path: "packages/edit/:id",
    element: <PackageEdit />,
  },
  {
    path: "products",
    element: <Products />,
  },
  {
    path: "products/edit/:id",
    element: <ProductEdit />,
  },
  {
    path: "products/new",
    element: <NewProduct />
  },
  {
    path: "transports",
    element: <Transport />,
  },
  {
    path: "links",
    element: <Links />,
  },
  {
    path: "hotels",
    element: <Hotels />,
  },
  {
    path: "hotels/new",
    element: <NewHotel />,
  },
  {
    path: "hotels/new/:hotelId",
    element: <NewInternalHotel />,
  },
  {
    path: "hotels/edit/:hotelId",
    element: <EditInternalHotel />,
  },
  {
    path: "produtos",
    element: <Produtos />,
  },
  {
    path: "produtos/new",
    element: <NewProdutoForm />,
  },
  {
    path: "produtos/new/:produtoId",
    element: <ProdutosInternalPage />,
  },
  {
    path: "produtos/edit/:produtoId",
    element: <EditProdutoForm />,
  },
  {
    path: "*",
    element: <Error />,
  },
  {
    path: "orchestrator",
    element: <Orchestrator />,
  }
];

export default routes;
