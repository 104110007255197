import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Text } from "../../core";

export const PackageCards = ({ package: pkg, onNavigateToEdit, onDelete }) => (
  <Box sx={styles.card}>
    <Box sx={styles.badgeCategory}>
      <img
        src={pkg.images[0] ? pkg.images[0] : "/no-image.png"}
        alt={pkg.title}
        width={280}
        height={200}
        style={{
          objectFit: "cover",
          borderRadius: "20px 20px 0 0",
        }}
      />
    </Box>
    <Text sx={styles.titleEvent}>{pkg.title}</Text>
    <Box sx={{display: 'flex'}}>
      <Button startIcon={<EditIcon />} sx={{ margin: "10px" }} variant="contained" onClick={onNavigateToEdit}>
        Editar
      </Button>
      <Button startIcon={<DeleteIcon />} sx={{ margin: "10px" }} variant="outlined" onClick={onDelete}>
        Excluir
      </Button>
    </Box>
  </Box>
);

const styles = {
  card: {
    background: "#fff",
    boxShadow: "10px 10px 10px #333",
    height: "300px",
    width: "280px",
    borderRadius: "20px",
    margin: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
  badgeCategory: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    background: "#ffdd04",
    margin: 0,
    width: "240px",
    height: "24px",
    borderRadius: "20px 20px 0 0",
    flexDirection: "column",
  },
  titleCategory: {
    fontSize: "16px",
    padding: "0 0 6px 0 ",
  },
  titleEvent: {
    position: "relative",
    margin: "60% 0 0 0",
  },
};
