import React, { createContext, useState } from "react";
import { Loading } from "../components/core/Loading";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <AppContext.Provider value={{ setLoading, loading }}>
      {children}
      <Loading open={loading} />
    </AppContext.Provider>
  );
};
