import React, { createContext, useState } from "react";
import { SnackBarFeedback } from "../components/patterns/Dialogs/SnackBarFeedback";

export const FeedbackContext = createContext({});

const initialState = {
  open: false,
  message: "Ocorreu um erro desconhecido",
  severity: "warning",
  duration: 6000,
};

export const FeedbackProvider = ({ children }) => {
  const [response, setResponse] = useState(initialState);

  return (
    <FeedbackContext.Provider value={{ setResponse }}>
      {children}
      {response.open && (
        <SnackBarFeedback
          open={response.open}
          message={response.message}
          severity={
            !response.severity ? initialState.severity : response.severity
          }
          autoHideDuration={
            !response.duration ? initialState.duration : response.duration
          }
          onClose={() => setResponse({ ...response, open: false })}
        />
      )}
    </FeedbackContext.Provider>
  );
};
