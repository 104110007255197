import React, { useContext, useEffect, useState } from "react"
import { Box, Button, Container } from "@mui/material"
import { Text } from "../../components/core"
import { CategoryCreateDialog } from "../../components/patterns/Category/CategoryCreateDialog"
import { CategoryEditDialog } from "../../components/patterns/Category/CategoryEditDialog"
import { CustomCard } from "../../components/patterns/Cards/CustomCard"
import { AppContext } from "../../contexts/AppContext"
import { deleteCategory, getCategories } from "../../services/categoryService"
import { ConfirmationDialog } from "../../components/patterns/Dialogs/ConfirmationDialog"

export const Categories = () => {

  const { setLoading } = useContext(AppContext)

  const [categories, setCategories] = useState([])
  const [showCategoryDialog, setShowCategoryDialog] = useState(false)
  const [showDialog, setShowDialog] = useState({ open: false, id: null })
  const [categoryEditDialog, setCategoryEditDialog] = useState({ open: false, data: null })

  const loadCategories = async () => {
    const result = await getCategories()
    setCategories(result)
  }

  useEffect(_ => {
    setLoading(true)
    loadCategories()
    setLoading(false)
  }, [])

  const handleDeleteCategory = async id => {
    setShowDialog({ open: true, id: id })
  }

  const handleDelete = async _ => {
    await deleteCategory(showDialog.id)
    setShowDialog({ open: false, id: null })
    loadCategories()
  }

  return (
    <Container maxWidth="xl" disableGutters sx={{}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Text variant="h5">Categorias</Text>
        <div style={{ display: 'flex', flex: 1 }} />
        <Button variant="contained" color="primary" onClick={() => setShowCategoryDialog(true)}>Adicionar categoria</Button>
      </Box>
      <Box>
        {!!categories &&
          <CustomCard
            data={categories}
            handleEdit={setCategoryEditDialog}
            deleteCategory={handleDeleteCategory}
          />
        }
      </Box>
      {showCategoryDialog &&
        <CategoryCreateDialog
          handleClose={() => setShowCategoryDialog(false)}
          open={showCategoryDialog}
          reloadCategories={loadCategories}
        />
      }
      {categoryEditDialog.open &&
        <CategoryEditDialog
          open={categoryEditDialog.open}
          data={categoryEditDialog.data}
          handleClose={() => setCategoryEditDialog({ ...categoryEditDialog, open: false })}
          reloadCategories={loadCategories}
        />
      }
      {showDialog.open &&
        <ConfirmationDialog
          open={showDialog.open}
          dialogMessage="Deseja realmente excluir esta categoria?"
          handleClose={() => setShowDialog(false)}
          onConfirm={handleDelete}
        />
      }
    </Container>
  )
}
