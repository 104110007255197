import React, { useEffect, useState } from "react";
import { Box, Button, Container, Dialog, DialogActions, FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import { Text } from "../../components/core";
import { deleteHotel, updateHotel } from '../../services/hotelService'
import { ConfirmationDialog } from "../../components/patterns";
import useApiRequest from "../../services/useApiRequest";
import HotelsTable from "./HotelsTable";
import NewCardForm from "./NewCardForm";

export const Hotels = () => {
  const { data } = useApiRequest("/api/hotel");
  const [hotelData, setHotelData] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState({ open: false, id: "" });
  const [switchActiveDialog, setSwitchActiveDialog] = useState({ open: false, id: "", isActive: false });
  const [editDialog, setEditDialog] = useState({ open: false, hotel: {} });
  const [filter, setFilter] = useState({
    isActive: false,
    filteredData: [],
  })
  console.log('hotel data is:', hotelData);

  useEffect(() => {
    if (data) {
      setHotelData(data);
    }
  }, [data]);

  useEffect(() => {
    if (filter.isActive) {
      setFilter((prevFilter) => ({ ...prevFilter, filteredData: hotelData.filter(hotel => hotel.promotional?.isActive === true) }));
    }
    if (!filter.isActive) {
      setFilter((prevFilter) => ({ ...prevFilter, filteredData: hotelData.filter(hotel => !!hotel.promotional?.isActive === false) }));
    }
    console.log('new filter data is:', filter.filteredData);
  }, [filter.isActive, hotelData]);

const handleEditHotel = (hotel) => {
  console.log('editando hotel', hotel);
  setEditDialog({ open: true, hotel: hotel });
};

const onDelete = (hotel) => {
  setShowConfirmationDialog({ open: true, id: hotel._id });
};

const onSwitch = (hotel) => {
  setSwitchActiveDialog({ open: true, id: hotel._id, isActive: hotel.isActive });
}

const handleSwitch = async () => {
  console.log('handleswitch hotel id is:', switchActiveDialog.id);
  await updateHotel(switchActiveDialog.id, {
    isActive: !switchActiveDialog.isActive,
  }).then(() => {
    hotelData.find(hotel => hotel._id === switchActiveDialog.id).isActive = !switchActiveDialog.isActive;
    setHotelData([...hotelData]);
  }
  ).finally(() => {
    setSwitchActiveDialog({ open: false, id: '', isActive: false });
  }
  );
};

const handleDeleteHotel = async () => {
  await deleteHotel(showConfirmationDialog.id).then(() => {
    setHotelData(hotelData.filter((hotel) => hotel._id !== showConfirmationDialog.id));
    console.log('deleted hotel', showConfirmationDialog.id);
  })
  setShowConfirmationDialog(false);
};

return (
  <Container maxWidth="xl" disableGutters>
    <Box sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}>
      <Text variant="h5">Lista de Hoteis</Text>
      <FormControl>
        <InputLabel id="filtrar-por">Filtrar por</InputLabel>
        <Select
          id='filtrar-por'
          label="Filtrar por"
          variant="outlined"
          defaultValue={false}
          sx={{ width: 200 }}
          onChange={(e) => setFilter({ ...filter, isActive: e.target.value })}
        >
          <MenuItem value={true}>Promocionais</MenuItem>
          <MenuItem value={false}>Padrão</MenuItem>
        </Select>

      </FormControl>
      <Button
        href="/hotels/new"
        variant="contained"
        color="primary"
      >
        Novo hotel
      </Button>
    </Box>
    <Box
      maxWidth={962}
      display={'flex'}
    >
      <HotelsTable
        hotelData={filter.filteredData}
        onEdit={handleEditHotel}
        onDelete={onDelete}
        onSwitch={onSwitch}
      />
    </Box>
    {showConfirmationDialog.open && (
      <ConfirmationDialog
        open={showConfirmationDialog.open}
        dialogTitle="Confirmação de Remoção"
        dialogMessage="Deseja realmente remover o hotel?"
        handleClose={() => setShowConfirmationDialog({ open: false, id: '' })}
        onConfirm={handleDeleteHotel}
      />
    )}
    {switchActiveDialog.open && (
      <ConfirmationDialog
        open={switchActiveDialog.open}
        dialogTitle='Confirmar ação de ativação/desativação'
        dialogMessage={hotelData.find(hotel => hotel._id === switchActiveDialog.id)?.isActive ? "O hotel será desativado e não refletirá no site" : "O hotel será ativado e refletirá no site"}
        handleClose={() => setSwitchActiveDialog({ open: false, id: '' })}
        onConfirm={handleSwitch}
      />
    )}
    {editDialog.open && editDialog.hotel && (
      <Dialog
        open={editDialog.open}
      >
        <Box
          maxWidth="md"
          m={2}
        >
          <NewCardForm edit={editDialog.hotel} setEdit={setEditDialog} />
        </Box>
        <DialogActions>
          <Button autoFocus
            color="error"
            size="xl"
            variant="contained"
            onClick={() => {
              setEditDialog({ open: false })
              console.log('editDialog Hotel is:', editDialog.hotel)
              setHotelData((prevData) => {
                const updatedData = prevData.map((hotel) => {
                  if (hotel._id === editDialog.hotel._id) {
                    return editDialog.hotel;
                  }
                  return hotel;
                });
                return updatedData;
              });
            }

            }
          >
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </Container >
);
}
