import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Switch, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import PostAddIcon from '@mui/icons-material/PostAdd';

import useApiRequest from '../../services/useApiRequest';
import { useEffect, useState } from 'react';
export default function ProdutosTable({ produtos, onDelete ,onEdit, onSwitch }) {
    const categorias = useApiRequest('/api/category');
    const internalProdutosPage = useApiRequest('/api/produtosPageContent/');
    const [produtosList, setProdutosList] = useState();
    function changeActiveStatus(hotel) {
        onSwitch(hotel)
    }
    useEffect(() => {
        if (produtos) {
            console.log('produtos', produtos)
            setProdutosList(produtos);
        }
    }, [produtos, ]);
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#f5f5f5',
                        }}>
                        <TableCell sx={{ fontWeight: 600 }}>Produto</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Preço Min.</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Categoria</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Exibindo no Site</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 600 }}>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {produtos && produtos?.map((produto, index) => {
                         const internalPage = internalProdutosPage.data?.find(page => page.card_id === produto._id)
                        {/* const internalPage = null; */}
                        return (
                            <TableRow
                                key={`${produto.name}-${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="produto">
                                    <Typography fontWeight={650} fontSize={14}>{produto.name}</Typography>
                                </TableCell>
                                <TableCell align="center">R$ {parseFloat(produto.minPrice).toFixed(2)}</TableCell>
                                <TableCell align="center">  {categorias.data && categorias.data?.find(categoria => categoria._id === produto.category_id)?.name}</TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={produto.isActive}
                                        onClick={() => changeActiveStatus(produto)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Editar">
                                        <IconButton aria-label="edit" size="small"
                                            color='primary'
                                            onClick={() => onEdit(produto)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={internalPage ? 'Editar Pagina Interna' : 'Criar Pagina Interna'}>
                                        <IconButton aria-label="more" size="small"
                                            color={'primary'}
                                            href={internalPage ? `produtos/edit/${internalPage._id}` : `produtos/new/${produto._id}`}
                                        >
                                            {internalPage ? <LaunchIcon /> : <PostAddIcon />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Remover Card de produto">
                                        <IconButton aria-label="remove" size="small"
                                            color='primary'
                                            disabled={internalPage ? true : false}
                                            onClick={() => onDelete(produto)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {!produtosList && (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                Nenhum produto encontrado.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </TableContainer>
    );
}
