import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTicketsArrayWithMinMax } from "./ProductPriceFormat";

export const ProductsTable = ({ data, handleEdit, handleConfirmDelete }) => {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="transport table">
        <TableHead>
          <TableRow>
            <TableCell>Thumbnail</TableCell>
            <TableCell>Título</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Documento</TableCell>
            <TableCell>Voos</TableCell>
            <TableCell>Preços</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((product) => (
            <TableRow key={product._id}>
              {/* celula de Thunmbnail */}
              <TableCell>
                <img
                  src={
                    product.images.length > 0
                      ? product.images[0]
                      : "/no-image.png"
                  }
                  alt={product.title}
                  width={80}
                  height={80}
                  style={{ objectFit: "cover", borderRadius: 5 }}
                />
              </TableCell>
              <TableCell>{product.title}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell align="center">
                {product.document ?
                  <a href={product.document} target="_blank" rel="noreferrer">
                    <PictureAsPdfRoundedIcon />
                  </a>
                  : "Não cadastrado"
                }
              </TableCell>
              <TableCell> {product.description}</TableCell>
              <TableCell>
                {getTicketsArrayWithMinMax(product.tickets)}
              </TableCell>
              <TableCell colSpan={2}>
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    sx={{ paddingLeft: 0 }}
                    onClick={() => handleEdit(product)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleConfirmDelete(product._id)}
                  // criar o onConfirm
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
