import React, {  useState } from 'react'
import { Box, Container, Text } from '../../components/core'
import { Tab, Tabs, } from '@mui/material'
import NewCard from './NewCardForm';

export default function NewHotel() {
  const [tabValue, setTabValue] = useState(0);
  const tabOptions = [
    'Novo Card',
  ]
  const handleChange = (event, newValue) => {
    console.log('tabValue', tabValue)

    setTabValue(newValue);
  };
  
  return (
    <Container maxWidth="xl" disableGutters>
      <Text variant="h5">Novo Hotel</Text>
      <Box
        my={6}
        display={'flex'}
        flexDirection={'column'}
        gap={4}
        maxWidth={1280}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="tab options">
            {tabOptions.map((option, index) => (
              <Tab key={index} label={option} />
            ))}
          </Tabs>
        </Box>
         <NewCard/>
      </Box>
    </Container>
  );
}
