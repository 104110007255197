import React, { useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "../../components/core";
import { PackageCards } from "../../components/patterns/Package/EventList";
import { ConfirmationDialog } from "../../components/patterns/Dialogs/ConfirmationDialog";
import { deletePackage } from "../../services/packageService";
import useApiRequest from "../../services/useApiRequest";
import { FeedbackContext } from "../../contexts/FeedbackContext";
import { getPackageSubcategory } from "../../services/packageSubcategoryService";

const initialState = {
  packages: [],
  categorySelected: "",
  subcategorySelected: "",
  confirm: { open: false, id: "" },
};

function packagesReducer(state, action) {
  switch (action.type) {
    case "SET_PACKAGES":
      return { ...state, packages: action.payload };
    case "SET_CATEGORY":
      return { ...state, categorySelected: action.payload };
    case "SET_SUBCATEGORY":
      return { ...state, subcategorySelected: action.payload };
    case "SET_CONFIRM":
      return { ...state, confirm: action.payload };
    default:
      return state;
  }
}

export const Packages = () => {
  let navigate = useNavigate();

  const { setResponse } = useContext(FeedbackContext);

  const [state, dispatch] = useReducer(packagesReducer, initialState);

  const { data: categories, refetchData } = useApiRequest("/api/packageCategory/activeCategories", "GET", []);

  const handleFilterPackagesBySubcategory = ({ target }) => {
    const { value } = target;
    dispatch({ type: "SET_SUBCATEGORY", payload: value });
  };

  const handleConfirmDelete = (id) => {
    dispatch({ type: "SET_CONFIRM", payload: { open: true, id: id } });
  };

  const handleDeletePackage = async () => {
    await deletePackage(state.confirm.id);
    dispatch({ type: "SET_CONFIRM", payload: { open: false, id: "" } });
    refetchData();
  };

  useEffect(() => {
    const fetchSubcategories = async () => {
      if (state.categorySelected && state.subcategorySelected._id) {
        const response = await getPackageSubcategory(
          state.subcategorySelected._id
        );
        dispatch({ type: "SET_PACKAGES", payload: response.packages });
      }
    };
    fetchSubcategories();
  }, [state.subcategorySelected]);

  const handleSelectCategory = ({ target }) => {
    dispatch({ type: "SET_CATEGORY", payload: target.value });
    dispatch({ type: "SET_SUBCATEGORY", payload: "" });
    dispatch({ type: "SET_PACKAGES", payload: [] });
    if(!target.value.packageSubcategories.length) {
      setResponse({
        open: true,
        type: "warning",
        message: "Não há subcategorias para esta categoria",
      });
    }
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={{}} variant="h5">
          Pacotes
        </Typography>
        <div style={{ flex: 1 }} />
        <Button
          onClick={() => {
            const { categorySelected, subcategorySelected } = state;
            if (!!subcategorySelected?._id && !!categorySelected?._id) {
              navigate("/packages/new", {
                state: { subcategorySelected: subcategorySelected },
              });
            } else {
              setResponse({
                open: true,
                type: "error",
                message:
                  "Selecione uma categoria e subcategoria para criar um novo pacote",
              });
            }
          }}
          variant="contained"
          endIcon={<AddIcon />}
        >
          Novo pacote
        </Button>
      </Box>
      <FormControl sx={{ width: 300, my: 2, mr: 2 }}>
        <InputLabel>Categoria do pacote</InputLabel>
        <Select
          label="Categoria do pacote"
          value={state.categorySelected}
          onChange={handleSelectCategory}
          required
        >
          {categories?.map((category) => (
            <MenuItem key={category._id} value={category}>
              {category.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {state.categorySelected &&
        state.categorySelected.packageSubcategories.length > 0 && (
          <FormControl sx={{ width: 300, my: 2 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Subcategoria do pacote
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Subcategoria do pacote"
              value={state.subcategorySelected}
              onChange={handleFilterPackagesBySubcategory}
            >
              {state.categorySelected.packageSubcategories.map(
                (option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.title}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        )}
      <Box sx={{ margin: "20px", display: "flex", flexWrap: "wrap" }}>
        {state.packages.map((pkg) => (
          <PackageCards
            key={pkg._id}
            package={pkg}
            onNavigateToEdit={() =>
              navigate(`/packages/edit/${pkg._id}`, {
                state: { subcategorySelected: state.subcategorySelected },
              })
            }
            onDelete={() => handleConfirmDelete(pkg._id)}
          />
        ))}
      </Box>
      {state.confirm.open && (
        <ConfirmationDialog
          open={state.confirm.open}
          dialogTitle="Excluir Pacote"
          dialogMessage="Tem certeza que deseja excluir este pacote?"
          handleClose={() =>
            dispatch({ type: "SET_CONFIRM", payload: { open: false, id: "" } })
          }
          onConfirm={() => handleDeletePackage(state.confirm.id)}
        />
      )}
    </Container>
  );
};
