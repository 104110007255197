import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { AppBar, IconButton, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import { DrawerMenu } from "./DrawerMenu";

export const Header = () => {
  const { doLogout,  } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    setOpenDrawer((prevOpen) => !prevOpen);
  };

  return (
    <>
      <AppBar position="fixed" style={styles.container}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggleDrawer}
          sx={{width: 80}}
        >
          <MenuIcon />
        </IconButton>
        <img src="/logo-loumar.png" alt="loumar-logo" style={{width: 80, height: 80, objectFit: 'contain'}}/>
        <IconButton onClick={handleOpenMenu}>
          <ArrowDropDownIcon sx={{ color: "#fff" }} />
        </IconButton>
      </AppBar >
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        <MenuItem onClick={doLogout}>Sair</MenuItem>
      </Menu>
      <DrawerMenu open={openDrawer} onClose={handleToggleDrawer} />
    </>
  );
};

const styles = {
  container: {
    height: 64,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
