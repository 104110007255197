import makeRequest from "./makeRequest";

export const createCategory = async (data) => {
  const { name, thumb, link } = data;
  const response = await makeRequest("/api/category", "POST", { name, thumb, link });
  return response;
};

export const getCategories = async () => {
  const response = await makeRequest("/api/category", "GET");
  return response;
};

export const getCategory = async (id) => {
  const response = await makeRequest(`/api/category/${id}`, "GET");
  return response;
};

export const updateCategory = async ({ id, data }) => {
  const response = await makeRequest(`/api/category/${id}`, "PATCH", data);
  return response;
};

export const deleteCategory = async (id) => {
  const response = await makeRequest(`/api/category/${id}`, "DELETE");
  return response;
};
