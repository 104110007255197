import { Container } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getProductById, updateProduct } from '../../services/productService'
import { ProductForm } from '../../components/patterns/Products/ProductForm'
import { createTicket, getTicket, updateTicket } from '../../services/ticketService'
import { formatValue } from '../../utils/formatCurrency'
import updateNestedProperty from '../../utils/updateNestedProperty'
import { AppContext } from '../../contexts/AppContext'
import { createFlight, updateFlight } from '../../services/flightService'
import { FeedbackContext } from '../../contexts/FeedbackContext'


const initialValuesFlight = {
  departureDate: new Date(),
  arrivalDate: new Date(),
  departureTime: new Date(),
  arrivalTime: new Date(),
  departure: "",
  arrival: "",
  layOver: "",
  airline: {
    name: "",
    airlineThumb: "",
  },
  lugged: "",
  included: false,
};

const initialTicket = {
  title: "",
  description: "",
  price: "",
  installment: ""
};

export const ProductEdit = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);

  const productId = useParams().id;

  const [product, setProduct] = useState(location.state);
  const [tickets, setTickets] = useState([initialTicket]);
  const [flight, setFlight] = useState(initialValuesFlight);
  console.log("🚀 ~ file: ProductEdit.js:50 ~ ProductEdit ~ flight:", flight)
  const [openUpload, setOpenUpload] = useState(false);
  const [flightIncluded, setFlightIncluded] = useState(false);

  const isMountedRef = useRef(true);

  useEffect(() => {
    if (isMountedRef.current) {
      setLoading(true);
      fetchProduct();
      setLoading(false);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [])

  const fetchProduct = async () => {
    // if (!location.state) {
    if (isMountedRef.current) {
      const response = await getProductById(productId)
      const { tickets, flightDetail, ...rest } = response
      setProduct(rest)
      setTickets(response.tickets.concat(initialTicket))
      if (response.flightDetail)
        setFlight(response.flightDetail)
      setFlightIncluded(true)
    }
    // const tickets = response.tickets
    // if (tickets) {
    //   const promises = tickets.map(async ticket => {
    //     const response = await getTicket(ticket);
    //     return response;
    //   })
    //   const resolvedTickets = await Promise.all(promises);
    //   if (!resolvedTickets.length) {
    //     setTickets([initialTicket])
    //     return
    //   }
    //   setTickets(resolvedTickets);
    // } else {
    //   setProduct(location.state)
    // }
    // }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setProduct({ ...product, [name]: value })
  }

  const handleUpload = (data) => {
    const images = [...product.images, ...data.map((image) => image.url)];
    setProduct({ ...product, images: images });
  }

  const handleDocumentUpload = (data) => {
    const { url } = data
    setProduct({ ...product, document: url });
  }

  const handleRemoveDocument = () => {
    setProduct({ ...product, document: '' });
  }

  const addTicket = () => {
    setTickets([...tickets, initialTicket]);
  };

  const deleteTicket = (index) => {
    const updatedTickets = [...tickets];
    updatedTickets.splice(index, 1);
    setTickets(updatedTickets);
  };

  const handleTicketChange = (event, ticketIndex) => {
    let updatedTickets = [...tickets];

    const { name, value } = event.target;

    if (name === 'price') {
      const price = formatValue(value)
      updatedTickets[ticketIndex] = {
        ...updatedTickets[ticketIndex],
        [name]: price
      };
      setTickets(updatedTickets);
      return
    }

    updatedTickets[ticketIndex] = {
      ...updatedTickets[ticketIndex],
      [name]: value
    };

    setTickets(updatedTickets);
  };

  const handleFlightThumbUpload = (data) => {
    const { url } = data;
    setFlight({ ...flight, airline: { ...flight.airline, airlineThumb: url } });
  }

  const handleFlightChange = (event) => {
    const { name, value } = event.target;

    if (['arrivalTime', 'departureTime'].includes(name)) {
      const [hours, minutes] = value.split(':');
      const selectedDate = new Date();
      selectedDate.setHours(Number(hours));
      selectedDate.setMinutes(Number(minutes));
      console.log("🚀 ~ file: NewProduct.js:84 ~ handleFlightChange ~ selectedDate:", selectedDate)
      setFlight({ ...flight, [name]: selectedDate });
      return;
    }

    if (name.includes('.')) {
      setFlight(prevFlight => updateNestedProperty(name, prevFlight, value));
    } else {
      setFlight({ ...flight, [name]: value });
    }
  }

  const handleIncludedChange = ({ target }) => {
    const { value } = target;
    setFlightIncluded(value === 'true' ? true : false);
  }

  const handleDeleteThumb = () => {
    setFlight({ ...flight, airline: { ...flight.airline, airlineThumb: '' } });
  }

  const handleDeleteProductTempImage = (index) => {
    const updatedImages = [...product.images];
    updatedImages.splice(index, 1);
    setProduct({ ...product, images: updatedImages });
  };


  const submitTickets = async (tickets) => {
    const updatedTickets = await Promise.all(tickets.map(async (ticket) => {
      if (ticket._id) {
        await updateTicket({ id: ticket._id, data: ticket });
        return ticket._id; // Retorna apenas o _id do ticket
      } else {
        const createdTicket = await createTicket(ticket);
        return createdTicket._id; // Retorna apenas o _id do ticket criado
      }
    }));

    return updatedTickets;
  };

  const submitFlight = async (flight) => {
    let updatedFlight = null;
    if (flight._id) {
      const { _id, ...rest } = flight;
      await updateFlight(_id, rest);
      updatedFlight = _id; // Retorna apenas o _id do flight
    } else {
      if (flightIncluded) {
        const createdFlight = await createFlight(flight);
        updatedFlight = createdFlight._id; // Retorna apenas o _id do flight criado
      }
    }

    return updatedFlight;
  };

  const handleSubmit = async () => {
    try {
      const ticketIds = await submitTickets(tickets);
      const flightId = await submitFlight(flight);

      const updatedProduct = {
        ...product,
        flightDetail: flightId,
        tickets: ticketIds,
      };

      await updateProduct(product._id, updatedProduct);

      setResponse({
        type: 'success',
        message: 'Produto atualizado com sucesso!',
      });

      navigate('/products')
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <ProductForm
        product={product}
        flightIncluded={flightIncluded}
        handleIncludedChange={handleIncludedChange}
        handleDeleteProductTempImage={handleDeleteProductTempImage}
        handleDeleteThumb={handleDeleteThumb}
        handleChange={handleChange}
        handleUpload={handleUpload}
        openUpload={openUpload}
        setOpenUpload={setOpenUpload}
        handleDocumentUpload={handleDocumentUpload}
        handleRemoveDocument={handleRemoveDocument}
        tickets={tickets}
        addTicket={addTicket}
        deleteTicket={deleteTicket}
        handleTicketChange={handleTicketChange}
        flight={flight}
        handleFlightChange={handleFlightChange}
        handleFlightThumbUpload={handleFlightThumbUpload}
        handleSubmit={handleSubmit}
      />
    </Container>
  )
}
