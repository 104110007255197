import React, { useContext, useState } from "react";
import { Box, TextField, Button } from "../../core";
import { UploadBox } from "../Upload/UploadBox";
import { Text } from "../../core";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { updateCategory } from "../../../services/categoryService";
import { FeedbackContext } from "../../../contexts/FeedbackContext";
import { AppContext } from "../../../contexts/AppContext";
import { Preview } from "../PreviewImage";

export const CategoryEditDialog = ({
  open,
  handleClose,
  reloadCategories,
  data,
}) => {
  const { setResponse } = useContext(FeedbackContext);
  const { setLoading } = useContext(AppContext);

  const [category, setCategory] = useState({
    thumb: data.thumb || "",
    name: data.name || "",
    description: data.description || "",
    link: data.link || "",
  });

  const handleChange = ({ target }) => {
    setCategory({
      ...category,
      [target.name]: target.value,
    });
  };

  const handleUploadCategory = (data) => {
    const { url } = data;
    setCategory({ ...category, thumb: url });
  };

  const validateFields = (data) => {
    const { name, thumb, link } = data;
    if (!name || !thumb || !link) {
      return false;
    }
    return true;
  };

  const handleEditCategory = async (_) => {
    try {
      if (!validateFields(category)) {
        setResponse({
          open: true,
          message: "Preencha todos os campos",
          severity: "warning",
        });
        return;
      }
      setLoading(true);
      await updateCategory({ id: data._id, data: category });
      reloadCategories();
      handleClose();
    } catch (error) {
      setResponse({
        open: true,
        message: "Ocorreu um erro ao atualizar a categoria",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteThumb = () => {
    setCategory({ ...category, thumb: "" });
  };

  return (
    <Dialog open={open} sx={{ maxWidth: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "row", padding: 2 }}>
        <Typography variant="h6">Editar categoria</Typography>
        <div style={{ display: "flex", flex: 1 }} />
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "390px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {!category.thumb ? (
                <UploadBox
                  uploadText="Thumbnail da categoria"
                  data={category}
                  handleUpload={handleUploadCategory}
                  sx={{
                    width: "auto",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                  }}
                />
              ) : (
                <Preview src={category.thumb} deleteThumb={handleDeleteThumb}  />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column" }}>
          <TextField
            name="name"
            onChange={handleChange}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Nome"
            fullWidth
            defaultValue={category.name}
            />
          <TextField
            name="description"
            onChange={handleChange}
            defaultValue={category.description}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Descrição"
            fullWidth
            multiline
            rows={3}
          />
          <TextField
            name="link"
            onChange={handleChange}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Link para redirecionamento"
            fullWidth
            defaultValue={category.link}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditCategory}
          >
            Salvar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// const styles = {

// }
