import api from "./api"

export const createSlider = async data => {
  const response = await api.post('/api/slider', data)
  return response.data
}

export const updateSlider = async ({ id, data }) => {
  const response = await api.patch(`/api/slider/${id}`, data)
  return response.data
}