import React from 'react';
import { Alert, Snackbar, Stack } from '@mui/material';

export const SnackBarFeedback = ({ open, severity, message, duration, onClose }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };
  return (
    <div style={{ position: 'absolute', bottom: 20, left: 20 }}>
      <Stack spacing={1} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert severity={severity} autoHideDuration={duration} onClose={handleClose}>{message}</Alert>
        </Snackbar>
      </Stack>
    </div>
  )
}
