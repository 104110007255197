import { Dialog, DialogContent, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Box, TextField, Button, IconButton } from "../../core";
import { UploadBox } from "../Upload/UploadBox";
import { updateStory } from "../../../services/storyService";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../contexts/AppContext";
import { Preview } from "../PreviewImage";
import { updateStoriesPage } from "../../../services/pageService";
import { FeedbackContext } from "../../../contexts/FeedbackContext";

export const WebstoryEditDialog = ({
  open,
  handleClose,
  currentStory,
  reloadContent,
  page,
}) => {
  const [story, setStory] = useState({
    url: currentStory.url || "",
    thumbUrl: currentStory.thumbUrl || "",
    title: currentStory.title || "",
    subtitle: currentStory.subtitle || "",
  });

  const [position, setPosition] = useState(0);

  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);

  const handleUpload = (data) => {
    const { url } = data;
    setStory({ ...story, thumbUrl: url });
  };

  const handleChange = ({ target }) => {
    setStory({ ...story, [target.name]: target.value });
  };

  const validateFieldsBeforeCreate = () => {
    if (!story.url || !story.thumbUrl || !story.title || !story.subtitle) {
      return false;
    }
    return true;
  };

  const handleUpdateStory = async (story) => {
    if (!validateFieldsBeforeCreate()) {
      setResponse({
        open: true,
        message: "Todos os campos são requeridos na criação de um story.",
      });
      return;
    }
    try {
      setLoading(true);
      const { _id } = currentStory;
      const { url, thumbUrl, title, subtitle } = story;
      await updateStory(_id, { url, thumbUrl, title, subtitle });
      await updateStoriesPage({
        id: page,
        data: { position: parseInt(position), storyId: _id },
      });
      reloadContent();
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    const notAllowedKeys = ["-", ".", "e", "E", "+", " ", ","];
    const { key } = event;
    if (notAllowedKeys.includes(key)) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ maxWidth: "100%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", padding: 2 }}>
        <Typography variant="h6">Edição de story</Typography>
        <div style={{ display: "flex", flex: 1 }} />
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box sx={{ padding: 2, width: "340px" }}>
          <Box>
            <Box
              sx={{
                minHeight: "200px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {!story.thumbUrl && (
                  <UploadBox
                    handleUpload={handleUpload}
                    sx={{
                      width: "100%",
                      height: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f3f7fc",
                    }}
                  />
                )}
                {story.thumbUrl && (
                  <Preview
                    src={story.thumbUrl}
                    deleteThumb={() => setStory({ ...story, thumbUrl: "" })}
                  />
                )}
              </Box>
              <TextField
                fullWidth
                defaultValue={story.title}
                margin="dense"
                name="title"
                size="small"
                variant="outlined"
                placeholder="Título"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                defaultValue={story.subtitle}
                margin="dense"
                name="subtitle"
                size="small"
                variant="outlined"
                placeholder="Subtítulo"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                defaultValue={story.url}
                margin="dense"
                name="url"
                size="small"
                variant="outlined"
                placeholder="URL"
                onChange={handleChange}
              />
              <TextField
                id="position"
                name="position"
                label="Position"
                type="number"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                inputProps={{ min: 0 }}
                onKeyPress={handleKeyPress}
                onChange={(e) => setPosition(e.target.value)}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", padding: 2 }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleUpdateStory(story)}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
