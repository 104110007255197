import makeRequest from "./makeRequest";

export const getProdutos = () => makeRequest("/api/produtos", "GET");

export const getProdutosById = (id) => makeRequest(`/api/produtos/${id}`, "GET");

export const createProdutos = (data) => makeRequest("/api/produtos", "POST", data);

export const updateProdutos = (id, data) => makeRequest(`/api/produtos/${id}`, "PATCH", data);

export const deleteProdutos = (id) => makeRequest(`/api/produtos/${id}`, "DELETE");

export const deleteInternalPage = (id) => makeRequest(`/api/produtosPageContent/${id}`, "DELETE");

export const updateInternalPage = (id, data) => makeRequest(`/api/produtosPageContent/${id}`, "PATCH", data);

export const getAllInternalPages = () => makeRequest(`/api/produtosPageContent/`, "GET");
