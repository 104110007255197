import React, { useContext, useState } from 'react';
import { Box, TextField, Button } from "../../core"
import { UploadBox } from '../Upload/UploadBox';
import { Text } from '../../core';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { createSlide } from '../../../services/slideService';
import { updateSlider } from '../../../services/sliderService';

import CloseIcon from '@mui/icons-material/Close';
import { PagesContext } from '../../../contexts/PagesContext';

export const SlideCreateDialog = ({ open, handleClose, page, reloadContent }) => {

  // const { slider = {} } = page;
  // console.log(page)

  const { loadPageContent } = useContext(PagesContext)

  const [slide, setSlide] = useState({
    desktopThumb: '',
    mobileThumb: '',
    alt: '',
    link: '',
    position: page.slider.slides.length || 1
  })

  const handleChange = ({ target }) => {
    setSlide({
      ...slide,
      [target.name]: target.value
    })
  }

  const handlePositionChange = ({ target }) => {
    setSlide({
      ...slide,
      position: target.value
    })
  }

  const handleUploadDesktop = (data) => {
    const { url } = data;
    setSlide({ ...slide, desktopThumb: url })
  }

  const handleUploadMobile = (data) => {
    const { url } = data;
    setSlide({ ...slide, mobileThumb: url })
  }

  const handleCreateSlide = async (index) => {
    let slides = page.slider.slides.length > 0 ? page.slider.slides : []
    let newSlide = {
      desktopThumb: slide.desktopThumb,
      mobileThumb: slide.mobileThumb,
      alt: slide.alt,
      link: slide.link,
    }
    const createdSlide = await createSlide(newSlide)
    await handleUpdateSliderWithSlides(slides, index, createdSlide)
  }

  const handleUpdateSliderWithSlides = async (slides, index, createdSlide) => {
    let slidesArray = handlePositionSlide(slides, index, createdSlide)
    await updateSlider({ id: page.slider._id, data: { slides: slidesArray } })
    reloadContent()
  }

  const handlePositionSlide = (slides, index, createdSlide) => {
    // console.log(slides, index, createdSlide)
    let position = index - 1
    if (index === 0) {
      slides.unshift(createdSlide)
    } else if (position >= slides.length - 1) {
      slides.push(createdSlide)
    } else {
      slides.splice(position, 0, createdSlide)
    }
    return slides
  }

  return (
    <Dialog open={open} sx={{ maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
        <Typography variant="h6">
          Novo Slide
        </Typography>
        <div style={{ display: 'flex', flex: 1 }} />
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '390px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Text>Slide para Desktop (2000x320)</Text>
              {!slide.desktopThumb ? (
                <UploadBox
                  uploadText="Upload slide desktop"
                  data={slide}
                  handleUpload={handleUploadDesktop}
                  sx={{ width: 'auto', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}
                />
              ) : (
                <Box sx={{ position: 'relative', width: 'auto', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
                  <IconButton
                    size="small"
                    onClick={() => setSlide({ ...slide, desktopThumb: '' })}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      backgroundColor: '#e1e1e1'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <img
                    src={slide.desktopThumb}
                    alt="Slide"
                    style={{ width: 200, height: 90, objectFit: "contains", borderRadius: '13px' }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
              <Text>Slide para Mobile (390x330)</Text>
              {!slide.mobileThumb ? (
                <UploadBox
                  uploadText="Upload slide mobile"
                  data={slide}
                  handleUpload={handleUploadMobile}
                  sx={{ width: 'auto', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}
                />
              ) : (
                <Box sx={{ position: 'relative', width: 'auto', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
                  <IconButton
                    size="small"
                    onClick={() => setSlide({ ...slide, mobileThumb: '' })}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      backgroundColor: '#e1e1e1'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <img
                    src={slide.mobileThumb}
                    alt="Slide"
                    style={{ width: 200, height: 90, objectFit: "cover", borderRadius: '13px' }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column' }}>
          <TextField
            name="alt"
            onChange={handleChange}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Título (ALT)"
            fullWidth
          />
          <TextField
            name="link"
            onChange={handleChange}
            margin="dense"
            size="small"
            variant="outlined"
            placeholder="Link para redirecionamento"
            fullWidth
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 1 }}>
          <Text>Posição no slider:</Text>
          <div style={{ display: 'flex', flex: 1 }} />
          <TextField
            variant="outlined"
            defaultValue={slide.position}
            onChange={handlePositionChange}
            sx={{ maxWidth: '50px', width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!slide.desktopThumb || !slide.mobileThumb || !slide.alt || !slide.link}
            onClick={() => {
              handleCreateSlide(slide.position)
              handleClose()
              loadPageContent()
            }}
          >
            Salvar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

// const styles = {

// }
