export const separateByComma = (arr) => {
  if (arr.length === 0) {
    return "";
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(", ");
  } else {
    return arr.slice(0, 2).join(", ") + ", ...";
  }
};
