import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from '../../core'

export const ConfirmationDialog = ({
  variantButtonConfirm = 'outlined',
  open,
  dialogTitle = '',
  dialogMessage = 'Tem certeza que deseja realizar esta ação?',
  cancelActionName = 'Cancelar',
  confirmActionName = 'Confirmar',
  handleClose = () => { }, onConfirm = () => { } }) => {

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ padding: 1 }}>
        {dialogTitle !== '' && <DialogTitle>{dialogTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={variantButtonConfirm === "outlined" && "contained"} onClick={handleClose}>{cancelActionName}</Button>
          <Button variant={variantButtonConfirm} onClick={onConfirm} autoFocus>{confirmActionName}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
