import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { UploadBox } from '../../components/patterns';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Dialog, FormControl, InputLabel, LinearProgress, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { Preview } from '../../components/patterns/PreviewImage';
import CurrencyInput from 'react-currency-input-field';
import useApiRequest from '../../services/useApiRequest';
import api from '../../services/api';

// function currencyFormatter(value) {
//     const formattedValue = new Intl.NumberFormat('pt-BR', {
//         style: 'currency',
//         currency: 'BRL',
//     }).format(value);

//     return formattedValue;
// }

export default function NewProdutoForm({ edit, setEdit }) {
    const categories = useApiRequest('/api/category/');
    const [produtoImages, setprodutoImages] = useState([]);
    const [produtoImgError, setProdutoImgError] = useState(false);
    const [discount, setDiscount] = useState({
        price: 0,
        discount: 0,
        isActive: true
    });
    const [loading, setLoading] = useState({
        active: false,
        error: false,
        progress: 10,
        message: 'Carregando...'
    })
    const [submitError, setSubmitError] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const validationSchema = yup.object({
        name: yup
            .string('Insira o nome do produto')
            .required('Campo Obrigatório'),
        category_id: yup
            .string('Insira a categoria do produto')
            .required('Campo Obrigatório'),
        descriptions: yup
            .array().of(yup.string())
            .min(2, 'Insira pelo menos duas posições no array')
            .required('Campo Obrigatório'),
        minPrice: yup
            .number('Campo Obrigatório')
            .required('Campo Obrigatório'),
    });

    function onChangePromoPrice(e) {
        const { value } = e.target;
        const price = parseInt(value.replace('R$', '')) || 0;
        setDiscount({ ...discount, price: price });
    }
    function onChangePromoDiscount(e) {
        const { value } = e.target;
        const price = parseInt(value.replace('R$', '')) || 0;
        setDiscount({ ...discount, discount: price });
    }

    function handleUpload(file) {
        setprodutoImages([...produtoImages, file.url]);
    }

    async function handleSubmit(values) {
        if (produtoImages.length === 0) {
            return setProdutoImgError(true);
        }
        const bodyContent = {
            ...values,
            images: produtoImages,
            promotional: discount,
        }
        if (edit !== undefined && edit.length !== 0) {
            setLoading((prevState) => ({ ...prevState, active: true, message: 'Atualizando no banco de dados...', progress: 60 }))
            await api.put(`/api/produtos/${edit._id}`, bodyContent).then(() => {
                setLoading((prevState) => ({ ...prevState, active: false, message: 'Produto atualizado com sucesso', progress: 100 }))
                setTimeout(() => {
                    setEdit((value) => ({
                        ...value,
                        open: false,
                        produto: null
                    }))
                    window.location.reload();
                }, 100);
            })
        }
        else {
            setLoading((prevState) => ({ ...prevState, active: true, message: 'Salvando no banco de dados...', progress: 60 }))
            await api.post('/api/produtos', bodyContent).then(() => {
                setLoading((prevState) => ({ ...prevState, active: false, message: 'Produto criado com sucesso', progress: 100 }))
                setTimeout(() => {
                    navigate('/produtos')
                }, 500);
            }).catch((error) => {
                setLoading((prevState) => ({ ...prevState, active: false, message: 'Erro ao salvar no banco de dados', progress: 100, error: true }))
                setSubmitError(error.response.data.message)
            })
        }
    }

    useEffect(() => {
        if (edit) {
            setprodutoImages(edit.images)
            setDiscount(edit.promotional)
        }
    }, [edit]);

    useEffect(() => {
        if (produtoImages.length > 0) {
            setProdutoImgError(false);
        }
    }, [produtoImages]);
    return (
        <Box>
            <Typography variant="h4" gutterBottom fontSize={24}>
                {edit ? "Editar Produto" : "Novo Produto"}
            </Typography>
            <Formik
                initialValues={{
                    name: edit?.name,
                    category_id: edit?.category_id || '',
                    descriptions: edit?.descriptions || ['', ''],
                    minPrice: edit?.minPrice || 0,
                    isActive: edit?.isActive || true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}

            >
                <Form>
                    <Box
                        m={4}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                        maxWidth={960}>
                        <Typography fontSize={20} color={'#000000DE'} fontWeight={600} my={2}>Dados para o Card</Typography>
                        {categories.data && <Field name="category_id">
                            {({ field, form }) => (
                                <FormControl>
                                    <InputLabel id="category_id">Categoria</InputLabel>
                                    <Select
                                        {...field}
                                        id='category_id'
                                        label="Categoria"
                                        value={edit?.category_id || field.category_id}
                                        error={form.errors.name && form.touched.name}
                                        onChange={(e) => form.setFieldValue('category_id', e.target.value)}
                                    >
                                        {categories.data && categories.data.map((category) => (
                                            <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {field.category_id}
                                </FormControl>
                            )}
                        </Field>}
                        <Box
                            display={'inherit'}
                            gap={2}
                            flexDirection={'row'}
                        >
                            <Field name="name">
                                {({ field, form }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Nome do Produto"
                                        error={form.errors.name && form.touched.name}
                                        helperText={form.errors.name && form.touched.name && form.errors.name}
                                    />
                                )}
                            </Field>

                            <Field name="minPrice">
                                {({ field, form }) => (
                                    <TextField type="text" label="Preços a partir de*" fullWidth sx={{ width: 600 }}
                                        {...field}
                                        onChange={(e) => {
                                            const numberValue = parseFloat(e.target.value.replace('R$', ''))
                                            if (isNaN(numberValue)) {
                                                form.setFieldValue('minPrice', 0)
                                            }
                                            else form.setFieldValue('minPrice', numberValue)
                                        }
                                        }
                                        name='minPrice'
                                        // value={edit.minPrice || field.minPrice}
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            error: form.errors.minPrice && form.touched.minPrice,
                                            value: field.minPrice,
                                            inputProps: {
                                                name: 'minPrice',
                                                value: form.values.minPrice,
                                                decimalsLimit: 2,
                                                prefix: 'R$',
                                            }
                                        }}
                                    />
                                )}
                            </Field>

                        </Box>
                        <Typography fontSize={20} color={'#000000DE'} fontWeight={600} my={2}>Observações</Typography>
                        <Box
                            display={'flex'}
                            gap={2}
                        >
                            <Field name="descriptions[0]">
                                {({ field, form }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        required
                                        // value={edit?.descriptions[0] || field?.descriptions[0] || ''}
                                        label="Observação Principal"
                                        error={form.errors.descriptions && form.touched.descriptions}
                                    />
                                )}
                            </Field>
                            <Field name="descriptions[1]">
                                {({ field, form }) => (
                                    <TextField
                                        {...field}
                                        label="Descritivo"
                                        fullWidth
                                        required
                                        // value={edit?.descriptions[1] || field?.descriptions[1] || ''}
                                        error={form.errors.descriptions && form.touched.descriptions}
                                        helperText={form.errors.descriptions && form.touched.descriptions && form.errors.descriptions}
                                    />
                                )}
                            </Field>

                        </Box>
                        <Box
                            display={'flex'}
                            alignContent={'center'}
                            gap={1}
                            mt={2}
                        >
                            <Typography fontSize={18} color={'#000000DE'} fontWeight={500} justifySelf={'center'} alignSelf={'center'}>Este produto está em promoção</Typography>
                            <Switch
                                checked={discount.isActive}
                                onChange={() => setDiscount({ ...discount, isActive: !discount.isActive })}
                            />
                        </Box>
                        <Typography fontSize={20} color={'#000000DE'} fontWeight={600} mb={2}>Valores quando promocional (Opcional)</Typography>
                        <Box
                            display={'flex'}
                            gap={2}
                        >
                            <TextField type="text" label="Valor Original" fullWidth sx={{ width: 300 }}
                                onChange={onChangePromoPrice}
                                name='discount.price'
                                InputProps={{
                                    inputComponent: CurrencyInput,
                                    inputProps: {
                                        // intlConfig:{ locale: 'pt-BR', currency: 'BRL' },
                                        prefix: 'R$',
                                        decimalsLimit: 2,
                                        value: discount.price,
                                    }
                                }}
                            />
                            <TextField type="text" label="Valor Promocional" fullWidth sx={{ width: 300 }}
                                onChange={onChangePromoDiscount}
                                InputProps={{
                                    inputComponent: CurrencyInput,
                                    inputProps: {
                                        decimalsLimit: 2,
                                        value: (discount.discount),
                                        prefix: 'R$',
                                    }
                                }}
                            />
                            <TextField type="text" label="% de Desconto" fullWidth sx={{ width: 300 }}
                                disabled
                                value={discount.discount ? (((discount.price - discount.discount) / discount.price) * 100).toFixed(1) : 0}
                            />
                        </Box>

                        <Box
                            display={'inherit'}
                            gap={2}
                            flexDirection={'row'}
                            mt={2}
                        >
                            <Typography fontSize={20} color={'#000000DE'} fontWeight={600}>Imagens do Card</Typography>
                        </Box>
                        <Box
                            display={'flex'}
                            my={6}
                            gap={4}
                        >

                            {edit && produtoImages[0] ?
                                <Box
                                    display={'flex'}
                                    width={'100%'}
                                >
                                    <Preview
                                        miniPreview={true}
                                        src={produtoImages && produtoImages[0]}
                                        deleteThumb={() => setprodutoImages(produtoImages.filter((img, index) => index !== 0))}
                                    />

                                </Box>
                                :
                                <UploadBox
                                    uploadText="Arraste a Imagem Principal"
                                    data={produtoImages && produtoImages[0]}
                                    handleUpload={handleUpload}
                                    sx={{
                                        width: "300px",
                                        height: "80px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: '2px dashed #ccc',
                                        padding: 2,
                                    }}
                                />
                            }
                            {edit && produtoImages[1] ? <Preview
                                miniPreview={true}
                                src={produtoImages && produtoImages[1]}
                                deleteThumb={() => setprodutoImages(produtoImages.filter((img, index) => index !== 1))}
                            />
                                :
                                <UploadBox
                                    uploadText="Arraste a Imagem 1"
                                    data={produtoImages && produtoImages[1]}
                                    handleUpload={handleUpload}
                                    sx={{
                                        width: "300px",
                                        height: "80px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: '2px dashed #ccc',
                                        padding: 2,
                                    }}
                                />
                            }

                            {edit && produtoImages[2] ? <Preview
                                miniPreview={true}
                                src={produtoImages && produtoImages[2]}
                                deleteThumb={() => {
                                    setprodutoImages(produtoImages.filter((img, index) => index !== 2))
                                    console.log('deleteThumb produto img:', produtoImages)
                                }}
                            />
                                :
                                <UploadBox
                                    uploadText="Arraste a Imagem 2"
                                    data={produtoImages && produtoImages[2]}
                                    handleUpload={handleUpload}
                                    sx={{
                                        width: "300px",
                                        height: "80px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: '2px dashed #ccc',
                                        padding: 2,
                                    }}
                                />
                            }



                        </Box>
                        {produtoImgError && <Box m={1} fontSize={20} color={'red'}>Adicione ao menos uma imagem</Box>}
                        {submitError && <Box m={1} fontSize={20} color={'red'}>{submitError}</Box>}
                        <Button type="submit" variant='outlined' disabled={buttonDisabled}>{edit ? "Editar Produto" : "Criar Novo Produto"}</Button>
                    </Box>
                </Form>
            </Formik >
            <Dialog
                open={loading.active}
                onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
            >
                <Box p={4}>
                    <Typography variant="h5">{loading.message}</Typography>
                    <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
                        height: 20,
                    }}

                    />
                </Box>
            </Dialog>
        </Box>

    );
}