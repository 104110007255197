import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Text, TextField } from "../../core";
import { Dialog, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { UploadBox } from "../Upload/UploadBox";
import CustomMuiPicker from "../../core/DatePicker";

export const PackageSubcategoryForm = ({
  open,
  onClose,
  handleDeleteThumb,
  handleUpload,
  handleTitleToSlug,
  handleChange,
  handleSubmit,
  handleEditSubmit,
  initialValues,
  categories,
  error
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ padding: 2, minWidth: 320 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Text variant="h6">
            {!initialValues._id ? "Criar subcategoria" : "Editar subcategoria"}
          </Text>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 2,
          }}
        >
          {!initialValues.thumbnail ? (
            <UploadBox
              uploadText="Thumbnail da categoria"
              data={initialValues}
              handleUpload={handleUpload}
              sx={{
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  padding: 2,
                }}
              >
                <IconButton
                  size="small"
                  onClick={handleDeleteThumb}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: "#e1e1e1",
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <img
                  src={initialValues.thumbnail}
                  alt="category thumbnail"
                  style={{
                    width: 300,
                    height: 200,
                    objectFit: "cover",
                    borderRadius: 16,
                  }}
                />
              </Box>
            </Box>
          )}
          <TextField
            fullWidth
            label="Título"
            name="title"
            onChange={handleChange}
            value={initialValues.title}
            variant="outlined"
            sx={{ marginBlock: 2 }}
            error={error && initialValues.title.trim() === ""}
            helperText={error && initialValues.title.trim() === "" ? "O título é obrigatório" : ""}
          />
          <Text sx={{ color: "#97999bf0", paddingLeft: 2, fontSize: 12 }}>
            Slug: {handleTitleToSlug(initialValues.title)}
          </Text>
          <TextField
            fullWidth
            label="Descrição"
            name="description"
            rows={2}
            multiline
            onChange={handleChange}
            value={initialValues.description}
            variant="outlined"
            sx={{ marginTop: 2 }}
          />
          <Box sx={{ marginBlock: 2, display: 'flex', gap: 2}}>
            <CustomMuiPicker
              type="date"
              label="Período de"
              onChange={periodFrom => handleChange({ target: { name: 'periodFrom', value: periodFrom } })}
              value={initialValues.periodFrom}
              sx={{ width: '100%' }}

            />
            <CustomMuiPicker
              name="periodTo"
              type="date"
              label="Período até"
              onChange={periodTo => handleChange({ target: { name: 'periodTo', value: periodTo } })}
              value={initialValues.periodTo}
              sx={{ width: '100%' }}
            />
          </Box>
        </Box>
        <Box sx={{}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
            <Select
              name="packageCategory"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={categories.find((category) => category._id === initialValues.packageCategoryId)}
              label="Categoria"
              onChange={handleChange}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category}>{category.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 2 }}
            onClick={initialValues._id !== "" ? handleEditSubmit : handleSubmit}
            disabled={!initialValues.title}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Dialog >
  );
};
