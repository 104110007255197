import makeRequest from "./makeRequest";

export const getHotels = () => makeRequest("/api/hotel", "GET");

export const getHotelById = (id) => makeRequest(`/api/hotel/${id}`, "GET");

export const createHotel = (data) => makeRequest("/api/hotel", "POST", data);

export const updateHotel = (id, data) => makeRequest(`/api/hotel/${id}`, "PATCH", data);

export const deleteHotel = (id) => makeRequest(`/api/hotel/${id}`, "DELETE");

export const getInternalPage = (id) => makeRequest(`/api/hotelPageContent/${id}`, "GET");

export const deleteInternalPage = (id) => makeRequest(`/api/hotelPageContent/${id}`, "DELETE");

export const updateInternalPage = (id, data) => makeRequest(`/api/hotelPageContent/${id}`, "PATCH", data);

export const getAllInternalPages = () => makeRequest(`/api/hotelPageContent/`, "GET");

