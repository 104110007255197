import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Preview = ({
  src,
  miniPreview = false,
  imgWidthMin = '120px',
  imgHeightMin = '80px',
  imgWidth = "300px",
  imgHeight = "200px",
  deleteThumb,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          padding: 2,
        }}
      >
        <IconButton
          size="small"
          onClick={deleteThumb}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            backgroundColor: "#e1e1e1",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <img
          src={src}
          alt="thumbnail"
          style={{
            width: miniPreview ? imgWidthMin : imgWidth,
            height: miniPreview ? imgHeightMin : imgHeight,
            objectFit: "cover",
            borderRadius: 16,
          }}
        />
      </Box>
    </Box>
  );
};
