import React, { useContext, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { FeedbackContext } from "../../../contexts/FeedbackContext";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { UploadBox } from "../Upload/UploadBox";
import { updateStoriesPage } from "../../../services/pageService";
import { createWebstory } from "../../../services/storyService";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog";
import { Preview } from "../PreviewImage";

export const WebstoryCreateDialog = ({
  open,
  page,
  handleClose,
  reloadContent,
}) => {
  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);

  const [story, setStory] = useState({
    url: "",
    thumbUrl: "",
    title: "",
    subtitle: "",
  });
  const [position, setPosition] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  const handleChange = ({ target }) => {
    setStory({ ...story, [target.name]: target.value });
  };

  const validateFieldsBeforeCreate = () => {
    console.log('aqui')
    if (!story.url || !story.thumbUrl || !story.title || !story.subtitle) {
      return false;
    }
    return true;
  };

  const handleCreateStory = async ({ position, story }) => {
    if (!validateFieldsBeforeCreate()) {
      setResponse({
        open: true,
        message: "Todos os campos são requeridos na criação de um story.",
      });
      return;
    }
    try {
      setLoading(true);
      const data = await createWebstory(story);
      await updateStoriesPage({
        id: page,
        data: { position: parseInt(position), storyId: data._id },
      });
      handleClose();
      reloadContent();
      setResponse({
        open: true,
        message: "Story criado com sucesso",
        severity: "success",
      });
    } catch (error) {
      setResponse({
        open: true,
        message: "Ocorreu um erro ao criar o story.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (data) => {
    const { url } = data;
    if (url) {
      setStory({ ...story, thumbUrl: url });
      setResponse({
        open: true,
        message: "Upload realizado com sucesso.",
        severity: "success",
      });
    }
  };

  const handleKeyPress = (event) => {
    const notAllowedKeys = ["-", ".", "e", "E", "+", " ", ","];
    const { key } = event;
    if (notAllowedKeys.includes(key)) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ maxWidth: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 2,
          minWidth: 330,
        }}
      >
        <Typography variant="h6">Novo story</Typography>
        <div style={{ display: "flex", flex: 1 }} />
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              minHeight: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {story.thumbUrl ? (
              <Preview
                src={story.thumbUrl}
                deleteThumb={() => setStory({ ...story, thumbUrl: "" })}
              />
            ) : (
              <UploadBox
                handleUpload={handleUpload}
                sx={{
                  width: "100%",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f3f7fc",
                }}
              />
            )}
            <TextField
              margin="dense"
              name="title"
              size="small"
              variant="outlined"
              placeholder="Título"
              onChange={handleChange}
              sx={{ mt: 2 }}
              fullWidth
            />
            <TextField
              margin="dense"
              name="subtitle"
              size="small"
              variant="outlined"
              placeholder="Subtítulo"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              name="url"
              size="small"
              variant="outlined"
              placeholder="URL"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              id="position"
              name="position"
              label="Position"
              type="number"
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              inputProps={{ min: 0 }}
              onKeyPress={handleKeyPress}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCreateStory({ position, story })}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
      </DialogContent>
      {showDialog && (
        <ConfirmationDialog
          open={showDialog}
          dialogTitle="Atenção"
          dialogMessage="Preencha todos os campos para criar o story"
          handleClose={() => setShowDialog(false)}
          onConfirm={() => setShowDialog(false)}
        />
      )}
    </Dialog>
  );
};
