import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Container, Button, Text, } from "../../components/core";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePage } from "../../services/pageService";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { createSlider } from "../../services/sliderService";
import { AppContext } from "../../contexts/AppContext";
import { PagesContext } from "../../contexts/PagesContext";
import { FeedbackContext } from "../../contexts/FeedbackContext";

export const Sliders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state = {} } = location;

  const { setLoading } = useContext(AppContext);
  const { setResponse } = useContext(FeedbackContext);
  const { pages, loadPageContent } = useContext(PagesContext);

  const [selectedPage, setSelectedPage] = useState("");

  const handleChangePage = ({ target }) => {
    setSelectedPage(target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!state) {
        if (pages) setSelectedPage(pages[0]._id);
      } else {
        setSelectedPage(state.page._id);
      }
    }, 100);
  }, [pages, state]);

  const handleCreateNewSlider = async () => {
    setLoading(true);
    try {
      const { slider } = await createSlider({ slides: [] });
      await updatePage({ id: selectedPage, data: { sliderId: slider._id } });
      navigate(`/sliders/${slider._id}`, { state: { selectedPage } });
      setResponse({
        open: true,
        message: "Slider criado com sucesso.",
        severity: "success",
      });
      loadPageContent();
    } catch (error) {
      setResponse({
        open: true,
        message: "Ocorreu um erro no servidor ao tentar criar o slider.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const currentPage =
    !!pages && pages.filter((page) => page._id === selectedPage)[0];

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <Text variant="h5">Sliders</Text>
      </Box>
      <Box>
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{ padding: 2, paddingLeft: 0, marginTop: 2, display: "flex" }}
          >
            <FormControl fullWidth sx={{ maxWidth: "220px" }} size="small">
              <InputLabel>Página</InputLabel>
              <Select
                label="Página"
                color="primary"
                onChange={handleChangePage}
                value={selectedPage}
              >
                {!!pages &&
                  pages.map((page, index) => (
                    <MenuItem key={`page:${index}`} value={page._id}>
                      {page.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", flex: 1 }} />
            {currentPage && currentPage.slider && currentPage.slider._id ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/sliders/${selectedPage}`, {
                    state: { currentPage },
                  })
                }
                disabled={!selectedPage}
              >
                Editar
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateNewSlider}
                disabled={!selectedPage}
              >
                Novo
              </Button>
            )}
          </Box>

          {currentPage && currentPage.slider && currentPage.slider._id && currentPage.slider.slides.length > 0 && (
            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <SliderDesktop data={currentPage.slider} />
              <SliderMobile data={currentPage.slider} />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

const SliderDesktop = ({ data }) => (
  <Box sx={{ marginRight: 2 }}>
    <Text variant="h6" sx={{ fontSize: "18px" }}>
      Preview do Slider Desktop
    </Text>
    <Box
      sx={{
        maxWidth: "620px",
        width: "100%",
        border: "10px solid #252629",
        borderRadius: "10px",
        padding: "5px",
      }}
    >
      <div
        style={{ boxShadow: "0px 0px 0px 11px #252629", borderRadius: "10px" }}
      >
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          transitionTime={1000}
          emulateTouch={true}
        >
          {!!data &&
            data.slides.map((slide, index) => (
              <Box
                key={`slide:${index}`}
                sx={{
                  maxWidth: "620px",
                  maxHeight: "380px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={slide.desktopThumb}
                  alt={slide.alt}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </Box>
            ))}
        </Carousel>
      </div>
    </Box>
  </Box>
);

const SliderMobile = ({ data }) => (
  <Box>
    <Text variant="h6" sx={{ fontSize: "18px" }}>
      Preview do Slider Mobile
    </Text>
    <Box
      sx={{
        maxWidth: "200px",
        width: "100%",
        height: "382px",
        border: "10px solid #252629",
        borderRadius: "10px",
        padding: "4px",
      }}
    >
      <div
        style={{
          boxShadow: "0px 0px 0px 10px #252629",
          borderRadius: "10px",
          height: "100%",
        }}
      >
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          transitionTime={1000}
          emulateTouch={true}
        >
          {!!data &&
            data.slides.map((slide, index) => (
              <Box
                key={`slide:${index} + id:${slide._id}`}
                sx={{ maxWidth: "390px", maxHeight: "840px" }}
              >
                <img
                  src={slide.mobileThumb}
                  alt={slide.alt}
                  style={{ maxHeight: 120, objectFit: "cover" }}
                />
              </Box>
            ))}
        </Carousel>
      </div>
    </Box>
  </Box>
);
