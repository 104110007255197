import { formatValue } from "../../../utils/formatCurrency";

export function getTicketsArrayWithMinMax(ticketsArray) {
  if (Array.isArray(ticketsArray) && ticketsArray.length > 0) {
    const ticketPrices = ticketsArray.map((ticket) =>
      parseFloat(ticket.price.replace("R$", "").replace(".", "").replace(",", "."))
    );

    const minPrice = Math.min(...ticketPrices);
    const maxPrice = Math.max(...ticketPrices);

    const minPriceFormatted = formatValue(minPrice.toFixed(2).replace(".", ","));
    const maxPriceFormatted = formatValue(maxPrice.toFixed(2).replace(".", ","));

    return `${minPriceFormatted} - ${maxPriceFormatted}`;
  } else {
    return "Não cadastrado";
  }
}
