import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Switch,
  Box,
  Button,
  TextField,
  FormHelperText,
  Divider,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Text } from "../../core";
import { MultipleUploadBox } from "../Upload/MultipleUploadBox";
import TextEditor from "../../core/TextEditor";
import { Preview } from "../PreviewImage";
import { Carousel } from "react-responsive-carousel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DocumentUpload } from "../Upload/DocumentUpload";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PackageForm = ({
  initialValues,
  categories,
  subcategories,
  subcategorySelected,
  handleCancel,
  products,
  handleDeleteTempImage,
  handleEditorChange,
  handleUpload,
  handleDocumentUpload,
  handleRemoveDocument,
  handleChange,
  handleActive,
  tickets,
  handleSubmit,
  addTicket,
  deleteTicket,
  handleTicketChange,
}) => {
  const [openUpload, setOpenUpload] = useState(false);

  const [touched, setTouched] = useState({
    title: false,
    description: false,
    schedule: false,
    price: false,
    category: false,
    subcategory: false,
  });

  const handleBlur = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };

  return (
    <>
      {initialValues && (
        <Box>
          <Text variant="h5">Pacote</Text>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 2 }}>
            <TextField
              fullWidth
              label="Título"
              placeholder="Título do Pacote"
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={initialValues?.title}
              sx={{ mr: 2 }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              error={touched.title && initialValues.title === ""}
              helperText={
                touched.title && initialValues.title === "" && "Campo obrigatório"
              }
              required
            />
            <div style={{ flex: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "row", mr: 2 }}>
              <Text sx={{ marginRight: 1 }}>Avaliação</Text>
              <Rating
                name="evaluation"
                value={Number(initialValues.evaluation)}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text>{initialValues.active ? "Ativo" : "Inativo"}</Text>
              <Switch
                checked={initialValues.active}
                onChange={handleActive}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
          </Box>
          {initialValues.images && initialValues.images.length ? (
            <Carousel
              showThumbs={false}
              showStatus={true}
              showArrows={false}
              autoPlay={false}
              emulateTouch={true}
            >
              {initialValues.images.map((image, index) => (
                <Preview
                  key={index}
                  src={image}
                  deleteThumb={() => handleDeleteTempImage(index)}
                />
              ))}
            </Carousel>
          ) : (
            <div />
          )}
          {openUpload ? (
            <>
              <Text
                sx={{ fontStyle: "italic", color: "#6f7174", fontSize: 14 }}
              >
                A primeira imagem adicionada necessariamente será a thumbnail do
                evento.
              </Text>
              <MultipleUploadBox
                handleUpload={handleUpload}
                preview={false}
                sx={{
                  width: "100%",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f3f7fc",
                  marginTop: 1,
                }}
              // data={loumarEvent.images}
              />
            </>
          ) : (
            <Box sx={{ width: 330, my: 2 }}>
              <Button
                variant="contained"
                // size="small"
                startIcon={<CloudUploadIcon />}
                onClick={() => setOpenUpload(true)}
              >
                Adicionar imagem
              </Button>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', my: 4 }}>
            <Box sx={{ mr: 2 }}>
              <Text variant="h6">Descrição *</Text>
              <TextEditor
                value={initialValues.description}
                onEditorChange={handleEditorChange}
                name="description"
              />
            </Box>
            <Box>
              <Text variant="h6">Informações adicionais</Text>
              <TextEditor
                value={initialValues.extraInfo}
                onEditorChange={handleEditorChange}
                name="extraInfo"
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <DocumentUpload
              document={initialValues.document}
              data={initialValues.document}
              handleDocumentUpload={handleDocumentUpload}
              handleRemoveDocument={handleRemoveDocument}
            />
          </Box>
          <Box>
            {products &&
              <FormControl sx={{ minWidth: 300, mt: 4 }}>
                <InputLabel id="demo-simple-select-label">Produtos</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  name="products"
                  multiple
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                  value={initialValues.products}
                  label="Produtos"
                  onChange={handleChange}
                >
                  {products.map(product => (
                    <MenuItem key={product._id} value={product._id}>{product.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ mb: 2 }}>
              <Text variant="h6">Preços</Text>
            </Box>
            {tickets && tickets.length && tickets.map((ticket, ticketIndex) => (
              <Box key={`ticket:${ticketIndex}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Título"
                      name="title"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={ticket.title}
                      onChange={(e) => handleTicketChange(e, ticketIndex)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Descrição"
                      name="description"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={ticket.description}
                      onChange={(e) => handleTicketChange(e, ticketIndex)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Preço"
                      name="price"
                      variant="outlined"
                      type="text"
                      value={ticket.price}
                      onChange={(e) => handleTicketChange(e, ticketIndex)}
                      onBlur={handleBlur}
                      error={
                        touched.price &&
                        (ticket.price === "" ||
                          ticket.price === "R$ 0,00")
                      }
                      helperText={
                        touched.price &&
                        (ticket.price === "" ||
                          ticket.price === "R$ 0,00") &&
                        "Campo obrigatório"
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        size="small"
                        label="Parcelamento"
                        name="installment"
                        variant="outlined"
                        type="text"
                        value={ticket.installment}
                        onChange={(e) => handleTicketChange(e, ticketIndex)}
                        fullWidth
                      />
                      <IconButton
                        variant="outlined"
                        onClick={() => deleteTicket(ticketIndex)}
                        sx={{ borderRadius: '50%', ml: 1 }}
                        disabled={tickets.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {/* {show just if it is a first ticket} */}
                      {ticketIndex === 0 && (
                        <IconButton
                          onClick={addTicket}
                          variant="contained"
                          sx={{}}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2, gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
