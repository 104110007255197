import React, { useState } from 'react'
import { UploadBox } from '../Upload/UploadBox'
import { Box, Text, TextField, IconButton, Button } from '../../core'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { updateSlide } from '../../../services/slideService';
import CloseIcon from '@mui/icons-material/Close';
import { updateSlider } from '../../../services/sliderService';

export const SlideEdit = ({ editSlide, handleClose, slides, sliderId }) => {

    // const { loadContentPage } = editSlide
    const { desktopThumb, mobileThumb, alt, link } = editSlide.currentSlide

    const currentSlidePosition = slides.findIndex(element => element._id === editSlide.currentSlide._id)

    const [slide, setSlide] = useState({
        desktopThumb: desktopThumb ? desktopThumb : '',
        mobileThumb: mobileThumb ? mobileThumb : '',
        alt: alt ? alt : '',
        link: link ? link : '',
        position: currentSlidePosition
    })

    const handleChange = ({ target }) => {
        setSlide({
            ...slide,
            [target.name]: target.value
        })
    }

    const handleChangePosition = ({ target }) => {
        setSlide({
            ...slide,
            position: target.value
        })
    }

    const handleUploadDesktop = (data) => {
        const { url } = data;
        setSlide({ ...slide, desktopThumb: url })
    }

    const handleUploadMobile = (data) => {
        const { url } = data;
        setSlide({ ...slide, mobileThumb: url })
    }

    const handleSaveSlide = async () => {
        const { _id } = editSlide.currentSlide
        let editedSlide = {
            desktopThumb: slide.desktopThumb,
            mobileThumb: slide.mobileThumb,
            alt: slide.alt,
            link: slide.link,
            _id: _id
        }
        let slidesToBeSpliced = slides
        let slidesArray = handlePositionSlide(slidesToBeSpliced, slide.position, editedSlide)
        await updateSlide({ id: _id, data: editedSlide })
        await updateSlider({ id: sliderId, data: { slides: slidesArray } })
        handleClose()
    }

    const handlePositionSlide = (slidesToBeSpliced, index, editedSlide) => {
      console.log(slidesToBeSpliced, index, editedSlide)
        let position = index - 1
        if (position === 0) {
            slidesToBeSpliced.splice(currentSlidePosition, 1)
            slidesToBeSpliced.unshift(editedSlide)
        } else if (position >= slidesToBeSpliced.length - 1) {
            slidesToBeSpliced.splice(currentSlidePosition, 1)
            slidesToBeSpliced.push(editedSlide)
        } else {
          console.log('entrou aqui', position)
            slidesToBeSpliced.splice(currentSlidePosition, 1)
            slidesToBeSpliced.splice(index, 0, editedSlide)
        }
        return slidesToBeSpliced
    }

    return (
        <Dialog open={editSlide.open} sx={{ maxWidth: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                <Text variant="h6">Edição de Slide</Text>
                <div style={{ display: 'flex', flex: 1 }} />
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Box sx={{ maxWidth: '400px', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '390px'}}>
                        <>
                            {!slide.desktopThumb ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text>Slide para Desktop (2000x320)</Text>
                                    <UploadBox
                                        data={slide}
                                        handleUpload={handleUploadDesktop}
                                        sx={{ width: 'auto', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}
                                    />
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text>Slide para Desktop</Text>
                                    <Box sx={{ position: 'relative', width: 'auto', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => setSlide({ ...slide, desktopThumb: '' })}
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                                backgroundColor: '#e1e1e1'
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                        <img
                                            src={slide.desktopThumb}
                                            alt="Slide"
                                            style={{ width: 200, height: 120, objectFit: "cover", borderRadius: '13px' }}
                                        />
                                    </Box>
                                </Box>
                            )}
                            {!slide.mobileThumb ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                    <Text>Slide para Mobile (390x330)</Text>
                                    <UploadBox data={slide} handleUpload={handleUploadMobile} sx={{ width: 'auto', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }} />
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                    <Text>Slide para Mobile</Text>
                                    <Box sx={{ position: 'relative', width: 'auto', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => setSlide({ ...slide, mobileThumb: '' })}
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                                backgroundColor: '#e1e1e1'
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />

                                        </IconButton>
                                        <img
                                            src={slide.mobileThumb}
                                            alt="Slide"
                                            style={{ width: 200, height: 120, objectFit: "cover", borderRadius: '13px' }}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </>
                    </Box>
                    <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', maxWidth: '380px' }}>
                        <TextField
                            label="Alt"
                            name="alt"
                            onChange={handleChange}
                            defaultValue={editSlide.currentSlide.alt}
                            margin="dense"
                            size="small"
                            variant="outlined"
                            placeholder="Título (ALT)"
                        />
                        <TextField
                            label="Link"
                            name="link"
                            onChange={handleChange}
                            defaultValue={editSlide.currentSlide.link}
                            margin="dense"
                            size="small"
                            variant="outlined"
                            placeholder="Link para redirecionamento"
                        />
                        <TextField
                            label="Posição"
                            onChange={handleChangePosition}
                            defaultValue={currentSlidePosition + 1}
                            margin="dense"
                            size="small"
                            variant="outlined"
                            placeholder="Posição"
                            sx={{ maxWidth: '70px', width: '100%' }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSaveSlide}
                    disabled={!slide.desktopThumb || !slide.mobileThumb || !slide.alt || !slide.link}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
