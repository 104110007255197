import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Container,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "../../components/core";
import { Alert, Snackbar } from "@mui/material";
import {
  create,
  update,
  // updateStatus,
  remove,
  getPackageCategories,
  updateStatus,
  updatePackageCategory,
} from "../../services/packageCategoryService";
import { AppContext } from "../../contexts/AppContext";
import { PackageCategoryTable } from "../../components/patterns/Category/PackageCategoryTable";
import { PackageCategoryForm } from "../../components/patterns/Category/PackageCategoryForm";

const initialValues = {
  _id: "",
  thumbnail: "",
  title: "",
  active: false
}

export const PackageCategory = () => {
  const { setLoading } = useContext(AppContext);
  const [packageCategory, setPackageCategory] = useState(initialValues);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [packageCategories, setPackageCategories] = useState([]);
  const [error, setError] = useState(false);

  const [response, setResponse] = useState({
    open: false,
    severity: "warning",
    message: "",
  });
  const [showDialogConfirmation, setShowDialogConfirmation] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
    item: {},
  });

  useEffect(() => {
    listCategories();
  }, []);

  const listCategories = async () => {
    try {
      const data = await getPackageCategories();
      setPackageCategories(data);
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao listar as categorias do pacote.`,
      });
    }
  };

  const handleChange = ({ target }) => {
    setPackageCategory({
      ...packageCategory,
      [target.name]: target.value,
    });
    !packageCategory.title ? setError(true) : setError(false);
  };

  const handleTitleToSlug = (title) => {
    return title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9 ]/g, "")
      .replace(/ /g, "-");
  };

  const handleSubmit = async () => {
    const data = {
      title: packageCategory.title,
      active: packageCategory.active,
      thumbnail: packageCategory.thumbnail,
      slug: handleTitleToSlug(packageCategory.title),
    };

    try {
      setLoading(true);
      await create(data);
      setResponse({
        open: true,
        severity: "success",
        message: `Categoria de evento salva com sucesso.`,
      });
      setPackageCategory({ title: "", active: false });
      listCategories();
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao salvar a categoria de evento.`,
      });
      setPackageCategory({ thumb: "", title: "", active: false });
    } finally {
      setLoading(false);
      setOpenFormDialog(false);
    }
  };

  const handleCheckedCategory = (category) => {
    setShowDialogConfirmation({
      open: true,
      message: "Deseja ativar/inativar a categoria?",
      title: "Ativar/Inativar Categoria",
      onConfirm: () =>
        handleUpdateActiveCategory({
          ...category,
          active: !category.active,
        }),
    });
  };

  const handleUpdateActiveCategory = async (category) => {
    try {
      setLoading(true);
      await updateStatus(category);
      listCategories();
      setShowDialogConfirmation({
        open: false,
        title: "",
        message: "",
        onConfirm: () => {},
        item: {},
      });
      setResponse({
        open: true,
        severity: "success",
        message: `Categoria de pacote atualizada com sucesso.`,
      });
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao atualizar a categoria de pacote.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = (category) => {
    setShowDialogConfirmation({
      open: true,
      message: "Deseja deletar a categoria?",
      title: "Deletar Categoria",
      onConfirm: () => deleteCategory(category),
    });
  };

  const deleteCategory = async (category) => {
    try {
      setLoading(true);
      await remove(category._id);
      listCategories();
      setShowDialogConfirmation({
        open: false,
        title: "",
        message: "",
        onConfirm: () => {},
        item: {},
      });
      setResponse({
        open: true,
        severity: "success",
        message: `Categoria de evento deletada com sucesso.`,
      });
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao deletar a categoria de evento.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (data) => {
    const { url } = data;
    setPackageCategory({ ...packageCategory, thumbnail: url });
  };

  const handleEditCategory = (category) => {
    setOpenFormDialog(true)
    setPackageCategory(category)
  }

  const handleSwitchActive = ({ target }) =>
    setPackageCategory({ ...packageCategory, active: target.checked });

  const handleDeleteTempThumb = () =>
    setPackageCategory({ ...packageCategory, thumbnail: "" });

  const handleNewCategory = () => {
    setOpenFormDialog(true)
    setPackageCategory(initialValues)
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        ...packageCategory,
        slug: handleTitleToSlug(packageCategory.title),
      }
      await updatePackageCategory(data);
      listCategories();
      setResponse({
        open: true,
        severity: "success",
        message: `Categoria de evento atualizada com sucesso.`,
      });
    } catch (error) {
      setResponse({
        open: true,
        severity: "error",
        message: `Ocorreu um erro ao atualizar a categoria de evento.`,
      });
    } finally {
      setLoading(false);
      setOpenFormDialog(false)
    }
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Text variant="h5">Categorias de Pacote</Text>
          <Button
            onClick={handleNewCategory}
            variant="contained"
            color="primary"
          >
            Nova Categoria
          </Button>
        </Box>
        <PackageCategoryTable
          data={packageCategories}
          handleCheckedCategory={handleCheckedCategory}
          handleDeleteCategory={handleDeleteCategory}
          handleEditCategory={handleEditCategory}
        />
        <PackageCategoryForm
          open={openFormDialog}
          onClose={() => setOpenFormDialog(false)}
          initialValues={packageCategory}
          handleSwitchActive={handleSwitchActive}
          handleUpload={handleUpload}
          handleTitleToSlug={handleTitleToSlug}
          handleChange={handleChange}
          handleDeleteThumb={handleDeleteTempThumb}
          handleSubmit={handleSubmit}
          handleEditSubmit={handleEditSubmit}
          error={error}
        />
      </Box>
      {response.open && (
        <SnackbarResponse
          open={response.open}
          severity={response.severity}
          message={response.message}
          onClose={() => setResponse({ open: false, message: "" })}
        />
      )}
      {showDialogConfirmation && (
        <DialogConfirmation
          open={showDialogConfirmation.open}
          title={showDialogConfirmation.title}
          message={showDialogConfirmation.message}
          onClose={() => setShowDialogConfirmation(false)}
          onConfirm={showDialogConfirmation.onConfirm}
          item={packageCategory}
        />
      )}
    </Container>
  );
};

const SnackbarResponse = ({
  open = false,
  message = "Ocorreu um erro desconhecido",
  severity = "warning",
  onClose = () => {},
}) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert severity={severity} sx={{ margin: 2 }} onClose={onClose}>
      {message}
    </Alert>
  </Snackbar>
);

const DialogConfirmation = ({
  open = false,
  title,
  message,
  onClose = () => {},
  item = {},
  onConfirm = () => {},
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <Button onClick={(item) => onConfirm(item)} autoFocus variant="contained">
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);
