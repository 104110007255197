import makeRequest from "./makeRequest";

export const getLinks = () => makeRequest("/api/link", "GET");

export const getLinkById = (id) => makeRequest(`/api/link/${id}`, "GET");

export const createLink = (data) => makeRequest("/api/link", "POST", data);

export const updateLink = (id, data) => makeRequest(`/api/link/${id}`, "PATCH", data);

export const deleteLink = (id) => makeRequest(`/api/link/${id}`, "DELETE");
