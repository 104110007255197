import { Box, Button, Container, Dialog, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ProdutosMidia from './ProdutosMidia';
import ProdutosData from './ProdutosData';
import ProdutosPrices from './ProdutosPrices';
import { uploadToS3 } from '../../../utils/uploadToS3';
import api from '../../../services/api';

export default function ProdutosInternalPage() {
    const [tabValue, setTabValue] = useState(0)
    const tabOptions = ['Imagens/Vídeo', 'Dados do Produto', 'Valores'];
    const [loading, setLoading] = useState({
        active: false,
        error: false,
        progress: 10,
        message: 'Carregando...'
    })
    const navigate = useNavigate()
    const pathParam = useParams()
    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }
    const handleNewInternalProduto = async (values) => {
        setLoading((prevState) => ({ ...prevState, active: true, message: 'Salvando imagens...', progress: 20 }))
        const { images } = values;
        // PASSO 1 - UPLOAD DAS IMAGENS NO S3
        const imgUrl = await uploadToS3(images).then((uploadedImages) => {
            console.log('uploadedImages', uploadedImages)
            return uploadedImages
        }).catch((error) => {
            console.log('error', error)
            setLoading((prevState) => ({ ...prevState, error: true, message: error.message || 'Erro ao salvar imagem' }))
        }).finally(() => {
            setLoading((prevState) => ({ ...prevState, progress: 60, message: 'Gravando no banco de dados...' }))
        })

        const bodyRequest = {
            ...values,
            card_id: pathParam.produtoId,
            images: imgUrl.map((img) => img.url)
        };
        console.log('bodyRequest', bodyRequest);
        await api.post('/api/produtosPageContent', bodyRequest).then(() => {
            setLoading((prevState) => ({ ...prevState, progress: 100, message: 'Produto salvo com sucesso!' }))
            setTimeout(() => {
                navigate('/produtos')
            }, 1000)
        }
        ).catch((error) => {
            console.log('error', error)
            setLoading((prevState) => ({ ...prevState, error: true, message: error.message || 'Erro ao salvar produto' }))
        })
    }

    return (
        <Container maxWidth="xl" disableGutters>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
            >
                <Typography variant="h5">Pagina Interna de Produto </Typography>
                {/* <Button
    variant="contained"
    >
        Salvar Produto
    </Button> */}
            </Box>
            <Box my={6} display={'flex'} flexDirection={'column'} gap={4} maxWidth={1280}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="tab options"
                    >
                        {tabOptions.map((option, index) => (
                            <Tab key={index} label={option} />
                        ))}
                    </Tabs>
                </Box>
                <Box>
                    <Formik
                        initialValues={{
                            card_id: pathParam.produtoId,
                            images: [],
                            youtubeVideo: '',
                            generalDescription: '',
                            observations: '',
                            other: [{
                                description: '',
                                price: 0,
                                installment: 10,
                            }],
                            isActive: true,
                            availableTime: [{
                                day: '',
                                startTime: '',
                                endTime: '',
                            }],
                            priceTable: [{
                                description: 'teste',
                                price: 99,
                                installment: 10,
                            }],
                            metaTitle: '',
                        }}
                    //   validationSchema={InternalContentSchema}
                      onSubmit={handleNewInternalProduto}
                    >
                        <Form>
                            {tabValue === 0 && <ProdutosMidia />}
                            {tabValue === 1 && <ProdutosData />}
                            {tabValue === 2 && <ProdutosPrices />}
                        </Form>
                    </Formik>
                </Box>
            </Box>
            <Dialog
                open={loading.active}
                onClose={() => setLoading((prevState) => ({ ...prevState, active: false }))}
            >
                <Box p={4}>
                    <Typography variant="h5">{loading.message}</Typography>
                    <LinearProgress variant="determinate" color={loading.error ? 'error' : 'primary'} value={loading.progress} sx={{
                        height: 20,
                    }}

                    />
                </Box>
            </Dialog>
        </Container>
    )
}
