export const formatDate = (date) => {

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat('pt-BR', options);
  const formattedDate = formatter.format(new Date(date));

  return formattedDate;
};