import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Text, TextField } from "../../core";
import { Dialog, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { UploadBox } from "../Upload/UploadBox";
export const PackageCategoryForm = ({
  open,
  onClose,
  handleSwitchActive,
  handleDeleteThumb,
  handleUpload,
  handleTitleToSlug,
  handleChange,
  handleSubmit,
  handleEditSubmit,
  initialValues,
  error
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ padding: 2, minWidth: 320 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Text variant="h6">
            {!initialValues._id ? "Criar Categoria" : "Editar Categoria"}
          </Text>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 2,
          }}
        >
          {!initialValues.thumbnail ? (
            <UploadBox
              uploadText="Thumbnail da categoria"
              data={initialValues}
              handleUpload={handleUpload}
              sx={{
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  padding: 2,
                }}
              >
                <IconButton
                  size="small"
                  onClick={handleDeleteThumb}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: "#e1e1e1",
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <img
                  src={initialValues.thumbnail}
                  alt="category thumbnail"
                  style={{
                    width: 300,
                    height: 200,
                    objectFit: "cover",
                    borderRadius: 16,
                  }}
                />
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
            <TextField
              fullWidth
              label="Título"
              name="title"
              onChange={handleChange}
              value={initialValues.title}
              variant="outlined"
              sx={{ marginRight: 2 }}
              error={error && initialValues.title.trim() === ""}
              helperText={error && initialValues.title.trim() === "" ? "O título é obrigatório" : ""}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text>Ativo</Text>
              <Switch
                checked={initialValues.active}
                onChange={handleSwitchActive}
                name="active"
              />
            </Box>
          </Box>
          {!initialValues.title && (
            <Text sx={{ color: "red", fontSize: 12 }}>Campo obrigatório</Text>
          )}
          <Text sx={{ marginRight: 2, color: "#97999bf0" }}>
            Slug: {handleTitleToSlug(initialValues.title)}
          </Text>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 2 }}
            onClick={initialValues._id !== "" ? handleEditSubmit : handleSubmit}
            disabled={!initialValues.title}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
