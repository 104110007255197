import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { MultipleUploadBox } from "../Upload/MultipleUploadBox";
import { Text } from "../../core";
import { DocumentUpload } from "../Upload/DocumentUpload";
import { TicketForm } from "../Tickets/TicketForm";
import { FlightForm } from "../Flights/FlightForm";
import { Loading } from "../../core/Loading";
import { Carousel } from "react-responsive-carousel";
import { Preview } from "../PreviewImage";

export const ProductForm = ({
  product,
  flightIncluded,
  handleIncludedChange,
  handleChange,
  handleUpload,
  openUpload,
  setOpenUpload,
  handleDocumentUpload,
  handleRemoveDocument,
  tickets,
  addTicket,
  deleteTicket,
  handleTicketChange,
  flight,
  handleFlightChange,
  handleFlightThumbUpload,
  handleDeleteProductTempImage,
  handleDeleteThumb,
  handleSubmit
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Título do produto"
          onChange={handleChange}
          name="title"
          value={product.title || ""}
        />
        <TextField
          label="Descrição do produto"
          onChange={handleChange}
          name="description"
          value={product.description || ""}
        />
      </Box>
      {product.images && product.images.length ? (
        <Carousel
          showThumbs={false}
          showStatus={true}
          showArrows={false}
          autoPlay={false}
          emulateTouch={true}
        >
          {product.images.map((image, index) => (
            <Preview
              key={index}
              src={image}
              deleteThumb={() => handleDeleteProductTempImage(index)}
            />
          ))}
        </Carousel>
      ) : (
        <div />
      )}
      {openUpload ? (
        <Box sx={{ mt: 2 }}>
          <Text sx={{ fontStyle: "italic", color: "#6f7174", fontSize: 14 }}>
            A primeira imagem adicionada necessariamente será a thumbnail do
            produto.
          </Text>
          <MultipleUploadBox
            handleUpload={handleUpload}
            preview={false}
            data={product.images}
            sx={{
              width: "100%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f3f7fc",
              marginTop: 1,
            }}
          />
        </Box>
      ) : (
        <Box sx={{ width: 330, my: 2 }}>
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={() => setOpenUpload(true)}
          >
            Adicionar imagem
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 2 }} />
      <DocumentUpload
        data={product}
        document={product.document}
        handleDocumentUpload={handleDocumentUpload}
        handleRemoveDocument={handleRemoveDocument}
      />
      <Divider sx={{ my: 2 }} />
      <TicketForm
        tickets={tickets}
        addTicket={addTicket}
        deleteTicket={deleteTicket}
        handleTicketChange={handleTicketChange}
      />
      <FormControl>
        <FormLabel>Deseja adicionar detalhes de voo?</FormLabel>
        <RadioGroup
          value={flightIncluded}
          onChange={handleIncludedChange}
          sx={{ pl: 2, display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel value="true" control={<Radio />} label="Sim" />
          <FormControlLabel value="false" control={<Radio />} label="Não" />
        </RadioGroup>
      </FormControl>
      {flightIncluded && flight ? (
        <FlightForm
          flight={flight}
          handleFlightChange={handleFlightChange}
          handleUpload={handleFlightThumbUpload}
          handleDeleteThumb={handleDeleteThumb}
        />
      ) : <Loading open={flight ? true : false} />}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
};
