import React, { useEffect, useState } from 'react'
import { Box, TextField, Button, Select, MenuItem, InputLabel, FormControlLabel, Switch, } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { UploadBox } from '../../components/patterns';
import { createHotel, updateHotel } from '../../services/hotelService';
import { useNavigate } from 'react-router-dom';
import { Preview } from '../../components/patterns/PreviewImage';
import CurrencyInput from 'react-currency-input-field';

function currencyFormatter(value) {
    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);

    return formattedValue;
}

export default function NewCard({ edit, setEdit }) {
    const [hotelImages, setHotelImages] = useState([]);
    const [hotelImgError, setHotelImgError] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const validationSchema = yup.object({
        name: yup
            .string('Insira o nome do hotel')
            .required('Campo Obrigatório'),
        location: yup
            .string('Enter your password')
            .required('Campo Obrigatório'),
        starRating: yup
            .number('Campo Obrigatório')
            .required('Campo Obrigatório'),
        minPrice: yup
            .number('Campo Obrigatório')
            .required('Campo Obrigatório'),
        isActive: yup
            .boolean('Campo Obrigatório')
            .required('Campo Obrigatório'),
        feature1: yup
            .string('Campo Obrigatório')
            .required('Campo Obrigatório'),
        feature2: yup
            .string('Campo Obrigatório')
            .required('Campo Obrigatório'),
        feature3: yup
            .string('Campo Obrigatório')
            .required('Campo Obrigatório'),
    });

    function handleUpload(file) {
        setHotelImages([...hotelImages, file.url]);
    }
    console.log('edit is:', edit)

    async function handleSubmit(values) {
        if (hotelImages.length === 0) {
            return setHotelImgError(true);
        }
        const { feature1, feature2, feature3, ...rest } = values;
        if (edit !== undefined && edit.length !== 0) {
            const updateBodyContent = {
                ...rest,
                features: [feature1, feature2, feature3],
                images: hotelImages
            }
            await updateHotel(edit._id, updateBodyContent).then((res) => {
                setEdit({
                    open: true,
                    hotel: { ...updateBodyContent, _id: edit._id }
                })
                console.log('Hotel Editado:', res);
                setSubmitError('Hotel Editado com Sucesso');
                return navigate('/hotels');
            }).catch((err) => {
                console.log('error is:', err);
                setSubmitError(err.message);
            });
        }
        else {
            const createBodyContent = {
                ...rest,
                features: [feature1, feature2, feature3],
                images: hotelImages,
            }
            await createHotel(createBodyContent).then((res) => {
                console.log('Hotel Criado:', res);
                return navigate('/hotels');
            }).catch((err) => {
                console.log('error is:', err);
                setSubmitError(err.message);
            });
        }
        setButtonDisabled(true);
    }

    useEffect(() => {
        if (edit) {
            setHotelImages(edit.images)
        }
    }, [edit]);

    useEffect(() => {
        if (hotelImages.length > 0) {
            setHotelImgError(false);
        }
    }, [hotelImages]);

    return (
        <Formik
            initialValues={{
                name: edit?.name,
                location: edit?.location || '',
                starRating: edit?.starRating,
                minPrice: edit?.minPrice,
                feature1: (edit?.features && edit.features[0]) || '',
                feature2: (edit?.features && edit.features[1]) || '',
                feature3: (edit?.features && edit.features[2]) || '',
                isActive: edit?.isActive || true,
                promotional: {
                    isActive: edit?.promotional?.isActive || false,
                    price: edit?.promotional?.price || [0, 0, 0, 0],
                }
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}

        >
            <Form>
                <Box
                    m={4}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    maxWidth={960}>
                    <Box
                        display={'inherit'}
                        gap={2}
                        flexDirection={'row'}
                    >
                        <Field name="name">
                            {({ field, form }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Nome do Hotel"
                                    error={form.errors.name && form.touched.name}
                                    helperText={form.errors.name && form.touched.name && form.errors.name}
                                />
                            )}
                        </Field>
                        <Field name="location">
                            {({ field, form }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Localização"
                                    error={form.errors.location && form.touched.location}
                                    helperText={form.errors.location && form.touched.location && form.errors.location}
                                />
                            )}
                        </Field>

                    </Box>

                    <Box
                        display={'flex'}
                        gap={2}
                    >
                        <Field name="feature1">
                            {({ field, form }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Informação promocional 1"
                                    error={form.errors.features && form.touched.features}
                                    helperText={form.errors.features && form.touched.features && form.errors.features}
                                />
                            )}
                        </Field>
                        <Field name="feature2">
                            {({ field, form }) => (
                                <TextField
                                    {...field}
                                    label="Informação promocional 2"
                                    fullWidth
                                    error={form.errors.features && form.touched.features}
                                    helperText={form.errors.features && form.touched.features && form.errors.features}
                                />
                            )}
                        </Field>
                        <Field name="feature3">
                            {({ field, form }) => (
                                <TextField
                                    {...field}
                                    label="Informação promocional 3"
                                    fullWidth
                                    error={form.errors.features && form.touched.features}
                                    helperText={form.errors.features && form.touched.features && form.errors.features}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box
                        display={'inherit'}
                        gap={2}
                    >

                        <Field name="minPrice"
                        >
                            {({ field, form }) => (
                                <div>
                                    <InputLabel htmlFor="minPrice">Preço Mínimo</InputLabel>
                                    <TextField
                                        {...field}
                                        sx={{ maxWidth: 200 }}
                                        label="Preço Mínimo"
                                        error={form.errors.minPrice && form.touched.minPrice}
                                        helperText={form.errors.minPrice && form.touched.minPrice && form.errors.minPrice}
                                        InputProps={{
                                            inputComponent: InputMask,
                                            inputProps: {
                                                mask: '999.99',
                                                placeholder: '___.__',
                                            },
                                            onBlur: (e) => {
                                                e.target.value = currencyFormatter(e.target.value)
                                            }
                                            // onInput: (e) => {
                                            //     console.log('e is:', e.target.value.replace(/\D/g, ''));
                                            //     e.target.value = currencyFormatter(e.target.value.replace(/[^\d.]/g, ''))
                                            // }
                                        }}
                                    />
                                </div>
                            )}
                        </Field>
                        <Field name="starRating">
                            {({ field, form }) => (
                                <div>
                                    <InputLabel htmlFor="starRating">nº estrelas</InputLabel>
                                    <Select
                                        sx={{ minWidth: 80 }}
                                        value={field.value}
                                        autoWidth
                                        label="Quantidade de Estrelas"
                                        error={form.errors.starRating && form.touched.starRating}
                                        {...field}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                </div>
                            )}
                        </Field>
                    </Box>
                    <Field name="promotional.isActive">
                        {({ field, form }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={field.value}
                                        onChange={form.handleChange}
                                    />
                                }
                                label={field.value ? "Desativar Promocional" : "Ativar Promocional"}
                            />
                        )}
                    </Field>
                    <Field name="promotional.price">
                        {({ field, form }) => form.values.promotional.isActive && (
                            <Box
                                display={'flex'}
                                gap={2}
                                flexWrap={'wrap'}
                                
                            >
                                <Box>
                                    <InputLabel htmlFor="promotional.price[0]">Preço Single</InputLabel>
                                    <CurrencyInput
                                        id="input-currency-quad"
                                        style={{ maxWidth: 200, height: 39, padding: 10, border: '1px solid #ccc', fontSize: 16 }}
                                        name='promotional.price[0]'
                                        placeholder="R$999,99"
                                        value={field.value[0]}
                                        prefix="R$"
                                        decimalsLimit={2}
                                        onValueChange={(value) => form.setFieldValue('promotional.price', [value, field.value[1], field.value[2], field.value[3]])}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel htmlFor="promotional.price[1]">Preço Duplo</InputLabel>
                                    <CurrencyInput
                                        id="input-currency-quad"
                                        style={{ maxWidth: 200, height: 39, padding: 10, border: '1px solid #ccc', fontSize: 16 }}
                                        name='promotional.price[1]'
                                        placeholder="R$999,99"
                                        value={field.value[1]}
                                        prefix="R$"
                                        decimalsLimit={2}
                                        onValueChange={(value) => form.setFieldValue('promotional.price', [field.value[0], value, field.value[2], field.value[3]])}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel htmlFor="promotional.price[2]">Preço Triplo</InputLabel>
                                    <CurrencyInput
                                        id="input-currency-quad"
                                        style={{ maxWidth: 200, height: 39, padding: 10, border: '1px solid #ccc', fontSize: 16 }}
                                        name='promotional.price[2]'
                                        placeholder="R$999,99"
                                        value={field.value[2]}
                                        prefix="R$"
                                        decimalsLimit={2}
                                        onValueChange={(value) => form.setFieldValue('promotional.price', [field.value[0], field.value[1], value, field.value[3]])}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel htmlFor="promotional.price[3]">Preço Quad.</InputLabel>
                                    <CurrencyInput
                                        id="input-currency-quad"
                                        style={{ maxWidth: 200, height: 39, padding: 10, border: '1px solid #ccc', fontSize: 16 }}
                                        name='promotional.price[3]'
                                        placeholder="R$999,99"
                                        value={field.value[3]}
                                        prefix="R$"
                                        decimalsLimit={2}
                                        onValueChange={(value) => form.setFieldValue('promotional.price', [field.value[0], field.value[1], field.value[2], value])}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Field>

                    <Box
                        display={'flex'}
                        my={6}
                        gap={4}
                    >

                        {edit && hotelImages[0] ?
                            <Box
                                display={'flex'}
                                width={'100%'}
                            >
                                <Preview
                                    miniPreview={true}
                                    src={hotelImages && hotelImages[0]}
                                    deleteThumb={() => setHotelImages(hotelImages.filter((img, index) => index !== 0))}
                                />

                            </Box>
                            :
                            <UploadBox
                                uploadText="Arraste a Imagem Principal"
                                data={hotelImages && hotelImages[0]}
                                handleUpload={handleUpload}
                                sx={{
                                    width: "300px",
                                    height: "80px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: '2px dashed #ccc',
                                    padding: 2,
                                }}
                            />
                        }
                        {edit && hotelImages[1] ? <Preview
                            miniPreview={true}
                            src={hotelImages && hotelImages[1]}
                            deleteThumb={() => setHotelImages(hotelImages.filter((img, index) => index !== 1))}
                        />
                            :
                            <UploadBox
                                uploadText="Arraste a Imagem 1"
                                data={hotelImages && hotelImages[1]}
                                handleUpload={handleUpload}
                                sx={{
                                    width: "300px",
                                    height: "80px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: '2px dashed #ccc',
                                    padding: 2,
                                }}
                            />
                        }

                        {edit && hotelImages[2] ? <Preview
                            miniPreview={true}
                            src={hotelImages && hotelImages[2]}
                            deleteThumb={() => {
                                setHotelImages(hotelImages.filter((img, index) => index !== 2))
                                console.log('deleteThumb hotel img:', hotelImages)
                            }}
                        />
                            :
                            <UploadBox
                                uploadText="Arraste a Imagem 2"
                                data={hotelImages && hotelImages[2]}
                                handleUpload={handleUpload}
                                sx={{
                                    width: "300px",
                                    height: "80px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: '2px dashed #ccc',
                                    padding: 2,
                                }}
                            />
                        }



                    </Box>
                    {hotelImgError && <Box m={1} fontSize={20} color={'red'}>Adicione ao menos uma imagem</Box>}
                    {submitError && <Box m={1} fontSize={20} color={'red'}>{submitError}</Box>}
                    <Button type="submit" variant='outlined' disabled={buttonDisabled}>{edit ? "Editar Hotel" : "Criar Novo Hotel"}</Button>
                </Box>
            </Form>
        </Formik >
    );
}