import React, { useContext, useState } from "react";
import { Box, Button, Text, TextField, FormLabel, FormControl } from "../../components/core";
import { Preview } from "../../components/patterns/PreviewImage";
import { Carousel } from "react-responsive-carousel";
import { MultipleUploadBox } from "../../components/patterns/Upload/MultipleUploadBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import updateNestedProperty from "../../utils/updateNestedProperty";
import { formatValue } from "../../utils/formatCurrency";
import { createFlight } from "../../services/flightService";
import { createProduct } from "../../services/productService";
import { createTicket } from "../../services/ticketService";
import { FeedbackContext } from "../../contexts/FeedbackContext";
import { DocumentUpload, FlightForm, TicketForm } from "../../components/patterns";
import { useNavigate } from "react-router-dom";

const initialValues = {
  title: "",
  description: "",
  images: [],
  flightDetail: [],
  document: "",
  tickets: [],
};

const initialValuesFlight = {
  departureDate: new Date(),
  arrivalDate: new Date(),
  departureTime: new Date(),
  arrivalTime: new Date(),
  departure: "",
  arrival: "",
  layOver: "",
  airline: {
    name: "",
    airlineThumb: "",
  },
  lugged: "",
  included: false,
};

const initialTicket = {
  title: "",
  description: "",
  price: "",
  installment: ""
};

export const NewProduct = () => {
  const { setResponse } = useContext(FeedbackContext);
  const [product, setProduct] = useState(initialValues);
  const [flight, setFlight] = useState(initialValuesFlight);
  const [tickets, setTickets] = useState([initialTicket])
  const [openUpload, setOpenUpload] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleFlightChange = (event) => {
    const { name, value } = event.target;
    if (['arrivalTime', 'departureTime'].includes(name)) {
      const [hours, minutes] = value.split(':');
      const selectedDate = new Date();
      selectedDate.setHours(Number(hours));
      selectedDate.setMinutes(Number(minutes));
      console.log("🚀 ~ file: NewProduct.js:84 ~ handleFlightChange ~ selectedDate:", selectedDate)
      setFlight({ ...flight, [name]: selectedDate });
      return;
    }

    if (name === "included") {
      setFlight({ ...flight, included: value === "true" ? true : false })
      return;
    }

    if (name.includes('.')) {
      setFlight(prevFlight => updateNestedProperty(name, prevFlight, value));
    } else {
      setFlight({ ...flight, [name]: value });
    }
  }

  const handleUpload = (data) => {
    const images = [...product.images, ...data.map((image) => image.url)];
    setProduct({ ...product, images: images });
  }

  const handleFlightThumbUpload = (data) => {
    const { url } = data;
    setFlight({ ...flight, airline: { ...flight.airline, airlineThumb: url } });
  }

  const addTicket = () => {
    setTickets([...tickets, initialTicket]);
  };

  const deleteTicket = (index) => {
    const updatedTickets = [...tickets];
    updatedTickets.splice(index, 1);
    setTickets(updatedTickets);
  };

  const handleTicketChange = (event, ticketIndex) => {
    let updatedTickets = [...tickets];

    const { name, value } = event.target;

    if (name === 'price') {
      const price = formatValue(value)
      updatedTickets[ticketIndex] = {
        ...updatedTickets[ticketIndex],
        [name]: price
      };
      setTickets(updatedTickets);
      return
    }

    updatedTickets[ticketIndex] = {
      ...updatedTickets[ticketIndex],
      [name]: value
    };

    setTickets(updatedTickets);
  };

  const handleDocumentUpload = data => {
    const { url } = data;
    setProduct({ ...product, document: url })
  }

  const handleRemoveDocument = () => {
    setProduct({ ...product, document: "" })
  }

  const submitTickets = async (tickets) => {
    const validTickets = tickets.filter(ticket => ticket.price);
    if (!validTickets.length) {
      setResponse({
        success: false,
        message: "É necessário adicionar pelo menos um ticket"
      })
      return;
    }
    const ticketPromises = tickets.map(ticket => {
      return createTicket(ticket);
    });

    const ticketsResolved = await Promise.all(ticketPromises);
    const ticketIds = ticketsResolved.map(ticket => ticket._id);

    return ticketIds;
  }

  const submitFlight = async (flight) => {
    const requiredFields = ['departureDate', 'arrivalDate', 'departureTime', 'arrivalTime', 'departure', 'arrival', 'layOver', 'airline', 'lugged'];
    const flightHasRequiredFields = requiredFields.every(field => {
      const keys = field.split('.');
      let value = flight;
      for (let key of keys) {
        value = value[key];
      }
      return value !== "" && value !== undefined && value !== null;
    });

    if (!flightHasRequiredFields) {
      throw new Error("Flight missing required fields");
    }

    const { included, ...restFlight } = flight;
    const flightResolved = await createFlight(restFlight);
    const flightId = flightResolved._id;
    return flightId;
  }

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const ticketIds = await submitTickets(tickets);
      const flightId = flight.included ? await submitFlight(flight) : '';

      const newProduct = {
        ...product,
        flightDetail: flight.included ? flightId : null,
        tickets: ticketIds,
      };

      await createProduct(newProduct);

      navigate('/products', { replace: true })
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteTempImage = (index) => {
    const updatedImages = [...product.images];
    updatedImages.splice(index, 1);
    setProduct({ ...product, images: updatedImages });
  }


  return (
    <Box>
      <Text variant="h4" sx={{ mb: 2 }}>
        Novo Produto
      </Text>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Título do produto"
          onChange={handleChange}
          name="title"
          value={product.title || ""}
        />
        <TextField
          label="Descrição do produto"
          onChange={handleChange}
          name="description"
          value={product.description || ""}
        />
      </Box>
      {product.images && product.images.length ? (
        <Carousel
          showThumbs={false}
          showStatus={true}
          showArrows={false}
          autoPlay={false}
          emulateTouch={true}
        >
          {product.images.map((image, index) => (
            <Preview
              key={index}
              src={image}
              deleteThumb={() => handleDeleteTempImage(index)}
            />
          ))}
        </Carousel>
      ) : (
        <div />
      )}
      {openUpload ? (
        <Box sx={{ mt: 2 }}>
          <Text sx={{ fontStyle: "italic", color: "#6f7174", fontSize: 14 }}>
            A primeira imagem adicionada necessariamente será a thumbnail do
            produto.
          </Text>
          <MultipleUploadBox
            handleUpload={handleUpload}
            preview={false}
            sx={{
              width: "100%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f3f7fc",
              marginTop: 1,
            }}
            data={product.images}
          />
        </Box>
      ) : (
        <Box sx={{ width: 330, my: 2 }}>
          <Button
            variant="contained"
            // size="small"
            startIcon={<CloudUploadIcon />}
            onClick={() => setOpenUpload(true)}
          >
            Adicionar imagem
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 2 }} />
      <DocumentUpload
        data={product}
        document={product.document}
        handleDocumentUpload={handleDocumentUpload}
        handleRemoveDocument={handleRemoveDocument}
      />
      <Divider sx={{ my: 2 }} />
      <TicketForm
        tickets={tickets}
        addTicket={addTicket}
        deleteTicket={deleteTicket}
        handleTicketChange={handleTicketChange}
      />
      <FormControl>
        <FormLabel>Deseja adicionar detalhes de voo?</FormLabel>
        <RadioGroup
          name="included"
          value={flight.included}
          onChange={handleFlightChange}
          sx={{ pl: 2, display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel value="true" control={<Radio />} label="Sim" />
          <FormControlLabel value="false" control={<Radio />} label="Não" />
        </RadioGroup>
      </FormControl>
      {flight.included && (
        <FlightForm
          flight={flight}
          handleFlightChange={handleFlightChange}
          handleUpload={handleFlightThumbUpload}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >Salvar</Button>
      </Box>
    </Box>
  );
};

