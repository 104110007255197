import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import InputMask from 'react-input-mask';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
export default function ProdutosPrices() {
    const { values, submitForm, handleChange, setFieldValue, errors } = useFormikContext();
    const [priceArray, setPriceArray] = useState(values.priceTable);
    const [otherArray, setOtherArray] = useState(values.other);
    const installmentMask = {
        mask: '99x',
        placeholder: '__x',
        maskChar: '0',
    }
    console.log('errors is:', errors)
    return (
        <Box width={'100%'}>
            <Typography fontSize={20} color={'black'} fontWeight={500}>Valores Padrão</Typography>

            {priceArray.map((price, index) => (
                <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} gap={2}>
                    <TextField type="text" label="Titulo" fullWidth sx={{ width: 400, mt: 1 }}
                        value={values.priceTable[index]?.description} 
                        name={`priceTable[${index}].description`} 
                        onChange={handleChange}
                    />
                    <CurrencyInput
                        id="input-currency-quad"
                        style={{ width: 430, height: 35, padding: 10, border: '1px solid #000', fontSize: 18, alignSelf: 'center'}}
                        name={`priceTable[${index}].price`}
                        value={values.priceTable[index]?.price}
                        placeholder="R$999,99"
                        prefix="R$"
                        decimalsLimit={2}
                        onValueChange={(value) => setFieldValue(`priceTable[${index}].price`, parseFloat(value))}
                    />
                    <TextField type="text" label="Parcelamento*" fullWidth sx={{ width: 230, mt: 1 }}
                        value={values.priceTable[index]?.installment} name={`priceTable[${index}].installment`} onChange={handleChange}
                        InputProps={{
                            inputComponent: InputMask,
                            inputProps: installmentMask,
                        }}
                    />
                    <IconButton
                        color="primary"
                        onClick={() => {
                            (priceArray.length < 4) && setPriceArray([...priceArray, { description: '', price: 0, installment: 10 }])
                        }
                        }
                    >
                        <AddShoppingCartIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            priceArray.splice(index, 1)
                            setPriceArray([...priceArray])
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}

            <Typography fontSize={20} color={'black'} fontWeight={500} mt={4}>Outras Cobranças (Opcional)</Typography>
            <Box width={'100%'} mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}></Box>
            <Box display={'flex'} alignItems={'center'} mt={4} flexDirection={'column'}>
                {otherArray.map((other, index) => (
                    <Box width={'100%'} display={'flex'} alignItems={'center'} gap={2}>
                        <TextField type="text" label="Titulo" fullWidth sx={{ width: 400, mt: 1 }}
                            value={values.other[index]?.description} name={`other[${index}].description`} onChange={handleChange}
                        />
                        <CurrencyInput
                            id="input-currency-quad"
                            style={{ width: 430, height: 35, padding: 10, border: '1px solid #000', fontSize: 18 }}
                            name={`other[${index}].price`}
                            value={values.other[index]?.price}
                            placeholder="R$999,99"
                            prefix="R$"
                            decimalsLimit={2}
                            onValueChange={(value) => setFieldValue(`other[${index}].price`, parseFloat(value))}
                        />
                        <TextField type="text" label="Parcelamento*" fullWidth sx={{ width: 230, mt: 1 }}
                            value={values.other[index]?.installment} name={`other[${index}].installment`} onChange={handleChange}
                            InputProps={{
                                inputComponent: InputMask,
                                inputProps: installmentMask,
                            }}
                        />
                        <IconButton
                            color="primary"
                            onClick={() => {
                                (otherArray.length < 4) && setOtherArray([...otherArray, { description: '', price: 0, installment: 10 }])
                            }
                            }
                        >
                            <AddShoppingCartIcon />
                        </IconButton>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setOtherArray(() => otherArray.filter((item, i) => i !== index))
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}

            </Box>
                <Box
                my={4}
                >
                    <Button variant="contained" color="primary" sx={{ px: 4, py: 2 }} type="submit">Finalizar</Button>
                </Box>
        </Box>
    )
}



