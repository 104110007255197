import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ptBR } from 'date-fns/locale';

export default function CustomMuiPicker({ type = 'date', value, onChange, label = 'Data', sx }) {
  // const [value, setValue] = React.useState(null);

  if (type === 'date') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DatePicker
          // disableFuture
          label={label}
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} sx={sx} />}
        />
      </LocalizationProvider>
    )
  } else {
    return (

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <TimePicker
          label={label}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    )
  }
}

