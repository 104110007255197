import { Box, Button, IconButton, Typography } from "@mui/material"
import ReactQuill from "react-quill"
import { TextField } from '@mui/material'
import { useFormikContext } from "formik";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import InputMask from "react-input-mask";
import { useEffect, useState } from "react";

export default function ProdutosData() {
    const { values, handleChange, setFieldValue } = useFormikContext();
    const horarioRetornoMask = {
        mask: '99:99',
        placeholder: '__:__H',
        maskChar: '0',
    }
    const [horariosArray, setHorariosArray] = useState(values.availableTime)
    console.log('values', values)

    return (
        <Box width={'100%'} px={2}>
            <Typography fontSize={20} color={'black'} fontWeight={500}>Descrição Geral</Typography>
            <ReactQuill style={{ height: 150, marginTop: 20 }} value={values.generalDescription} name='generalDescription' onChange={(value) => setFieldValue('generalDescription', value)} />
            <Typography fontSize={20} color={'black'} fontWeight={500} mt={8}>Descrição Aba 2</Typography>
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                <ReactQuill style={{ height: 150, marginTop: 20, width: '100%' }} value={values.observations} name='observations' onChange={(value) => setFieldValue('observations', value)} />
            </Box>
            <Typography fontSize={20} color={'black'} fontWeight={500} mt={8}>Tabela de Horários (Opcional)</Typography>
            {horariosArray.map((horario, index) => (
                <Box
                    display={'flex'}
                    gap={2}
                    mt={4}
                >
                    <FormControl fullWidth>
                        <InputLabel id="diasSemanaLabel">Dias da Semana</InputLabel>
                        <Select
                            labelId="diasSemanaLabel"
                            id="diasSemana"
                            name={`availableTime[${index}].day`}
                            value={values.availableTime[index]?.day}
                            onChange={handleChange}
                        >
                            <MenuItem value="Segunda-feira">Segunda-feira</MenuItem>
                            <MenuItem value="Terça-feira">Terça-feira</MenuItem>
                            <MenuItem value="Quarta-feira">Quarta-feira</MenuItem>
                            <MenuItem value="Quinta-feira">Quinta-feira</MenuItem>
                            <MenuItem value="Sexta-feira">Sexta-feira</MenuItem>
                            <MenuItem value="Sábado">Sábado</MenuItem>
                            <MenuItem value="Domingo">Domingo</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        name={`availableTime[${index}].startTime`}
                        label="Horário de Saída"
                        value={values.availableTime[index]?.startTime}
                        onChange={handleChange}
                        InputProps={{
                            inputComponent: InputMask,
                            inputProps: horarioRetornoMask,
                        }}
                    />
                    <TextField
                        fullWidth
                        name={`availableTime[${index}].endTime`}
                        label="Horário de Retorno"
                        value={values.availableTime[index]?.endTime}
                        onChange={handleChange}
                        InputProps={{
                            inputComponent: InputMask,
                            inputProps: horarioRetornoMask,
                        }}
                    />
                    <IconButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => (horariosArray.length < 6) && setHorariosArray([...horariosArray, {
                            diasSemana: '',
                            horarioSaida: '',
                            horarioRetorno: '',
                        }])
                        }
                    >
                        <AlarmAddIcon />
                    </IconButton>
                    <IconButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => (horariosArray.length > 0) && setHorariosArray(() => horariosArray.filter((item, i) => i !== index))
                        }
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))
            }
            <Typography fontSize={20} color={'black'} fontWeight={500} mt={8}>Metadados</Typography>
            <TextField
                fullWidth
                id="metaTitle"
                name="metaTitle"
                label="Descrição até 160 caracteres"
                value={values.metaTitle}
                onChange={handleChange}
            />

        </Box>
    )
}