import { Box, Button, Container } from "@mui/material";
import React, { useState } from "react";
import { Text } from "../../components/core";
import { ConfirmationDialog } from "../../components/patterns/Dialogs/ConfirmationDialog";
import useApiRequest from "../../services/useApiRequest";
import { deleteProduct } from "../../services/productService";
import { useNavigate } from "react-router-dom";
import { ProductsTable } from "../../components/patterns";

export const Products = () => {
  const { data: products, refetchData } = useApiRequest("/api/product", "GET", []);

  const [dialog, setDialog] = useState({ open: false, id: "" });

  const navigate = useNavigate();

  const handleConfirmDelete = (id) => {
    setDialog({ open: true, id: id });
  };

  const handleDeleteProduct = async (id) => {
    await deleteProduct(id);
    await refetchData();
    setDialog({ open: false, id: "" });
  };

  const handleEdit = (product) => navigate(`/products/edit/${product._id}`, { state: product });

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}
      >
        <Text variant="h5">Produtos</Text>
        <Button
          onClick={() => navigate("/products/new")}
          variant="contained"
          color="primary"
        >
          Adicionar
        </Button>
      </Box>
      <Box>
        <ProductsTable
          data={products}
          handleEdit={handleEdit}
          handleConfirmDelete={handleConfirmDelete}
        />
      </Box>
      {dialog.open && (
        <ConfirmationDialog
          open={dialog.open}
          dialogTitle="Excluir produto"
          dialogMessage="Tem certeza que deseja excluir este produto?"
          variantButtonConfirm='outlined'
          handleClose={() => setDialog({ open: false, id: "" })}
          onConfirm={() => handleDeleteProduct(dialog.id)}
        />
      )}
    </Container>
  );
};

