import { Box, Typography, TextField, Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';

export default function ProdutosMidia() {
    const [files, setFiles] = useState([]);
    const { values, errors, touched, handleChange, setFieldValue } = useFormikContext();

    const handleFileChange = (event) => {
        console.log('EVENT: ', event)
        const fileList = event.target.files;
        const updatedFiles = [...files, ...fileList].slice(0, 5);
        setFiles(updatedFiles);
        setFieldValue('images', updatedFiles);
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
        setFieldValue('images', updatedFiles)
    };

    // const handleDrop = (event) => {
    //     handleChange(event);
    //     event.preventDefault();
    //     const fileList = event.dataTransfer.files;
    //     setFiles([...files, ...fileList]);
    // };

    return (
        <Box display={'flex'} flexDirection={'column'}>

            <Typography fontSize={20} color={'#000000DE'} fontWeight={600}>Video do Produto <span style={{ color: '#00000066', fontSize: 14 }}>(apenas disponível para página interna)</span></Typography>
            <Typography fontSize={14}>Caso você insira um vídeo, ele tomará a primeira posição no mosaico de fotos</Typography>

            <TextField
                label="Link Youtube"
                fullWidth
                value={values.youtubeVideo}
                onChange={handleChange}
                name='youtubeVideo'
                sx={{ my: 2 }}

            />

            <Typography fontSize={20} color={'#000000DE'} fontWeight={600}>Imagens do Produto</Typography>
            <Box
                onDragOver={(e) => e.preventDefault()}
                onClick={() => document.querySelector(".input-field").click()}
                width={1215}
                onChange={handleChange}
                border={'2px dashed var(--divider, #0000001F)'}
                p={2}
                display={'flex'}
                alignItems={'center'}
                name='images'
                sx={{ cursor: 'pointer' }}
                mt={2}
                gap={2}
            >
                <input className='input-field' onChange={handleFileChange} type='file' accept='image/*' hidden style={{ display: 'none' }} />

                <UploadFileIcon sx={{ height: 30, width: 25 }} />
                <Typography fontSize={16} color={'#2196F3'} fontWeight={400}>
                    Clique para enviar
                </Typography>
                <Typography fontSize={14} color={'#00000099'}>PNG, JPG ou WEBP</Typography>
            </Box>
            <Box mt={2} width={'100%'}>
                {values.images?.map((file, index) => (
                    <Box key={index} width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={3}>
                        <Box display={'flex'}>
                            {file && file instanceof File ? (
                                <img src={URL.createObjectURL(file)} alt={file.name} style={{ height: 80, width: 80, borderRadius: 5 }} />
                            )
                                :
                                (
                                    <img src={file ? file : ''} alt={file? file : ''} style={{ height: 80, width: 80, borderRadius: 5 }} />
                                )}
                            <Box ml={2} my={1}>
                                <Typography fontSize={16} fontWeight={400} color={'#000000DE'}>{typeof file === 'string' ? file : file?.name}</Typography>
                                <Typography fontSize={14} color={'#00000099'} mt={2}>{typeof file === 'string' ? '~' : (file?.size / 1024).toFixed(3)}kb</Typography>
                            </Box>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                            <TextField value={index + 1} sx={{ width: 73 }} label="Posição" />
                            <Button
                                startIcon={<DeleteIcon sx={{ width: 30, height: 30 }} />}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => handleRemoveFile(index)}
                            />
                        </Box>
                    </Box>
                ))}
                {touched.images && errors.images && <Typography color={'red'}>{errors.images}</Typography>}

            </Box>
        </Box>
    );
}
