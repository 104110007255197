import React, { createContext, useEffect, useState } from "react";
import { getPage, getPages } from "../services/pageService";

export const PagesContext = createContext({});

export const PagesProvider = ({ children }) => {
  const [pages, setPages] = useState();
  const [page, setPage] = useState();
  const [cachedPages, setCachedPages] = useState({});
  const [cachedPage, setCachedPage] = useState({});

  useEffect(() => {
    const cachedPages = JSON.parse(localStorage.getItem("cachedPages"));
    if (cachedPages && cachedPages.length > 0) {
      setCachedPages(cachedPages);
      setPages(cachedPages);
    } else {
      loadPageContent();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cachedPages", JSON.stringify(cachedPages));
  }, [cachedPages]);

  const loadPageContent = async () => {
    if (cachedPages && cachedPages.length > 0) {
      setPages(cachedPages);
    } else {
      const { pages } = await getPages();
      setPages(pages);
      setCachedPages(pages);
    }
  };

  const loadPagesContent = async () => {
    const { pages } = await getPages();
    setPages(pages);
    setCachedPages(pages);
  };

  const loadCurrentPageContent = async (id) => {
    if (cachedPage[id]) {
      setPage(cachedPage[id]);
    } else {
      const { page } = await getPage(id);
      setPage(page);
      setCachedPage((prevState) => ({ ...prevState, [id]: page }));
    }
  };

  return (
    <PagesContext.Provider
      value={{ pages, page, loadPageContent, loadCurrentPageContent, loadPagesContent }}
    >
      {children}
    </PagesContext.Provider>
  );
};
